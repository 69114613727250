export const START_GAME = "START_GAME"
export const START_NEXT_ROUND = "START_NEXT_ROUND"
export const SET_BOARD_ELEMENT_RECT = 'SET_BOARD_ELEMENT_RECT'

export const SETUP_CLUE = 'SETUP_CLUE'
export const DISPLAY_CLUE = 'DISPLAY_CLUE'
export const CLUE_CLOSED = "CLUE_CLOSED"
export const DAILY_DOUBLE_CLICKED = "DAILY_DOUBLE_CLICKED"
export const SHOW_FINAL_CLUE = "SHOW_FINAL_CLUE"
export const SHOW_FINAL_RESULTS = "SHOW_FINAL_RESULTS"
export const PLAY_FINAL_SONG = "PLAY_FINAL_SONG"
export const UPDATE_TEAM_NAME = "UPDATE_TEAM_NAME"
export const UPDATE_TEAM_SCORE = "UPDATE_TEAM_SCORE"
export const UPDATE_TEAM_WAGER = "UPDATE_TEAM_WAGER"
export const UPDATE_TEAM_WAGER_ID = "UPDATE_TEAM_WAGER_ID"
export const UPDATE_TEAM_ANSWER_ID = "UPDATE_TEAM_ANSWER_ID"
export const SET_LAST_CORRECT = "SET_LAST_CORRECT"
export const ADD_TEAM = "ADD_TEAM"
export const REMOVE_TEAM = "REMOVE_TEAM"
export const ADD_BUZZER = "ADD_BUZZER"
export const REMOVE_BUZZER = "REMOVE_BUZZER"
export const SET_BUZZER_LATENCY = "SET_BUZZER_LATENCY"
export const UPDATE_BUZZER_STATS = "UPDATE_BUZZER_STATS"
export const MOVE_HIGHLIGHT = "MOVE_HIGHLIGHT"
export const INC_TEAM_SCORE_INDEX = "INC_TEAM_SCORE_INDEX"
export const DEC_TEAM_SCORE_INDEX = "DEC_TEAM_SCORE_INDEX"
export const INC_TEAM_SCORE_ID = "INC_TEAM_SCORE_ID"
export const DEC_TEAM_SCORE_ID = "DEC_TEAM_SCORE_ID"
export const SET_FINAL_CONTINUE_BUTTON = "SET_FINAL_CONTINUE_BUTTON"
export const FINAL_NEXT = "FINAL_NEXT"

export const TOGGLE_SIDEBAR = "TOGGLE_SIDEBAR"
export const TOGGLE_BUZZERS = "TOGGLE_BUZZERS"
export const ACTIVATE_BUZZERS = "ACTIVATE_BUZZERS"
export const DEACTIVATE_BUZZERS = "DEACTIVATE_BUZZERS"
export const BUZZIN_TIMER_EXPIRED = "BUZZIN_TIMER_EXPIRED"
export const CLEAR_BUZZERS = "CLEAR_BUZZERS"
export const BUZZER_ACTIVATION_STYLE = "BUZZER_ACTIVATION_STYLE"
export const TOGGLE_TIMERS = "TOGGLE_TIMERS"
export const SET_BUZZIN_TIMEOUT = "SET_BUZZIN_TIMEOUT"
export const SET_ANSWER_TIMEOUT = "SET_ANSWER_TIMEOUT"
export const TOGGLE_AUTO_DISABLE_BUZZERS = "TOGGLE_AUTO_DISABLE_BUZZERS"
export const GOT_VALID_BUZZ = "GOT_VALID_BUZZ"
export const RESET_TIMER = "RESET_TIMER"
export const HIDE_TIMER = "HIDE_TIMER"
export const SET_LOCKOUT = "SET_LOCKOUT"
export const SET_LAST_ANSWER = "SET_LAST_ANSWER"
export const BUZZER_IS_PICKING_CLUE = "BUZZER_IS_PICKING_CLUE"
export const BUZZER_IS_VIEWING_ANSWER = "BUZZER_IS_VIEWING_ANSWER"
export const TOGGLE_REBUZZ = "TOGGLE_REBUZZ"
export const TOGGLE_BUZZERS_JOIN_TEAMS = "TOGGLE_BUZZERS_JOIN_TEAMS"
export const TOGGLE_EXPERT_MODE = "TOGGLE_EXPERT_MODE"
export const TOGGLE_AUTOHOST = "TOGGLE_AUTOHOST"
export const SET_VOICE = "SET_VOICE"

export const ACTIVATE_TEAM = "ACTIVATE_TEAM"

export const EDITING = "EDITING"

export const CLUE_STATE_PRE = 1
export const CLUE_STATE_SHOW = 2
export const CLUE_STATE_HIDE = 3
export const CLUE_STATE_DAILY_DOUBLE_SPLASH = 4
export const CLUE_STATE_SHOW_DAILY_DOUBLE = 5
export const CLUE_STATE_HIDE_DAILY_DOUBLE = 6
