import React from 'react';

export default function FinalPreReveal({ok}) {
  return (
    <div>
      <div>Your team is next!</div>
      <button id="final-reveal-button" className="buzzer-yes-button buzzer-big-font" onClick={ok}>Reveal Final Answer!</button>
    </div>
  )
}
