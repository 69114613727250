import React, { useState } from 'react';
import './Password.css'
import MainScreen from './MainScreen';
import axios from 'axios';

export default function Password() {
  const [password, setPassword] = useState('');
  const [authed, setAuthed] = useState(false);
  const [wrong, setWrong] = useState(false);

  const changePassword = (e) => {
    setPassword(e.target.value);
  }

  function login() {
    axios.post(`${process.env.REACT_APP_API}/auth`, {p: password})
      .then(_ => setAuthed(true))
      .catch(_ => setWrong(true));
  }

  function keyUp(e) {
    if (e.keyCode === 13) login();
  }

  return (
    <div>
      {authed &&
        <MainScreen/>
      }

      {!authed &&
        <div id="container">
            <div id="modal">
                <div style={{color: '#FFCC00', fontFamily: 'Anton, sans-serif'}}>Password:</div>
                <input type="password" value={password} onChange={changePassword} autoFocus onKeyUp={(e) => keyUp(e)}></input>
                <div><button onClick={login}>go</button></div>
                {wrong &&
                  <div style={{color: '#94f8ff', fontFamily: 'Anton, sans-serif'}}>nope!</div>
                }
            </div>
        </div>
      }
    </div>
  )
}
