import React, { PureComponent } from 'react';

/*
 This nice idea and implementation was lifted from the package textFit.
 https://github.com/STRML/textFit/blob/master/textFit.js
 Unfortunately I couldn't get it (or the react version react-textfit) to do anything,
 so I grabbed this code, simplified it a bit to only deal with a single string and
 also update the element in place (instead of making a new element for testing size), 
 and removed a bunch of the hardcore javascript stuff in there that I don't understand
 but probably makes it way more robust.
 */

class SizeToFit extends PureComponent {
  constructor(props) {
    super(props);
    this.ref = React.createRef();
    this.debouncedResize = this.debounce(this.handleResize.bind(this), 100)
  }

  debounce(fn, ms) {
    let timer
    return _ => {
      // call fn at most once every ms
      if (!timer) {
        timer = setTimeout(_ => {
          timer = null
          fn.apply(this, arguments)
        }, ms)
      }
    };
  }

  handleResize() {
    // if (this.props.text.startsWith('OH SAY')) {
    if (this.ref.current) {
      const el = this.ref.current.parentElement;
      // start with the original parent width, we're going to try various font sizes
      // until the text fits in here (plus fudge factor (for rounding error?))
      const originalWidth = el.clientWidth + 1;
      const originalHeight = el.clientHeight + 1;
      // console.log(`>>>>>>> ${this.props.text} - ${originalWidth} x ${originalHeight}`)
    
      let low = this.props.smallest || 1;
      let high = this.props.largest || 100;
      let mid;

      let size = low;
      while (low <= high) {
        mid = (high + low) >> 1;
        el.style.fontSize = mid + 'px';
        if(el.scrollWidth <= originalWidth && el.scrollHeight <= originalHeight) {
          size = mid;
          low = mid + 1;
          // console.log(`size ${mid} inner ${el.scrollWidth}x${el.scrollHeight} vs ${originalWidth}x${originalHeight}: too small`)
        } else {
          high = mid - 1;
          // console.log(`size ${mid} inner ${el.scrollWidth}x${el.scrollHeight} vs ${originalWidth}x${originalHeight}: too big`)
        }
      }
      if( el.style.fontSize !== size + 'px' ) el.style.fontSize = size + 'px';
      // console.log(`final font size ${size}`)
    }
    //}
  }

  componentWillUnmount() {
    // window.removeEventListener("resize", this.handleResize.bind(this));
    window.removeEventListener("resize", this.debouncedResize);
  }

  componentDidMount() {
    this.handleResize()
      // window.addEventListener('resize', this.handleResize.bind(this))
      window.addEventListener('resize', this.debouncedResize)
  }

  componentDidUpdate() {
    this.handleResize()
  }

  render() {
    return (
      <div ref={this.ref}>
        {this.props.text}
      </div>
    )
  }
}

export default SizeToFit
