if (typeof window === 'undefined') {
  const { JSDOM } = require("jsdom")
  global.DOMParser = new JSDOM().window.DOMParser
}

// Scrape a single game page, return a game object

const parser = new DOMParser();
const reAnswer = /('([^'\\]|\\.)*')/g
const reShowDate = /Show #(\d*) - (.*)/
const visualExtensions = ['.jpg', '.mp4']
const audioExtensions = ['.mp3']

function scrapeCorrectResponse(element, clue) {
  // finding the correct response is an extra challenge...
  const clueMouseOverElement = element.querySelector('div[onmouseover^="toggle("]')
  if (clueMouseOverElement) {
    try {
      const toggle = clueMouseOverElement.getAttribute('onmouseover')
      // onmouseover calls a toggle function with 3 arguments, the 3rd of which
      // contains an HTML string (which strangely (sometimes?) has the 
      // correct_response class quotes escaped) that includes the correct response
      const toggleHtml = toggle.match(reAnswer)[2].slice(1, -1).replace('class=\\"correct_response\\"', 'class="correct_response"')
      const toggleDoc = parser.parseFromString(toggleHtml, 'text/html');
      const correctResponse = toggleDoc.querySelector('.correct_response')
      return correctResponse.textContent
    } catch (e) {
      console.log('failed to scrape correct response for', clue)
      console.log(e)
    }
    return null
  }
}

function parseRound(baseValue, element) {
  const round = []
  const categoryElements = element.querySelectorAll('.category')
  for (const categoryElement of categoryElements) {
    let gameCategory = {}
    round.push(gameCategory)

    gameCategory.category = categoryElement.querySelector('.category_name').textContent
    gameCategory.comments = categoryElement.querySelector('.category_comments').textContent
    gameCategory.clues = []
  }

  const roundTable = element.querySelector('.round>tbody')
  const rows = roundTable.querySelectorAll('.round>tbody>tr')

  for (var r = 1; r < rows.length; r++) {
    const rowClues = rows[r].querySelectorAll('.clue')
    for (var c = 0; c < rowClues.length; c++) {
      let gameClue = {}
      round[c].clues.push(gameClue)

      const clueTextElement = rowClues[c].querySelector('.clue_text')
      if (clueTextElement) {
        gameClue.value = baseValue * r
        gameClue.text = clueTextElement.textContent
        gameClue.dailyDouble = !!(rowClues[c].querySelector('.clue_value_daily_double'))
        gameClue.correctResponse = scrapeCorrectResponse(rowClues[c], gameClue.text)
        const childHrefs = [...clueTextElement.childNodes].filter(n => n.nodeName === 'A')
        gameClue.isVisual = childHrefs.filter(a => visualExtensions.includes(a.attributes.getNamedItem('href').value.toLowerCase().slice(-4))).length > 0
        gameClue.isAudio = childHrefs.filter(a => audioExtensions.includes(a.attributes.getNamedItem('href').value.toLowerCase().slice(-4))).length > 0
      }
    }
  }
  return round
}

function parseFinal(element) {
  const round = {}

  round.category = element.querySelector('.category_name').textContent
  round.comments = element.querySelector('.category_comments').textContent

  round.clue = {}
  round.clue.text = element.querySelector('.clue_text').textContent
  round.clue.correctResponse = scrapeCorrectResponse(element)

  return round
}

export function scrape(content) {
  let game = {}

  const doc = parser.parseFromString(content, 'text/html');
  game.title = doc.querySelector('#game_title').textContent
  game.comments = doc.querySelector('#game_comments').textContent
  game.showDate = new Date(game.title.match(reShowDate)[2]);

  game.rounds = []
  game.rounds.push(parseRound(200, doc.querySelector('#jeopardy_round')))
  game.rounds.push(parseRound(400, doc.querySelector('#double_jeopardy_round')))
  game.final_jeopardy_round = parseFinal(doc.querySelector('#final_jeopardy_round'))

  return game
}

// const fs = require('fs')
// const text = fs.readFileSync(__dirname + '/../../public/dev/game.html')
// console.log(JSON.stringify(scrape(text)))

// module.exports = { scrape };
