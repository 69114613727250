import React, { Component } from 'react';
import { BrowserRouter as Router } from 'react-router-dom'
import { Route } from 'react-router-dom'
import { Provider } from "react-redux"
import store from './redux/store'
import './App.css'
import Admin from './admin/Admin'
import Buzzer from './buzzer/Buzzer'
import Password from './components/Password';
import MainScreen from './components/MainScreen';

class App extends Component {
  render() {
    return (
      <Provider store={store}>
        <Router>
          <div className="appContainer">
            {process.env.REACT_APP_PASSWORD ?
              <Route path="/" exact component={Password}/>
              :
              <Route path="/" exact component={MainScreen}/>
            }
            <Route path="/admin" component={Admin}/>
            <Route path="/buzzer" component={Buzzer}/>
          </div>
        </Router>
      </Provider>
    )
  }
}

export default App
