import React, { PureComponent } from 'react';
import { connect } from "react-redux";
import './Team.css'
import {incTeamScoreByIndex, decTeamScoreByIndex, updateTeamName, updateTeamScore, updateTeamWager, updateTeamAnswerById, removeTeam, dailyDoubleClicked, activateEditing, deactivateEditing} from "../redux/actions/index";

const mapStateToProps = state => {
  return {
    teams: state.teams,
    buzzers: state.buzzers,
    isFinal: state.gameplay.isFinal,
    isFinalAnswer: state.gameplay.isFinalAnswer,
    isFinalResults: state.gameplay.isFinalResults,
    isClueDisplayed: state.gameplay.isClueDisplayed,
    activeClueValue: state.gameplay.activeClueValue,
    isDailyDouble: state.gameplay.isDailyDouble,
  }
}

function mapDispatchToProps(dispatch) {
  return {
    incTeamScoreByIndex: (i, clueValue) => dispatch(incTeamScoreByIndex(i, clueValue)),
    decTeamScoreByIndex: (i, clueValue) => dispatch(decTeamScoreByIndex(i, clueValue)),
    updateTeamName: (i, n) => dispatch(updateTeamName(i, n)),
    updateTeamScore: (i, s) => dispatch(updateTeamScore(i, s)),
    updateTeamWager: (i, w) => dispatch(updateTeamWager(i, w)),
    updateTeamAnswerById: (id, answer) => dispatch(updateTeamAnswerById(id, answer)),
    removeTeam: (i) => dispatch(removeTeam(i)),
    dailyDoubleClicked: () => dispatch(dailyDoubleClicked()),
    activateEditing: () => dispatch(activateEditing()),
    deactivateEditing: () => dispatch(deactivateEditing()),
  }
}

class Team extends PureComponent {
  constructor(props) {
    super(props)
    // index prop passed directly from parent

    this.state = {
      teamId: this.getTeam().id,
      editingName: !this.getTeam().teamName,
      editingScore: false,
      localName: this.getTeam().teamName,
      localScore: this.getTeam().score,
      hideScore: true,
      showBuzzers: false,
      myBuzzers: [],
      wager: this.getTeam().wager || '',
      needsWager: true,
      answer: null,
      needsAnswer: true,
    }

    // we're only editing if the team didn't already come with a name
    if (!this.getTeam().teamName) this.props.activateEditing()
  }

  componentDidMount() {
    const myBuzzers = this._buzzers()
    this.setState({myBuzzers: myBuzzers, needsWager: myBuzzers.length === 0, needsAnswer: myBuzzers.length === 0})
  }

  componentDidUpdate(prevProps) {
    if (prevProps.buzzers !== this.props.buzzers) {
      const myBuzzers = this._buzzers()
      this.setState({myBuzzers: myBuzzers, needsWager: myBuzzers.length === 0, needsAnswer: myBuzzers.length === 0})
    }
    if (prevProps.teams !== this.props.teams) {
      this.setState({wager: this.getTeam().wager || ''})
    }
  }

  getTeam() {
    return this.props.teams[this.props.index]
  }

  onKeyDown = (e) => {
    if (e.keyCode === 27) {
      if (!this.state.localName || !this.getTeam().teamName) {
        this.props.updateTeamName(this.props.index, '<no name>')
      }
      this.setState({localScore: this.getTeam().score, localName: this.getTeam().teamName, editingScore: false, editingName: false})
      this.props.deactivateEditing()
    }
  }

  _incTeamScore = () => {
    this.props.isFinal && this.setState({hideScore: false})
    this.props.incTeamScoreByIndex(this.props.index, this.props.activeClueValue)
  }

  _decTeamScore = () => {
    this.props.isFinal && this.setState({hideScore: false})
    this.props.decTeamScoreByIndex(this.props.index, this.props.activeClueValue)
  }

  _handleNameChange = (e) => {
    this.setState({localName: e.target.value.substring(0, 30)})
  }

  _handleScoreCorrection = (e) => {
    this.setState({localScore: e.target.value})
  }

  _setEditNameFlag = () => {
    this.setState({editingName: true, localName: this.getTeam().teamName})
    this.props.activateEditing()
  }

  _setEditScoreFlag = () => {
    this.setState({editingScore: true, localScore: this.getTeam().score, localName: this.getTeam().teamName})
    this.props.activateEditing()
  }

  handleFocus = (event) => event.target.select();

  handleBlur = (e) => {
    var name = this.state.localName
    if (!name) name = '<no name>'
    this.props.updateTeamName(this.props.index, name)
    this.setState({localScore: this.getTeam().score, localName: name, editingScore: false, editingName: false})
    this.props.deactivateEditing()
  }

  _handleNameSubmit = (e) => {
    this.props.updateTeamName(this.props.index, this.state.localName || '<no name>')
    this.setState({editingName: false})
    this.props.deactivateEditing()
    e.preventDefault()
  }

  _handleScoreSubmit = (e) => {
    this.props.updateTeamScore(this.props.index, parseInt(this.state.localScore) || 0)
    this.setState({editingScore: false})
    this.props.deactivateEditing()
    e.preventDefault()
  }

  _handleEntryChange = (e) => {
    if (e.target.value.length <= 7) {
      this.setState({[e.target.name]: e.target.value})
    }
    e.preventDefault()
  }

  _onWagerKeyDown = (e) => {
    if (e.keyCode === 13) {
      this.props.updateTeamWager(this.props.index, parseInt(this.state.wager) || 0)
      this.setState({wager: '', needsWager: false})
      if (this.props.isDailyDouble) {
        document.activeElement.blur()
        this.props.dailyDoubleClicked()
      }
    }
  }

  _onWagerBlur = (e) => {
    if (this.state.wager !== null) {
      this.props.updateTeamWager(this.props.index, parseInt(this.state.wager) || 0)
      this.setState({needsWager: false})
    }
    this.setState({wager: ''})
  }

  _clearWager = () => {
    if (!this.props.isFinalAnswer) {
      this.props.updateTeamWager(this.props.index, null)
      this.setState({wager: '', needsWager: true})
    }
  }

  _onAnswerKeyDown = (e) => {
    this.props.activateEditing()
    if (e.keyCode === 13) {
      this.props.updateTeamAnswerById(this.state.teamId, this.state.answer)
      this.setState({answer: null, needsAnswer: false})
      this.props.deactivateEditing()
    }
  }

  _onAnswerBlur = (e) => {
    // if (this.getTeam().wager !== null && this.state.answer !== null) {
    if (this.state.answer !== null) {
      this.props.updateTeamAnswerById(this.state.teamId, this.state.answer)
      this.setState({needsAnswer: false})
    }
    this.setState({answer: null})
    this.props.deactivateEditing()
  }

  _clearAnswer = () => {
    if (!this.props.isFinalResults) {
      this.props.updateTeamAnswerById(this.state.teamId, null)
      this.setState({answer: null, needsAnswer: true})
    }
  }

  _buzzers() {
    const teamId = this.getTeam().id
    return this.props.buzzers.filter((b) => b.teamId === teamId)
  }

  _showBuzzers = () => {
    this.setState({showBuzzers: true})
  }

  _hideBuzzers = () => {
    this.setState({showBuzzers: false})
  }

  render() {
    // console.log(`render ${this.getTeam().teamName} cluedisplayed:${this.props.isClueDisplayed} wager:${this.getTeam().wager} activeclueval:${this.props.activeClueValue}`)
    // const buzzers = this._buzzers()
    var highlightBorder = "border-inactive"
    var correctBorder = ""
    if (this.props.isFinal) {
      if ((this.getTeam().wager === null) && (this.getTeam().score > 0)) {
        highlightBorder = "border-awaiting-wager"
      }
    } else if (this.props.isFinalAnswer) {
      if ((this.getTeam().finalAnswer === null) && (this.getTeam().score > 0)) {
        highlightBorder = "border-awaiting-wager"
      }
    } else {
      if (this.getTeam().active) highlightBorder = "border-active"
      if (this.getTeam().lastCorrect) correctBorder = "last-correct-team"
    }
    return (
      <div id={`team-${this.props.index}`} style={{position: 'relative'}}>
      <div className={highlightBorder}>
      <div className="border-content">
      <div className={"team noselect " + correctBorder}>
        <div className="icon-line">
          <i className={"pointer fa fa-trash " + (this.state.activeClueValue ? "trash-hide" : "trash")} 
            onClick={() => window.confirm('You sure?') && this.props.removeTeam(this.props.index) }></i>
          {this.state.myBuzzers.length > 0 &&
            <div className="icon-wrapper">
              <i className="pointer fas fa-users buzzer-count" onClick={this._showBuzzers}></i>
              <span className="pointer badge" onClick={this._showBuzzers}>{this.state.myBuzzers.length}</span>
            </div>
          }
        </div>

        <div className="name-line">
          {this.state.editingName && 
            <form id="team-name-form" onSubmit={this._handleNameSubmit}>
              <input type="text" id="teamName" autoFocus 
                onFocus={this.handleFocus}
                onBlur={this.handleBlur}
                value={this.state.localName} 
                onChange={this._handleNameChange}
                onKeyDown={this.onKeyDown}/>
            </form>
          }
          {!this.state.editingName && 
            <div id={`team-name-${this.props.index}`} 
              className="team-name" 
              onClick={this._setEditNameFlag}
            >
              {this.getTeam().teamName}
            </div>
          }
        </div>

        <div className="score">
          <div className="score-label">SCORE:</div>
          {this.state.editingScore &&
            <form onSubmit={this._handleScoreSubmit}>
              <input id={`team-score-${this.props.index}`} type="text" className="score-entry" autoFocus 
                onFocus={this.handleFocus}
                onBlur={this.handleBlur}
                value={this.state.localScore} 
                onChange={this._handleScoreCorrection} 
                onKeyDown={this.onKeyDown}/>
            </form>
          }
          {!this.state.editingScore &&
            <div id={`team-score-${this.props.index}`} 
              className={this.getTeam().score < 0 ? "score-value-negative" : "score-value"} 
              onClick={this._setEditScoreFlag}
            >
              {this.getTeam().score}
            </div>
          }
        </div>

        <div className="score-checks-container">
          {(this.props.isDailyDouble && this.getTeam().lastCorrect) &&
            <div className="wager-container">
              <div className="score-label">WAGER:</div>
              <input id={`team-wager-${this.props.index}`} type={this.props.isFinal && this.state.hideScore ? "password" : "text"} name="wager" size="6" autoFocus 
                  value={this.state.wager}
                  onChange={this._handleEntryChange} 
                  onKeyDown={this._onWagerKeyDown}
                  onBlur={this._onWagerBlur}
              />
            </div>
          }

          {/* final: team has a buzzer and is awaiting a wager */}
          {this.props.isFinal && !this.props.isFinalAnswer && this.getTeam().wager === null && !this.state.needsWager && this.state.myBuzzers.length > 0 &&
            <i className={"fas fa-dollar-sign awaiting-wager " + (!this.props.isFinalAnswer ? "pointer" : "")} onClick={this._clearWager}></i>
          }
          {/* final: team has no buzzer and needs wager entry, or wager entry requested */}
          {this.props.isFinal && !this.props.isFinalAnswer && this.state.needsWager &&
            <div className="wager-container">
              <div className="score-label">WAGER:</div>
              <input type={this.props.isFinal && this.state.hideScore ? "password" : "text"} name="wager" size="6" autoFocus 
                  onChange={this._handleEntryChange} 
                  onKeyDown={this._onWagerKeyDown}
                  onBlur={this._onWagerBlur}
              />
            </div>
          }
          {/* final: wager is set */}
          {this.props.isFinal && this.getTeam().wager !== null &&
            <i className={"fas fa-dollar-sign wager-set " + (!this.props.isFinalAnswer ? "pointer" : "")} onClick={this._clearWager}></i>
          }

          {/* final: team has a buzzer and is awaiting final answer */}
          {this.props.isFinalAnswer && this.getTeam().finalAnswer === null && !this.state.needsAnswer && this.state.myBuzzers.length > 0 &&
            <i className={"fas fa-question-circle awaiting-wager " + (!this.props.isFinalResults ? "pointer" : "")} onClick={this._clearAnswer}></i>
          }
          {/* final: team has no buzzer and needs answer entry, or answer entry requested */}
          {this.props.isFinalAnswer && this.state.needsAnswer &&
            <div className="wager-container">
              <div className="score-label">ANSWER:</div>
              <input type="password" name="answer" size="10" autoFocus 
                  onChange={this._handleEntryChange} 
                  onKeyDown={this._onAnswerKeyDown}
                  onBlur={this._onAnswerBlur}
              />
            </div>
          }
          {/* final: answer is set */}
          {this.props.isFinalAnswer && this.getTeam().finalAnswer !== null &&
            <i className={"fas fa-question-circle wager-set " + (!this.props.isFinalResults ? "pointer" : "")} onClick={this._clearAnswer}></i>
          }

          {this.props.isClueDisplayed && 
              (this.getTeam().wager !== null || this.props.activeClueValue > 0) && 
              !this.props.isFinal &&
            <div className="score-checks">
              <i id={`team-right-${this.props.index}`} className="fas fa-check right-answer pointer" onClick={this._incTeamScore}></i>
              <i id={`team-wrong-${this.props.index}`} className="fas fa-times wrong-answer pointer" onClick={this._decTeamScore}></i>
            </div>
          }
        </div>

      </div>
      </div>
      </div>

        {this.state.showBuzzers && 
          <div className="team-buzzer-list" onClick={this._hideBuzzers}>
            Buzzers:
            <hr/>
            <ul>
            {this.state.myBuzzers.map((b, i) => 
              <li key={i}>{b.buzzerName}</li>
            )}
            </ul>
          </div>
        }

      </div>
    )
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Team)
