import React, { PureComponent } from 'react';
import './Buzzer.css'
import './BuzzerShowAnswer.css'
import {hotkeys} from 'react-keyboard-shortcuts'

class BuzzerShowAnswer extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {
    }
  }

  hot_keys = {
    'enter': {
      handler: (event) => this.props.buzzerAnsweredRight() && event.preventDefault()
    },
    'y': {
      handler: (event) => this.props.buzzerAnsweredRight() && event.preventDefault()
    },
    'n': {
      handler: (event) => this.props.buzzerAnsweredWrong() && event.preventDefault()
    },
    's': {
      handler: (event) => this.props.buzzerAnsweredSkip() && event.preventDefault()
    },
  }

  render() {
    return (
      <div id="buzzer-show-answer-container">
        <div>
          <div className="buzzer-text">
            The answer is
          </div>
          {this.props.correctResponse &&
            <div id="buzzer-correct-response" className="buzzer-text buzzer-show-answer">
              {this.props.correctResponse}
            </div>
          }
          <div className="buzzer-text">
            Were you right?
          </div>
          <div className="buzzer-show-answer-buttons buzzer-text">
            <button id="buzzer-answer-correct-button" 
                className="buzzer-yes-button buzzer-big-font" onClick={this.props.buzzerAnsweredRight}>
              Yes
            </button>
            <button id="buzzer-answer-wrong-button" 
                className="buzzer-no-button buzzer-big-font" onClick={this.props.buzzerAnsweredWrong}>
              No
            </button>
          </div>
        </div>
        {!this.props.isDailyDouble &&
          <div className="buzzer-skip-button-container">
            <button id="buzzer-answer-skip-button" className="buzzer-skip-button" onClick={this.props.buzzerAnsweredSkip}>
              Skip
            </button>
          </div>
        }
      </div>
    )
  }
}

export default hotkeys(BuzzerShowAnswer)
