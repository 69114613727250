import React, { PureComponent } from 'react';
import { connect } from "react-redux";
import './ClueContainer.css'
import Clue from './Clue';
import DailyDouble from './DailyDouble';
import * as constants from "../redux/constants"
import DailyDoubleSound from '../assets/dailydouble.mp3';

const mapStateToProps = state => {
  return {
    clueDisplayState: state.gameplay.clueDisplayState,
    boardElements: state.gameplay.boardElements,
    currentRow: state.gameplay.currentRow,
    currentCol: state.gameplay.currentCol,
  }
}

class ClueContainer extends PureComponent {
  // expects text and category passed directly as props
  constructor(props) {
    super(props);

    this.clueElement = React.createRef()

    this.state = {
      text: this.props.text,      
    }
  }

  componentDidUpdate(prevProps) {
    if (prevProps.clueDisplayState !== this.props.clueDisplayState) {
      switch(this.props.clueDisplayState) {
        case constants.CLUE_STATE_PRE:
          this.setState({row: this.props.currentRow, col: this.props.currentCol, text: this.props.text})
          this.prepareForDisplay(this.state.myRect)
          break
        case constants.CLUE_STATE_SHOW:
          this.show()
          break
        case constants.CLUE_STATE_HIDE:
          this.hide()
          break
        case constants.CLUE_STATE_DAILY_DOUBLE_SPLASH:
          this.showDailyDoubleSplash()
          break
        case constants.CLUE_STATE_SHOW_DAILY_DOUBLE:
          this.showDailyDouble()
          break
        case constants.CLUE_STATE_HIDE_DAILY_DOUBLE:
          this.hideDailyDouble()
          break
        default:
          // noop
      }
    }
  }

  componentDidMount() {
    const myRect = this.clueElement.current.getBoundingClientRect()
    this.setState({myRect: myRect, row: this.props.currentRow, col: this.props.currentCol})
    this.prepareForDisplay(myRect)
  }

  translate(boardElement, me) {
    const tx = (boardElement.x + boardElement.width / 2) - (me.x + me.width / 2)
    const ty = (boardElement.y + boardElement.height / 2) - (me.y + me.height / 2)
    return `${tx}px,${ty}px`
  }

  scale(boardElement, me) {
    const sx = boardElement.width / me.width
    const sy = boardElement.height / me.height
    return `${sx},${sy}`
  }

  // scale down and move to the same rect as the current board element
  prepareForDisplay(myRect) {
    const boardElementRect = this.props.boardElements[this.props.currentRow][this.props.currentCol]
    this.setState({style: {
      visibility: 'hidden',
      transform: `translate(${this.translate(boardElementRect, myRect)}) scale(${this.scale(boardElementRect, myRect)})`,
    }})
  }

  // no transform (meaning go back to original covering the board)
  show() {
    this.setState({style: {
      visibility: 'visible',
      transition: 'all 500ms',
    }})
  }

  // transition to removing the clue from the screen
  hide() {
    // const myRect = this.state.myRect
    // const boardElementRect = this.props.boardElements[this.state.row][this.state.col]
    this.setState({style: {
      opacity: 0,
      // transform: `translate(${this.translate(boardElementRect, myRect)}) scale(${this.scale(boardElementRect, myRect)})`,
      transition: 'all 500ms',
    }})
  }

  showDailyDoubleSplash() {
    this.setState({style: {
      visibility: 'visible',
      transform: 'translate(0px,0px) scale(1) rotateX(360deg)',
      transition: 'all 1000ms',
    }})
  }

  showDailyDouble() {
    this.setState({style: {
      visibility: 'visible',
      transform: 'translate(0px,0px) scale(1) rotateY(180deg)',
      transition: 'all 1000ms',
    }})
  }

  hideDailyDouble() {
    // const myRect = this.state.myRect
    // const boardElementRect = this.props.boardElements[this.state.row][this.state.col]
    this.setState({style: {
      opacity: 0,
      // transform: `translate(${this.translate(boardElementRect, myRect)}) scale(${this.scale(boardElementRect, myRect)})`,
      transform: 'translate(0px,0px) scale(1) rotateY(180deg)',
      transition: 'all 500ms',
    }})
  }

  render() {
    const clue = <Clue 
      category={this.props.category} 
      text={this.state.text} 
      isVisual={this.props.isVisual}
      isAudio={this.props.isAudio}
      />

    return (
      <div id="clue-container" className="clue-container" ref={this.clueElement} style={this.state.style}>
        {/* regular clue show/hide */}
        {(this.props.clueDisplayState === constants.CLUE_STATE_SHOW ||
          this.props.clueDisplayState === constants.CLUE_STATE_HIDE) &&
          clue
        }

        {/* special daily double show/hide */}
        {this.props.clueDisplayState === constants.CLUE_STATE_DAILY_DOUBLE_SPLASH &&
          <div>
            <DailyDouble isVisual={this.props.isVisual} isAudio={this.props.isAudio}/>
            <audio src={DailyDoubleSound} autoPlay/>
          </div>
        }

        {(this.props.clueDisplayState === constants.CLUE_STATE_SHOW_DAILY_DOUBLE) &&
          <div className="card">
            <div className="card__face"><DailyDouble/></div>
            <div className="card__face card__face--back">{clue}</div>
          </div>
        }

        {(this.props.clueDisplayState === constants.CLUE_STATE_HIDE_DAILY_DOUBLE) &&
          <div className="card">
            <div className="card__face2 card__face--back">{clue}</div>
          </div>
        }
      </div>
    )
  }
}

export default connect(mapStateToProps)(ClueContainer)
