import React from 'react';
import { connect } from "react-redux";
import './DailyDouble.css'
import DailyDoubleImage from '../assets/dailydouble.jpg';
import {dailyDoubleClicked} from "../redux/actions/index";

function mapDispatchToProps(dispatch) {
  return {
    dailyDoubleClicked: () => dispatch(dailyDoubleClicked()),
  }
}

export default connect(null, mapDispatchToProps)(props => {
  return (
    <div id="daily-double-splash" className="dd-container"
        style={{
          backgroundImage: `url(${DailyDoubleImage})`,
          backgroundRepeat: 'no-repeat',
          backgroundSize: '100% 100%'
        }}
        onClick={props.dailyDoubleClicked}
    >
      {props.isVisual &&
        <div className="dd-footer"><i className="fas fa-photo-video min"></i> &nbsp; this is a visual Daily Double</div>
      }
      {!props.isVisual && props.isAudio &&
        <div className="dd-footer"><i className="fas fa-music min"></i> &nbsp; this is an audio Daily Double</div>
      }
    </div>
  )
})
