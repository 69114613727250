import React, { PureComponent } from 'react';
import { connect } from "react-redux";
import Team from './Team';
import './TeamList.css'
import {addTeam} from "../redux/actions/index";

const mapStateToProps = state => {
  return {
    teams: state.teams,
    editing: state.gameplay.editing,
  }
}

function mapDispatchToProps(dispatch) {
  return {
    addTeam: () => dispatch(addTeam()),
  };
}

class TeamList extends PureComponent {
  editTeamName(i, name) {
    const p = [...this.state.teams]
    p[i].teamName = name
    this.setState({teams: p})
  }

  render() {
    return (
      <div className="team-list-container">
        <div className="team-list-title">TEAMS</div>
        <div className="team-list">
          {this.props.teams.map((p, i) => 
            <Team key={i} index={i}/>
          )}
          <p/>
          {!this.props.editing &&
            <div style={{textAlign: 'center'}}>
              <input id="add-team" type="button" value="Add Team" onClick={this.props.addTeam}/>
            </div>
          }
        </div>
      </div>
    )
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(TeamList)
