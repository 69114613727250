import React, { PureComponent } from 'react';
import { connect } from "react-redux";
import {activateBuzzers} from "../redux/actions/index";

const mapStateToProps = state => {
  return {
    delayedActivation: state.gameplay.delayedActivation,
  }
}

function mapDispatchToProps(dispatch) {
  return {
    activateBuzzers:() => dispatch(activateBuzzers())
  };
}

// maybe could do this with a thunk instead of a whole component
class DelayedActivation extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      start: 0,
    }
  }

  componentDidMount() {
    this.setState({start: Date.now()})
    this.t = setInterval(this.calculateProgress, 100)
  }

  componentWillUnmount() {
    clearInterval(this.t)
  }

  calculateProgress = () => {
    if (Date.now() - this.state.start > this.props.delayedActivation) {
      clearInterval(this.t)
      this.props.activateBuzzers()
    }
  }

  render() {
    // return(<div style={{color:'white'}}>delaying for {this.props.delayedActivation}</div>)
    return(<div/>)
  }    
}

export default connect(mapStateToProps, mapDispatchToProps)(DelayedActivation)
