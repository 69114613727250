import React, { PureComponent } from 'react';

class FinalSorry extends PureComponent {
  render() {
    return (
      <div>
        {this.props.finalTeamScore === null &&
          <div>
            Looks like you're not on a team - no Final Jeopardy!
          </div>
        }

        {this.props.finalTeamScore !== null &&
          <div id="final-sorry">
            I'm sorry, but your score of {this.props.finalTeamScore} means
            your team didn't make it to Final Jeopardy.
          </div>
        }
      </div>
    )
  }
}

export default FinalSorry
