import React, { PureComponent } from 'react';
import { connect } from "react-redux";
import './ClueValue.css'
import {setupClue, displayClue, setBoardElementRect} from "../redux/actions/index";

const mapStateToProps = state => {
  return {
    currentRow: state.gameplay.currentRow,
    currentCol: state.gameplay.currentCol,
  }
}

function mapDispatchToProps(dispatch) {
  return {
    setupClue: (r,c) => dispatch(setupClue(r,c)),
    displayClue: (r,c) => dispatch(displayClue(r,c)),
    setBoardElementRect: (r,c,rect) => dispatch(setBoardElementRect(r,c,rect)),
  }
}

class ClueValue extends PureComponent {
  constructor(props) {
    super(props)

    this.boardElement = React.createRef()
  }

  isHighlighted() {
    return this.props.currentRow === this.props.row && this.props.currentCol === this.props.col
  }

  componentDidMount() {
    // put my bounding rect into the redux store
    this.props.setBoardElementRect(this.props.row, this.props.col, this.boardElement.current.getBoundingClientRect())
  }

  _clueClicked = () => {
    this.props.setupClue(this.props.row, this.props.col)
    setTimeout(() => this.props.displayClue(this.props.row, this.props.col), 0)
  }

  render() {
    return (
      <div className={'clue-value'} ref={this.boardElement}>
        <div className={this.isHighlighted() ? "clue-value-highlight" : "clue-value-nohighlight"}>
          {this.props.display && this.props.clueValue && 
            <div id={'clue-value-' + this.props.row + '-' + this.props.col} className="clue-value-background pointer" onClick={() => this._clueClicked()}>
              {"$" + this.props.clueValue}
            </div>
          }
        </div>
      </div>
    )
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(ClueValue)
