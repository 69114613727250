import React, { PureComponent } from 'react';
import './Buzzer.css'
import FinalStatus from './FinalStatus'

const MESSAGES_NAN = [
  "That's not going to work",
  "Try entering a number next time",
  "And how much do you suppose that's worth? Try again..."
]

const MESSAGES_NEGATIVE = [
  "Are you thinking if you get a negative bet wrong, you'll gain money? Wrong!",
  "Please. Seriously. At least bet 0.",
  "Don't be a negative nancy"
]

const MESSAGES_TOOMUCH = [
  "Yeah, you don't quite have that much",
  "Nice try, but let's keep it real",
  "If you wanted to bet that much, you should have earned it in the first place!"
]

const MESSAGES_NOENTRY = [
  "Ya gotta wager something!",
  "Looks like you forgot your wager...",
  "If you really want to wager 0, go ahead and enter a 0"
]

const MESSAGES_INTEGER = [
  "Integers only ... MATT!!!"
]

function pickRandom(array) {
  return array[Math.floor(Math.random()*array.length)]
}

class FinalWager extends PureComponent {
  constructor(props) {
    super(props);

    this.finalJeopardyWagerElement = React.createRef()

    this.state = {
      finalJeopardyWager: '',
      finalWagerError: null,
      finalWagerMessage: null,
    }
  }

  onChange = (e) => {
    if (e.target.value.length <= 15) {
      this.setState({[e.target.name]: e.target.value})
    } else {
      e.preventDefault()
    }
  }

  handleFinalWager = (e) => {
    console.log(`[player] submitting final wager of ${this.state.finalJeopardyWager}`)
    const n = Number(this.state.finalJeopardyWager);
    if (isNaN(n)) {
      this.setState({finalWagerError: pickRandom(MESSAGES_NAN), finalWagerMessage: null})
      this.finalJeopardyWagerElement.current.select();
    } else if (this.state.finalJeopardyWager === '-0') {
      this.setState({finalWagerError: 'Why on earth would you even try this?!', finalWagerMessage: null})
      this.finalJeopardyWagerElement.current.select();
    } else if (this.state.finalJeopardyWager === '') {
      this.setState({wagerError: pickRandom(MESSAGES_NOENTRY)})
      this.finalJeopardyWagerElement.current.select();
    } else if (n < 0) {
      this.setState({finalWagerError: pickRandom(MESSAGES_NEGATIVE), finalWagerMessage: null})
      this.finalJeopardyWagerElement.current.select();
    } else if (n > this.props.finalTeamScore) {
      this.setState({finalWagerError: pickRandom(MESSAGES_TOOMUCH), finalWagerMessage: null})
      this.finalJeopardyWagerElement.current.select();
    } else if (!Number.isSafeInteger(n)) {
      this.setState({finalWagerError: pickRandom(MESSAGES_INTEGER), finalWagerMessage: null})
      this.finalJeopardyWagerElement.current.select();
    } else {
      this.setState({finalWagerError: null, finalWagerMessage: 'All set!'})
      this.props.submitFinalWager(this.state.finalJeopardyWager)
    }
    e.preventDefault()
  }

  render() {
    return (
      <div>
        <form id="final-wager-form" onSubmit={this.handleFinalWager}>
          <div>You have ${this.props.finalTeamScore}</div>
          <br/>
          {this.props.teams
              .filter(p => p.teamName !== this.props.teamName && p.score > 0)
              .map((p, i) =>
                <div key={i}>
                  You are 
                  {this.props.finalTeamScore - p.score > 0 &&
                    ` ${this.props.finalTeamScore - p.score} ahead of `
                  }
                  {this.props.finalTeamScore - p.score < 0 &&
                    ` ${p.score - this.props.finalTeamScore} behind `
                  }
                  {this.props.finalTeamScore - p.score === 0 &&
                    ` tied with `
                  }
                  {p.teamName}
                </div>
              )
          }

          <p/>
          <div>Final Jeopardy wager:</div>
          <input id="final-wager-input" type="text" name="finalJeopardyWager" className="buzzer-large buzzer-input" 
            inputmode="numeric"
            ref={this.finalJeopardyWagerElement}
            autoFocus
            value={this.state.finalJeopardyWager} 
            onChange={this.onChange}
          />
          <div className="final-answer-help">Anyone on the team can submit; only the last submitted entry will count</div>
          {this.state.finalWagerError && 
            <div className="final-entry-error">{this.state.finalWagerError}</div>
          }
          {this.state.finalWagerMessage && 
            <div className="final-entry-message">{this.state.finalWagerMessage}</div>
          }
          <p/>
          <button id="final-wager-button" className="buzzer-yes-button buzzer-big-font">Submit</button>
        </form>

        <FinalStatus finalStatus={this.props.finalStatus} answering={this.props.answering}/>
      </div>
    )
  }
}

export default FinalWager
