import React, { PureComponent } from 'react';
import { connect } from "react-redux";
import './BuzzerHost.css'
import {QRCodeSVG} from 'qrcode.react';
import io from "socket.io-client";
import {hotkeys} from 'react-keyboard-shortcuts'
import {activateBuzzers, deactivateBuzzers, gotValidBuzz, resetTimer, hideTimer, addTeam, addBuzzer, removeBuzzer, setBuzzerLatency, updateBuzzerStats, activateTeam, incTeamScoreById, decTeamScoreById, updateTeamWagerById, updateTeamAnswerById, dailyDoubleClicked, clueClosed, setupClue, displayClue, setLastAnswer, buzzerIsPickingClue, buzzerIsViewingAnswer, startNextRound} from "../redux/actions/index";
import nextInt from '../sequence'
import emitter from '../redux/emitter'

const mapStateToProps = state => {
  return {
    game: state.gameplay.game,
    roundNumber: state.gameplay.roundNumber,
    currentRow: state.gameplay.currentRow,
    currentCol: state.gameplay.currentCol,
    previousRow: state.gameplay.previousRow,
    previousCol: state.gameplay.previousCol,
    activeClueValue: state.gameplay.activeClueValue,
    buzzersActive: state.gameplay.buzzersActive,
    buzzinTimerExpired: state.gameplay.buzzinTimerExpired,
    editing: state.gameplay.editing,
    isClueDisplayed: state.gameplay.isClueDisplayed,
    isDailyDouble: state.gameplay.isDailyDouble,
    isFinal: state.gameplay.isFinal,
    isFinalAnswer: state.gameplay.isFinalAnswer,
    isFinalResults: state.gameplay.isFinalResults,
    
    lockout: state.options.lockout,
    rebuzz: state.options.rebuzz,
    doBuzzersJoinTeams: state.options.doBuzzersJoinTeams,
    expertMode: state.options.expertMode,
    autohost: state.options.autohost,
    voice: state.options.voice,
    devSkipLatencyReport: state.options.devSkipLatencyReport,
    
    teams: state.teams,
    buzzers: state.buzzers,
  }
}

function mapDispatchToProps(dispatch) {
  return {
    activateBuzzers: () => dispatch(activateBuzzers()),
    deactivateBuzzers: () => dispatch(deactivateBuzzers()),
    gotValidBuzz: (isValid) => dispatch(gotValidBuzz(isValid)),
    resetTimer: () => dispatch(resetTimer()),
    hideTimer: () => dispatch(hideTimer()),
    addTeam: (teamId, teamName) => dispatch(addTeam(teamId, teamName)),
    addBuzzer: (buzzerId, buzzerName, teamId) => dispatch(addBuzzer(buzzerId, buzzerName, teamId)),
    removeBuzzer: (buzzerId) => dispatch(removeBuzzer(buzzerId)),
    setBuzzerLatency: (buzzerId, latencyAverage, latencyMax) => dispatch(setBuzzerLatency(buzzerId, latencyAverage, latencyMax)),
    updateBuzzerStats: (buzzerId, stats) => dispatch(updateBuzzerStats(buzzerId, stats)),
    activateTeam: (teamId) => dispatch(activateTeam(teamId)),
    incTeamScoreById: (teamId, clueValue) => dispatch(incTeamScoreById(teamId, clueValue)),
    decTeamScoreById: (teamId, clueValue) => dispatch(decTeamScoreById(teamId, clueValue)),
    updateTeamWagerById: (teamId, wager) => dispatch(updateTeamWagerById(teamId, wager)),
    updateTeamAnswerById: (teamId, answer) => dispatch(updateTeamAnswerById(teamId, answer)),
    dailyDoubleClicked: () => dispatch(dailyDoubleClicked()),
    clueClosed: (buzzerId) => dispatch(clueClosed(buzzerId)),
    setupClue: (r,c) => dispatch(setupClue(r,c)),
    displayClue: (r,c) => dispatch(displayClue(r,c)),
    setLastAnswer: (answer) => dispatch(setLastAnswer(answer)),
    buzzerIsPickingClue: (buzzerId) => dispatch(buzzerIsPickingClue(buzzerId)),
    buzzerIsViewingAnswer: (buzzerId) => dispatch(buzzerIsViewingAnswer(buzzerId)),
    startNextRound: () => dispatch(startNextRound()),
    danger: (state) => dispatch({type: 'DANGER', state: state}),
  }
}

class BuzzerHost extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      gameId: null,
      buzzerActivationTime: 0,
      invalidBuzzers: [],
      pendingBuzzers: [],
      validBuzzers: [],
      lastRightBuzzerId: null,

      // buzzers: ["Jack"],
      // invalidBuzzers: [{buzzerName: "Jill", status: "buzzers inactive"}],
      // validBuzzers: [
      //   {buzzerName:'aaa', teamId:1, active:false},
      //   {buzzerName:'bbb', teamId:2, active:false},
      //   {buzzerName:'ccc', teamId:3, active:true},
      // ],
    }
  }

  hot_keys = {
    'a': {
      handler: (event) => this.props.editing || (this.activateBuzzers() && event.preventDefault()),
    },
    'd': {
      handler: (event) => this.props.editing || (this.deactivateBuzzers() && event.preventDefault()),
    },
    'c': {
      handler: (event) => this.props.editing || (this.clearBuzzers() && event.preventDefault()),
    },
    '=': {
      handler: (event) => this.props.editing || (this.currentBuzzerRight() && event.preventDefault()),
    },
    'y': {
      handler: (event) => this.props.editing || (this.currentBuzzerRight() && event.preventDefault()),
    },
    '-': {
      handler: (event) => this.props.editing || (this.currentBuzzerWrong() && event.preventDefault()),
    },
    'n': {
      handler: (event) => this.props.editing || (this.currentBuzzerWrong() && event.preventDefault()),
    },
  }

  componentDidMount() {
    this.addSocketListeners()
    this.addEmitterListeners()
  }

  // for events coming from the server off socket.io
  addSocketListeners() {
    console.log(`[admin] connecting to io... ${process.env.REACT_APP_API}`)
    this.socket = io(process.env.REACT_APP_API, {transports: ['websocket']});
    // this.socket = io(process.env.REACT_APP_API);

    this.socket.on('new-buzzer', (data) => {
      console.log(`[admin] new buzzer: ${data.buzzerName}`)
      if (this.props.doBuzzersJoinTeams) {
        this.socket.emit('team-list', {gameId: this.state.gameId, teamList: this.props.teams, buzzerId: data.buzzerId, buzzerName: data.buzzerName})
      } else {
        // only trigger autohost info if autohost is on and this is the first registered buzzer
        if (this.props.autohost && this.props.buzzers.length === 0) {
          const board = this.buildBoardState()
          this.socket.emit('pick-clue', {
            gameId: this.state.gameId, 
            buzzerId: data.buzzerId, 
            board: board, 
            roundNumber: this.props.roundNumber,
            previousRow: this.props.previousRow,
            previousCol: this.props.previousCol,
          })
        }
        this.setState({lastRightBuzzerId: data.buzzerId})
        this.props.addBuzzer(data.buzzerId, data.buzzerName, -1)
      }
    })

    this.socket.on('join-team', (data) => {
      console.log(`[admin] join-team`, data)
      var teamId
      if (!data.teamId) {
        if (!!data.teamName) {
          // new team, unless teamName matches
          const existingTeam = this.props.teams.find((p) => p.teamName === data.teamName)
          if (!!existingTeam) {
            teamId = existingTeam.id
          } else {
            teamId = nextInt()
            this.props.addTeam(teamId, data.teamName)
          }
        } else {
          // this buzzer didn't join a team
          teamId = -1
        }
      } else {
        // add user to existing team
        teamId = data.teamId
      }

      const teamJoinedData = {gameId: this.state.gameId, teamId: teamId, buzzerName: data.buzzerName}
      // only include autohost info if autohost is on and this is the first registered buzzer
      if (this.props.autohost && this.props.buzzers.length === 0) {
        teamJoinedData.roundNumber = this.props.roundNumber
        teamJoinedData.board = this.buildBoardState()
        teamJoinedData.previousRow = this.props.previousRow
        teamJoinedData.previousCol = this.props.previousCol
        this.setState({lastRightBuzzerId: data.buzzerId})
      }
      this.props.addBuzzer(data.buzzerId, data.buzzerName, teamId)
      this.socket.emit('team-joined', teamJoinedData)
    })

    this.socket.on('buzz', (buzzerId, buzzerName, teamId, status, buzzTime) => {
      if (!this.props.isDailyDouble && status === 'good') {
        if (this.checkList(buzzerName, this.state.pendingBuzzers)) {
          console.log(`[admin] ignoring valid buzz for ${buzzerName} - already pending`)
          return
        }
        if (this.checkList(buzzerName, this.state.validBuzzers)) {
          console.log(`[admin] ignoring valid buzz for ${buzzerName} - already got a valid buzz`)
          return
        }
        console.log(`[admin] valid buzz for ${buzzerName} on team ${teamId} status ${status} buzzTime ${buzzTime}`)

        // buzz goes to pending list
        // calculate time in pending:
        const N = Date.now()
        // R = time this buzz arrived (since the buzzer activation message was sent)
        const R = N - this.state.buzzerActivationTime
        // B = this buzzer's reaction/buzzin time
        const B = buzzTime
        // M = max latency for remaining buzzers
        const M = this.findMaxRemainingLatency(buzzerId)
        // P = time to wait to ensure no remaining buzzer can arrive with lower B
        // todo document how this function came to be
        // It seemed like a good idea at some point but now I can't remember,
        // and there are cases when a slower buzz gets promoted too quickly
        // and a faster buzz comes in a very short time later (also this formula
        // may have been working better with the animalese voice, because
        // this.state.buzzerActivationTime was more closely aligned with the
        // time buzzers spend listening to the voice, so either I should
        // get the actual voice duration and use that here, and/or figure
        // out why I used 'now()' in this calculation).
        // As a stop-gap, I'm setting a 1/4 second minimum promo wait time to try
        // and catch these cases, until I fugure out a better formula.

        const P = Math.max(3.5 * M + B - R, 250)
        // set timer to promote this buzz from pending to valid
        console.log(`[admin] R=${R} B=${B} M=${M} buzzer pending for ${P} ms`)

        // add to pending, sorted by buzzTime
        const pending = [...this.state.pendingBuzzers, {buzzerId: buzzerId, buzzerName: buzzerName, teamId: teamId, buzzTime: buzzTime, promoTime: N+P}]
        pending.sort((a, b) => a.buzzTime - b.buzzTime)
        this.setState({pendingBuzzers: pending})
        clearTimeout(this.promoTimer)
        if (P > 0) {
          this.promoTimer = setTimeout(this.promotion, P)
        } else {
          this.promotion(pending)
        }

        this.setState({
          invalidBuzzers: this.removeFromList(buzzerName, this.state.invalidBuzzers),
        })
      } else if (this.checkList(buzzerName, this.state.validBuzzers)) {
        console.log(`[admin] ignoring invalid buzz for ${buzzerName} - already valid`)
      } else {
        console.log(`[admin] invalid buzz for ${buzzerName} on team ${teamId} status ${status}`)
        // remove in case it's already there
        const i = this.removeFromList(buzzerName, this.state.invalidBuzzers)
        i.unshift({buzzerName: buzzerName, status: status})
        this.setState({invalidBuzzers: i})
      }
    })

    this.socket.on('answer-revealed', (buzzerId, teamId) => {
      console.log(`[admin] answer-revealed ${buzzerId}`)
      const buzzers = this.state.validBuzzers.map((b, i) => {
        b.revealed = (b.buzzerId === buzzerId)
        return b
      })
      this.props.buzzerIsViewingAnswer(buzzerId)
      this.props.hideTimer()
      this.setState({validBuzzers: buzzers})
      if (this.props.isFinalResults) emitter.emit('final-answer-revealed', teamId)
    })

    this.socket.on('buzzer-answered-right', (buzzerId, teamId, lastAnswer) => {
      console.log(`[admin] buzzer-answered-right ${buzzerId}`)

      if (this.props.isFinal) {
        emitter.emit('final-answer-scored', teamId, true, lastAnswer)
      } else {
        this.buzzerRight(buzzerId)
        this.props.clueClosed(buzzerId)
        this.props.setLastAnswer(lastAnswer)
        // todo extra emit, left over from when I was doing
        // the socketMessage state trick to "dispatch" a 
        // clear-buzzers action, which didn't always work
        // now with local app pub-sub I hope this is not necessary
        // this.clearBuzzersFrom(buzzerId)

        const board = this.buildBoardState()
        console.log(`[prevRow] ${this.props.previousRow} [prevCol] ${this.props.previousCol}`)
        this.socket.emit('buzzer-answered-right-ack', {
          gameId: this.state.gameId, 
          buzzerId: buzzerId, 
          board: board, 
          roundNumber: this.props.roundNumber,
          previousRow: this.props.previousRow,
          previousCol: this.props.previousCol,
        })
      }
    })

    this.socket.on('buzzer-answered-wrong', (buzzerId, teamId, skipped, lastAnswer) => {
      console.log('[admin] buzzer-answered-wrong')
      if (this.props.isFinal) {
        emitter.emit('final-answer-scored', teamId, false, lastAnswer)
      } else {
        this.buzzerWrong(buzzerId, null, skipped)
      }
    })

    this.socket.on('show-clue', (buzzerId, row, col) => {
      console.log(`[admin] show-clue: ${buzzerId}`)
      this.props.setupClue(row, col)
      setTimeout(() => this.props.displayClue(row, col), 0)
    })

    this.socket.on('buzzer-picking-clue', (buzzerId) => {
      console.log(`[admin] buzzer-picking-clue: ${buzzerId}`)
      this.props.buzzerIsPickingClue(buzzerId)
    })

    this.socket.on('close-clue', (buzzerId, lastAnswer) => {
      console.log(`[admin] close-clue: ${buzzerId}`)
      // todo extra emit, left over from when I was doing
      // the socketMessage state trick to "dispatch" a 
      // clear-buzzers action, which didn't always work
      // now with local app pub-sub I hope this is not necessary
      // this.clearBuzzersFrom(buzzerId)
      this.props.clueClosed(buzzerId)
      this.props.setLastAnswer(lastAnswer)
    })

    this.socket.on('daily-double-wager', (data) => {
      console.log(`[admin] daily-double-wager: ${data.wager} by ${data.buzzerId} on team ${data.teamId}`)
      this.props.updateTeamWagerById(data.teamId, data.wager)
      this.props.dailyDoubleClicked()
    })

    this.socket.on('remove-buzzer', (buzzerId, buzzerName) => {
      console.log(`[admin] remove buzzer: ${buzzerName}`)
      this.props.removeBuzzer(buzzerId)
      this.setState({
        validBuzzers: this.removeFromList(buzzerName, this.state.validBuzzers),
        invalidBuzzers: this.removeFromList(buzzerName, this.state.invalidBuzzers),
      })
    })

    this.socket.on('next-round', () => {
      console.log(`[admin] next-round`)
      this.props.startNextRound()
    })

    this.socket.on('final-wager', (buzzerName, teamId, wager) => {
      console.log(`[admin] final-wager from ${buzzerName} on team ${teamId} of ${wager}`)
      this.props.updateTeamWagerById(teamId, parseInt(wager))
      this.socket.emit('final-status', this.buildFinalStatus(), this.props.autohost)
      if (this.props.autohost && this.allFinalWagersIn()) emitter.emit('final-wagers-done')
    })

    this.socket.on('final-answer', (buzzerName, teamId, answer) => {
      console.log(`[admin] final-answer from ${buzzerName} on team ${teamId} of ${answer}`)
      this.props.updateTeamAnswerById(teamId, answer)
      this.socket.emit('final-status', this.buildFinalStatus(), this.props.autohost)
      if (this.props.autohost && this.allFinalAnswersIn()) emitter.emit('final-answers-done')
    })

    this.socket.on('disconnect', (reason) => {
      console.log(`[admin] server disconnect: ${reason}`)
      this.setState({gameId: null, buzzers: [], invalidBuzzers: [], validBuzzers: [], pendingBuzzers: []})
      // todo forego reconnection attempts until I figure out how to distinguish
      // between a network blip and the actual server going away (because this could
      // keep a heroku dyno perpetually restarting if someone keeps the page up when
      // they're not using it)
      this.socket.close()
    })
  
    this.socket.on('connect', () => {
      console.log(`[admin] connect`)
      // todo don't blindly reset to a new game upon reconnect,
      // see if the server still has my gameId and reconnect to that
      // if possible
      this.startNewGame()
    })
  
    this.socket.on('connect_error', (error) => {
      console.log(`[admin] connect_error: ${error}`)
    })
  
    this.socket.on('connect_timeout', (timeout) => {
      console.log(`[admin] connect_timeout: ${timeout}`)
    })
  
    this.socket.on('error', (error) => {
      console.log(`[admin] error: ${error}`)
    })
  
    this.socket.on('reconnect', (attemptNumber) => {
      console.log(`[admin] reconnect: ${attemptNumber}`)
    })
  
    this.socket.on('reconnect_attempt', (attemptNumber) => {
      console.log(`[admin] reconnect_attempt: ${attemptNumber}`)
    })
  
    this.socket.on('reconnecting', (attemptNumber) => {
      console.log(`[admin] reconnecting: ${attemptNumber}`)
    })
  
    this.socket.on('reconnect_error', (error) => {
      console.log(`[admin] reconnect_error: ${error}`)
    })
  
    this.socket.on('reconnect_failed', () => {
      console.log(`[admin] reconnect_failed`)
    })
  
    this.socket.on('pong', (latency) => {
      console.log(`[admin] pong: ${latency}`)
    })
  
    this.socket.on('buzzer-latency', (buzzerId, latencyAverage, latencyMax) => {
      // TEST CODE
      if (!this.props.devSkipLatencyReport) {
        console.log(`[admin] buzzer ${buzzerId} latency ${latencyAverage} max ${latencyMax}`)
        this.props.setBuzzerLatency(buzzerId, latencyAverage, latencyMax)
      }
    })
  
    // TEST CODE: special message to allow a test to override a buzzer's reported latency
    this.socket.on('dev-buzzer-latency', (buzzerName, latencyAverage, latencyMax) => {
      console.log(`[admin] buzzer ${buzzerName} override latency ${latencyAverage} max ${latencyMax}`)
      this.props.setBuzzerLatency(buzzerName, latencyAverage, latencyMax)
    })
  
    this.socket.on('log-correct-response', (msg) => {
      // this is quite the roundabout way to get this info back to the server
      // (server emits, host listens here, host emits back to server)
      // but I can't figure out how to get the server to listen to itself to get this message
      console.log(`[admin] log-correct-response`)
      this.socket.emit('log-correct-response', msg)
    })

    // TEST CODE
    this.socket.on('dev', (data) => this._dev(data))
  }

  // for events coming from components within this app
  addEmitterListeners() {
    emitter.on('clear-buzzers', (buzzerId, djTeamId) => {
      console.log(`[admin] on clear-buzzers ${buzzerId} ${djTeamId}`)
      if (this.props.autohost) {
        // autohost clear buzzers is special, to allow buzzer to potentially
        // enter an autohost-specific state
        if (djTeamId) {
          const startingBuzzerId = this.firstBuzzerOnTeam(djTeamId)
          this.clearBuzzersFrom(startingBuzzerId)

          if (startingBuzzerId) {
            const board = this.buildBoardState()
            this.socket.emit('pick-clue', {
              gameId: this.state.gameId, 
              buzzerId: startingBuzzerId, 
              board: board, 
              roundNumber: this.props.roundNumber,
              previousRow: this.props.previousRow,
              previousCol: this.props.previousCol,
              splash: 'dj'
            })
            this.props.buzzerIsPickingClue(startingBuzzerId)
            this.setState({lastRightBuzzerId: startingBuzzerId})
          }
        } else {
          this.clearBuzzersFrom(buzzerId)
        }
      } else {
        this.clearBuzzers()
      }
    })

    emitter.on('team-name-change', (teamId, teamName) => {
      console.log(`[admin] team name change for ${teamId} to ${teamName}`)
      this.socket.emit('team-name-change', {teamId: teamId, teamName: teamName, gameId: this.state.gameId})
    })

    emitter.on('clue-shown', (row, col, delay) => {
      const clue = this.props.game.rounds[this.props.roundNumber][col].clues[row]
      console.log(`[admin] clue-shown`)
      this.socket.emit('clue-shown', {
        gameId: this.state.gameId, 
        round: this.props.roundNumber,
        col: col,
        row: row,
        clue: clue.text, 
        delay: delay, 
        lockout: this.props.lockout, 
        autohost: this.props.autohost,
        expertMode: this.props.expertMode,
      })
    })

    emitter.on('daily-double', () => {
      if (this.props.autohost) {
        // find score of team of last correct buzzer
        const buzzer = this.props.buzzers.find(b => b.buzzerId === this.state.lastRightBuzzerId)
        if (buzzer && buzzer.teamId) {
          const team = this.props.teams.find(t => t.id === buzzer.teamId)
          if (team) {
            const round = this.props.game.rounds[this.props.roundNumber]
            const category = round[this.props.currentCol].category
            const clueValue = (this.props.roundNumber + 1) * 200 * (this.props.currentRow + 1)

            this.socket.emit('daily-double', {
              gameId: this.state.gameId, 
              buzzerId: this.state.lastRightBuzzerId, 
              teamScore: team.score,
              category: category,
              clueValue: clueValue,
            })
          }
        }
      }
    })

    emitter.on('send-buzzer-stats', (buzzerId, stats) => {
      this.socket.emit('send-buzzer-stats', buzzerId, stats, this.props.buzzers.length)
    })

    emitter.on('final-reveal-pre-answer', (teamId) => {
      // comes during autohost only
      this.socket.emit('final-reveal-pre-answer', teamId)
    })

    emitter.on('game-over', () => {
      const teamResults = this.props.teams.map((team) => ({teamName: team.teamName, score: team.score}));
      try {
        console.log(`[admin] game-over ${this.state.gameId} results: ${JSON.stringify(teamResults)}`);
      } catch (error) {
        console.log(`[admin] game-over ${this.state.gameId} results failed to stringify`);
      }
      this.socket.emit('game-over', this.state.gameId, teamResults);
    })

    // TEST CODE
    emitter.on('dev', (data) => this._dev(data))
  }

  // TEST CODE
  _dev(data) {
    if (data.state) {
      console.log(`[admin] DEV Danger!! state incoming...`)
      this.props.danger(data.state)
    }
    if (data.emit) {
      console.log(`[admin] DEV Danger!! emit incoming...`)
      emitter.emit(data.emit)
    }
  }

  componentWillUnmount() {
    this.socket.close()
  }

  componentDidUpdate(prevProps) {
    if (prevProps.buzzersActive !== this.props.buzzersActive) {
      if (this.props.buzzersActive) {
        this.activateBuzzers()
      } else {
        this.deactivateBuzzers()
        if (this.props.autohost && this.props.buzzinTimerExpired) {
          // if autohost buzzers became inactive due to the timer expiring, 
          // tell the last buzzer who picked to pick the next clue again
          this.noOneGotIt()
        }
      }
    }
    if (prevProps.lockout !== this.props.lockout) {
      this.socket.emit('set-lockout', this.props.lockout)
    }
    if (prevProps.rebuzz !== this.props.rebuzz) {
      this.socket.emit('set-rebuzz', this.props.rebuzz)
    }
    if (prevProps.doBuzzersJoinTeams !== this.props.doBuzzersJoinTeams) {
      this.socket.emit('set-buzzersjointeams', this.props.doBuzzersJoinTeams)
    }
    if (prevProps.voice !== this.props.voice) {
      this.socket.emit('set-voice', this.props.voice)
    }
    if (prevProps.isFinal !== this.props.isFinal && this.props.isFinal) {
      this.socket.emit('final-jeopardy', this.props.teams, this.buildFinalStatus(), this.props.autohost)
    }
    if (prevProps.isFinalAnswer !== this.props.isFinalAnswer && this.props.isFinalAnswer) {
      this.socket.emit('final-answers')
    }
    if (prevProps.isFinalResults !== this.props.isFinalResults && this.props.isFinalResults && !this.props.autohost) {
      this.socket.emit('final-results')
    }
  }

  startNewGame() {
    console.log('[admin] emit new-game')
    const data = {title: this.props.game.title, showDate: this.props.game.showDate};
    // backdoor for uberduck voice - audio must already exist in storage
    if (window.location.search.indexOf('uvoice') > 0) {
      data['voice'] = 'u';
      console.log('using uberduck voice')
    }
    this.socket.emit('new-game', data, (gameId) => {
      console.log(`[admin] new-game ack: ${gameId}`)
      this.setState({gameId: gameId})
    })
  }

  removeFromList(name, list) {
    return list.filter((e,_) => e.buzzerName !== name)
  }

  checkList(name, list) {
    return list.some(e => e.buzzerName === name)
  }

  clearBuzzers = () => {
    this.clearBuzzersFrom(null)
  }

  clearBuzzersFrom = (buzzerId) => {
    console.log(`[admin] emit clear-buzzers originating from ${buzzerId}`)
    this.socket.emit('clear-buzzers', {gameId: this.state.gameId, buzzerId: buzzerId})
    this.setState({invalidBuzzers: [], validBuzzers: [], pendingBuzzers: []})
  }

  deactivateBuzzers = () => {
    this.socket.emit('deactivate-buzzers')
    this.setState({buzzerActivationTime: 0})
    this.props.deactivateBuzzers()
  }

  activateBuzzers = () => {
    if (!this.props.isDailyDouble) {
      if (!this.props.autohost) {
        this.socket.emit('activate-buzzers')
        this.setState({validBuzzers: []})
        this.props.activateBuzzers()
      }
      this.setState({buzzerActivationTime: Date.now()})
    }
  }

  findMaxRemainingLatency = (buzzerId) => {
    // for all buzzers != buzzerId and not in valid or pending, get max latency
    const buzzerMap = new Map(this.props.buzzers.map(b => [b.buzzerId, b]))
    buzzerMap.delete(buzzerId)
    this.state.validBuzzers.forEach(b => buzzerMap.delete(b.buzzerId))
    this.state.pendingBuzzers.forEach(b => buzzerMap.delete(b.buzzerId))
    if (buzzerMap.size === 0) return 0
    return Array.from(buzzerMap.values(), b => b.stats.latencyMax || 0).reduce((m, c) => Math.max(m, c), -Infinity)
  }

  promotion = (pending = [...this.state.pendingBuzzers]) => {

    if (pending.length === 0) return

    // if everyone has buzzed in, promote everyone immediately
    const instaPromo = (pending.length + this.state.validBuzzers.length === this.props.buzzers.length)
    if (instaPromo) console.log(`[admin] all ${this.props.buzzers.length} buzzers in - instaPromo!`)

    const now = Date.now()
    const valid = [...this.state.validBuzzers]
    let anyActive = (valid.findIndex(b => b.active === true) !== -1)
    let sessionPromoCount = 0

    // promote all buzzers whose promoTime has passed
    while (pending.length > 0 && (pending[0].promoTime < now || instaPromo)) {
      const buzzer = pending.shift()
      
      if (buzzer.status === 'first') this.deactivateBuzzers()
      this.props.gotValidBuzz(true)
      let active = false
      if (!anyActive) {
        // activate if no others currently active
        // (first buzz, or buzz comes in after others were all wrong)
        this.props.activateTeam(buzzer.teamId)
        anyActive = true
        active = true

        if (this.props.autohost) {
          // signal buzzer to enter its answering phase
          this.socket.emit('buzzer-answering', {gameId: this.state.gameId, buzzerId: buzzer.buzzerId})
        }
      }

      // upon promotion, put into valid list after other buzzers whose:
      //  - status is 'wrong'
      //  - active is true
      //  - B is < this one
      var i = 0
      while (i < valid.length) {
        const v = valid[i]
        i++
        if (v.active) continue
        if (v.status === 'wrong') continue
        if (v.buzzTime < buzzer.buzzTime) continue
        break
      }
      valid.splice(i, 0, {...buzzer, active: active})
      sessionPromoCount++
    }
    this.setState({validBuzzers: valid, pendingBuzzers: pending})
    console.log(`[admin] promoted ${sessionPromoCount} buzzers`)

    // schedule next promotion
    clearTimeout(this.promoTimer)
    if (pending.length > 0) {
      const nextPromoDelay = pending[0].promoTime - now
      console.log(`[admin] next promotion in ${nextPromoDelay}`)
      this.promoTimer = setTimeout(this.promotion, nextPromoDelay)
    }
  }

  updateBuzzerStats = (buzzer, right) => {
    const team = this.props.teams.find(t => t.id === buzzer.teamId)
    const wager = team ? team.wager : null
    const validBuzzer = this.state.validBuzzers.find(b => b.buzzerId === buzzer.buzzerId)
    const buzzTime = validBuzzer ? validBuzzer.buzzTime : null
    const first = this.state.validBuzzers.length > 0 && this.state.validBuzzers[0].buzzerId === buzzer.buzzerId
    const data = {
      wasRight: right, 
      clueValue: this.props.activeClueValue,
      wasDailyDouble: this.props.isDailyDouble,
      wager: wager,
      buzzTime: buzzTime,
      wasFirst: first
    }
    this.props.updateBuzzerStats(buzzer.buzzerId, data)
  }

  buzzerRight = (buzzerId, buzzer) => {
    const activeBuzzer = buzzer ? buzzer : this.props.buzzers.find(b => b.buzzerId === buzzerId)
    // right buzzer's team gains points
    this.props.incTeamScoreById(activeBuzzer.teamId, this.props.activeClueValue)

    // turn off buzzers, mark current buzzer as "right"
    this.deactivateBuzzers()
    const buzzers = this.state.validBuzzers.map((b) => {
      b.active = false
      b.revealed = false
      return b
    })
    this.props.buzzerIsViewingAnswer(null)
    activeBuzzer.status = 'right'
    this.setState({validBuzzers: buzzers, lastRightBuzzerId: buzzerId})

    this.updateBuzzerStats(activeBuzzer, true)
  }

  currentBuzzerRight = () => {
    const activeBuzzer = this.state.validBuzzers.find(b => b.active === true)
    if (activeBuzzer) {
      this.buzzerRight(activeBuzzer.buzzerId, activeBuzzer)
      this.socket.emit('disable-buzzer', activeBuzzer.buzzerId)
    }
  }

  buzzerWrong = (buzzerId, buzzer, skipped = false) => {
    const activeBuzzer = buzzer ? buzzer : this.props.buzzers.find(b => b.buzzerId === buzzerId)
    if (!skipped) {
      // wrong buzzer's team loses points
      this.props.decTeamScoreById(activeBuzzer.teamId, this.props.activeClueValue)
    }

    this.props.buzzerIsViewingAnswer(null)
    
    if (this.props.isDailyDouble) {
      // this function shouldn't be called during a daily double in a non-autohost game,
      // but if it ever does, nothing should happen
      if (this.props.autohost) {
        const board = this.buildBoardState(this.props.currentRow, this.props.currentCol)
        this.socket.emit('pick-clue', {
          gameId: this.state.gameId, 
          buzzerId: buzzerId, 
          board: board, roundNumber: this.props.roundNumber,
          previousRow: this.props.previousRow,
          previousCol: this.props.previousCol,
          splash: 'dd'
        })
      }

    } else {
      const activeBuzzerIndex = this.state.validBuzzers.findIndex(b => b.active === true)
      if (activeBuzzerIndex !== -1) {
        // activate the next buzzer, deactivate all others, mark current buzzer as "wrong"
        const buzzers = this.state.validBuzzers.map((b, i) => {
          b.active = (i === activeBuzzerIndex + 1)
          b.revealed = false
          if (i === activeBuzzerIndex) {
            b.status = 'wrong'
          }
          return b
        })

        if (activeBuzzerIndex < this.state.validBuzzers.length - 1) {
          // is there a next buzzer in line?
          const activeBuzzerId = this.state.validBuzzers[activeBuzzerIndex + 1].buzzerId

          const activeTeamId = this.state.validBuzzers[activeBuzzerIndex + 1].teamId
          this.props.activateTeam(activeTeamId)

          // reset/reactivate appropriate timers
          this.props.gotValidBuzz(true)
          this.props.resetTimer()

          if (this.props.autohost) {
            this.socket.emit('buzzer-answering', {gameId: this.state.gameId, buzzerId: activeBuzzerId})
          }
        } else if (activeBuzzerIndex < this.props.buzzers.length - 1) {
          // is there anyone else playing?
          this.props.activateBuzzers()
          this.props.gotValidBuzz(false)
          this.props.resetTimer()
        } else {
          // no one got it and there's no one left to try
          this.noOneGotIt()
        }

        this.setState({validBuzzers: buzzers})
      } else {
        this.props.resetTimer()
      }
    }

    if (!skipped) this.updateBuzzerStats(activeBuzzer, false)
  }

  currentBuzzerWrong = () => {
    const activeBuzzer = this.state.validBuzzers.find(b => b.active === true)
    if (activeBuzzer) {
      this.buzzerWrong(activeBuzzer.buzzerId, activeBuzzer)
      this.socket.emit('disable-buzzer', activeBuzzer.buzzerId)
    }
  }

  noOneGotIt = () => {
    if (this.props.autohost && this.state.lastRightBuzzerId) {
      const board = this.buildBoardState(this.props.currentRow, this.props.currentCol)
      this.socket.emit('pick-clue', {
        gameId: this.state.gameId, 
        buzzerId: this.state.lastRightBuzzerId, 
        board: board, roundNumber: this.props.roundNumber,
        previousRow: this.props.previousRow,
        previousCol: this.props.previousCol,
        splash: 'timeout'
      })
    }
  }

  buildBoardState = (skipRow, skipCol) => {
    const round = this.props.game.rounds[this.props.roundNumber]
    const board = []
    for (var col = 0; col < round.length; col++) {
      var flags = 0
      for (var row = 0; row < round[col].clues.length; row++) {
        if (!round[col].clues[row].done 
            && round[col].clues[row].value
            && !(col === skipCol && row === skipRow)
        ) {
          flags = flags | (1 << row)
        }
      }
      board.push({category: flags ? round[col].category : null, flags: flags})
    }
    return board
  }

  buildFinalStatus = () => {
    return this.props.teams
      .filter(team => team.score > 0)
      .map(team => {
        var flags = 0
        if (this.props.isFinal && team.wager !== null) flags |= 1
        if (this.props.isFinal && team.finalAnswer !== null) flags |= 2
        return {teamName: team.teamName, flags: flags}
      })
  }

  allFinalWagersIn = () => {
    var num = 0
    this.props.teams.forEach(team => {
      if (this.props.isFinal && (team.wager !== null || team.score <= 0)) num++
    })
    return num === this.props.teams.length
  }

  allFinalAnswersIn = () => {
    var num = 0
    this.props.teams.forEach(team => {
      if (this.props.isFinal && (team.finalAnswer !== null || team.score <= 0)) num++
    })
    return num === this.props.teams.length
  }

  firstBuzzerOnTeam = (teamId) => {
    const buzzer = this.props.buzzers.find(b => b.teamId === teamId)
    if (buzzer) return buzzer.buzzerId
    if (this.props.buzzers.length > 0) return this.props.buzzers[0].buzzerId
    return null
  }

  trimQueryParams = (url) => {
    const i = url.lastIndexOf('?');
    return (i > 0) ? url.substring(0, i) : url;
  }

  render() {
    return (
      <div id="buzzers" className="noselect">
        <div className="buzzer-title">BUZZERS</div>
        {this.state.gameId && 
          <div id="buzzers-info">
            {this.trimQueryParams(window.location.href) + 'buzzer'}
            <div id='gameid'>
              <div id="gameid-info">
                game id: {this.state.gameId}
              </div>
              {/* thanks for the idea Thomas */}
              <QRCodeSVG id="qrcode" value={this.trimQueryParams(window.location.href) + 'buzzer?g=' + this.state.gameId}/>
            </div>
          </div>
        }

        <div id="buzzer-subline">
          {this.props.autohost && 
            <div id="buzzer-subline-autohost">autohosted</div>
          }
          <div/>

          <div id="buzzer-actions">
            {this.props.buzzersActive &&
              <button className="pointer" tooltip="Deactivate buzzers (d)" onClick={this.deactivateBuzzers}>
                <i className="fas fa-lock"/>
              </button>
            }
            {!this.props.buzzersActive &&
              <button className="pointer" tooltip="Activate buzzers (a)" onClick={this.activateBuzzers}>
                <i className="fas fa-unlock"/>
              </button>
            }
            <button className="pointer" tooltip="Clear buzzers (c)" onClick={this.clearBuzzers}>
              <i className="fas fa-times"/>
            </button>
          </div>
        </div>

        {this.state.validBuzzers.length > 0 &&
          <div id="buzzers-valid">
            <div id="buzzers-valid-header">Buzzed!</div>
            {this.state.validBuzzers.map((e, i) => 
              <div id={`buzzer-valid-${i}`} key={i} className={(e.active ? "buzzer-border-active" : "buzzer-border-inactive")}>
                <div className={`buzzer-valid-item buzzer-content ${e.revealed ? "buzzer-content-revealed" : "buzzer-border-content"}`}>
                  <span id={`buzzer-valid-name-${i}`}>{e.buzzerName}</span>
                  {!this.props.expertMode &&
                    <span id={`buzzer-valid-time-${i}`} className="buzzer-pending-time">({e.buzzTime / 1000}s)</span>
                  }
                  {this.props.isClueDisplayed && e.active &&
                    <div className="buzzer-checks">
                      <i className="fas fa-check buzzer-right-answer pointer" onClick={this.currentBuzzerRight}></i>
                      <i className="fas fa-times buzzer-wrong-answer pointer" onClick={this.currentBuzzerWrong}></i>
                    </div>
                  }
                  {this.props.isClueDisplayed && !e.active && e.status === 'right' &&
                    <div className="buzzer-checks">
                      <i id={`buzzer-valid-right-${i}`} className="fas fa-check buzzer-right-answer"></i>
                    </div>
                  }
                  {this.props.isClueDisplayed && !e.active && e.status === 'wrong' &&
                    <div className="buzzer-checks">
                      <i id={`buzzer-valid-wrong-${i}`} className="fas fa-times buzzer-wrong-answer"></i>
                    </div>
                  }
                </div>
              </div>
            )}
          </div>
        }

        {this.state.pendingBuzzers.length > 0 &&
          <div id="buzzers-pending">
            <div id="buzzers-pending-header">Pending...</div>
            {this.state.pendingBuzzers.map((e, i) => 
              <div id={`buzzer-pending-${i}`} className="buzzer-pending-item" key={i}>
                <span id={`buzzer-pending-name-${i}`}>{e.buzzerName}</span>
                {!this.props.expertMode &&
                  <span id={`buzzer-pending-time-${i}`} className="buzzer-pending-time">({Math.round(e.buzzTime / 10) / 100}s)</span>
                }
              </div>
            )}
          </div>
        }

        {this.state.invalidBuzzers.length > 0 &&
          <div id="buzzers-invalid-buzz">
            <div id="buzzers-invalid-header">Didn't count:</div>
              <ul>
              {this.state.invalidBuzzers.map((e, i) => 
                <li id={`buzzer-invalid-${i}`} key={i}>
                  <span id={`buzzer-invalid-name-${i}`}>{e.buzzerName}</span>
                  {e.status === 'locked out' ? ' (locked out)' : ''}
                </li>
              )}
              </ul>
          </div>
        }

        <div className="buzzer-list">
          <div id="buzzers-list-header">Who's in:</div>
            <ul>
            {this.props.buzzers.map((buzzer, i) => 
              <li key={i} id={`buzzer-name-${i}`}>
                <span className="buzzer-name">{buzzer.buzzerName}</span>
                {buzzer.picking && <i className="fas fa-th buzzer-picking"></i>}
                {buzzer.revealed && <i className="fas fa-eye buzzer-revealed"></i>}
                {/* <BuzzerStats stats={buzzer.stats}/> */}
              </li>
            )}
            </ul>
        </div>
      </div>
    )
  }
}

export { BuzzerHost }
export default connect(mapStateToProps, mapDispatchToProps)(hotkeys(BuzzerHost))
