import React, { PureComponent } from 'react';
import { connect } from "react-redux";
import './Timer.css'
import TimesUpSound from '../assets/times-up.mp3';
import {buzzinTimerExpired} from "../redux/actions/index";
import emitter from '../redux/emitter'

export const STYLE_BUZZIN = 1
export const STYLE_ANSWER = 2

const mapStateToProps = state => {
  return {
    resetTimer: state.gameplay.resetTimer,

    buzzInTimeout: state.options.buzzInTimeout,
    answerTimeout: state.options.answerTimeout,
    autoDisableBuzzers: state.options.autoDisableBuzzers,
  }
}

function mapDispatchToProps(dispatch) {
  return {
    buzzinTimerExpired: () => dispatch(buzzinTimerExpired()),
  }
}

class Timer extends PureComponent {
  constructor(props) {
    super(props);

    this.audio = new Audio(TimesUpSound)

    this.state = {
      start: 0,
      duration: this.props.timerStyle === STYLE_BUZZIN ? this.props.buzzInTimeout : this.props.answerTimeout,
      progress: 100
    }
  }

  componentDidMount() {
    this.setState({start: Date.now()})
    this.t = setInterval(this.calculateProgress, 10)

    emitter.on('cancel-timer', () => {
      clearInterval(this.t)
      if ((this.props.timerStyle === STYLE_BUZZIN) && this.props.autoDisableBuzzers) {
        this.props.buzzinTimerExpired()
      }
    })
  }

  componentWillUnmount() {
    clearInterval(this.t)
  }

  componentDidUpdate(prevProps) {
    if (!prevProps.resetTimer && this.props.resetTimer) {
      this.setState({start: Date.now()})
      clearInterval(this.t)
      this.t = setInterval(this.calculateProgress, 10)
    }
  }

  calculateProgress = () => {
    const pct = ((Date.now() - this.state.start) / this.state.duration) * 100
    const prog = 100 - pct < 0 ? 0 : 100 - pct
    this.setState({progress: prog})
    if (prog <= 0) {
      this.audio.play()
      clearInterval(this.t)
      if ((this.props.timerStyle === STYLE_BUZZIN) && this.props.autoDisableBuzzers) {
        this.props.buzzinTimerExpired()
      }
    }
  }

  render() {
    var parts = []
    if (this.props.timerStyle === STYLE_ANSWER) {
      const half = Math.ceil(this.state.duration / 1000)
      const numParts = half * 2 - 1
      const numLit = Math.ceil(half * (this.state.progress / 100))
      for (var i = 0; i < numParts; i++) {
        // console.log(`i:${i} numParts:${numParts} half:${half} numLit:${numLit}`)
        const lit = (Math.abs(i - half + 1) < numLit)
        const c = "timer-part " + (lit ? " timer-lit" : "")
        parts.push(<div key={i} className={c}/>)
      }
    }

    return (
      <div id="timer" className="timer-container" 
        style={{height: `${this.props.timerStyle === STYLE_BUZZIN ? 5 : 25}px`}}
      >
        {this.props.timerStyle === STYLE_BUZZIN &&
          <div id="timer-buzzin" className="timer-progress" style={{width: `${this.state.progress}%`}}/>
        }
        {this.props.timerStyle === STYLE_ANSWER &&
          [parts]
        }
      </div>
    )
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Timer)
