import PubSub from 'pubsub-js'

// asynchronous pub-sub
class LocalEmitter {
  emit(topic) {
    PubSub.publish(topic, Array.prototype.slice.call(arguments, 1))
  }

  on(topic, listener) {
    PubSub.subscribe(topic, (_, data) => listener.apply(this, data || []))
  }
}

const emitter = new LocalEmitter();

export default emitter


// --------------------------

// synchronous emitter - can require use of redux-loop if a received event
// triggers another dispatch

// import EventEmitter from 'wolfy87-eventemitter'

// const emitter = new EventEmitter();

// export default emitter
