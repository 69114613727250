import React, { PureComponent } from 'react';
import './Buzzer.css'
import './BuzzerAnswer.css'
import {hotkeys} from 'react-keyboard-shortcuts'

class BuzzerAnswer extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {
    }
  }

  hot_keys = {
    'enter': {
      handler: (event) => this.props.revealAnswer() && event.preventDefault()
    },
  }

  render() {
    return (
      <div>
        <div className="buzzer-say-answer">
          Say your answer for all to hear!!
        </div>
        <div className="buzzer-say-answer-prompt">
          then go ahead and see if you're right...
        </div>
        <button id="buzzer-reveal-button" className="buzzer-yes-button buzzer-big-font" onClick={this.props.revealAnswer}>
          Reveal Answer!
        </button>
      </div>
    )
  }
}

export default hotkeys(BuzzerAnswer)
