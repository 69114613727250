import React, { PureComponent } from 'react';
import './Buzzer.css'
import './BuzzerShowAnswer.css'

const MESSAGES = [
  "Just look disappointed.",
  "Act all surprised instead, like you can't believe you got it wrong.",
  "Let's keep everybody else in suspense.",
  "Let the next team experience the thrill of discovery.",
  "Just be stoic.",
  "Just raise your eyebrows slightly and say 'hmmm'.",
  "Act like it was so obscure you would never have gotten it anyway.",
  "Tell everybody you were just kidding with your obviously silly answer."
]

function pickRandom(array) {
  return array[Math.floor(Math.random()*array.length)]
}

class FinalShowAnswer extends PureComponent {
  render() {
    return (
      <div>
        <div className="buzzer-text">
          The answer is
        </div>
        <div id="final-correct-answer" className="buzzer-text buzzer-show-answer">
          {this.props.correctResponse}
        </div>
        <div className="buzzer-text">
          Were you right?
        </div>
        <div className="buzzer-show-answer-buttons buzzer-text">
          <button id="final-answer-right" className="buzzer-yes-button buzzer-big-font" onClick={this.props.buzzerAnsweredRight}>Yes</button>
          <button id="final-answer-wrong" className="buzzer-no-button buzzer-big-font" onClick={this.props.buzzerAnsweredWrong}>No</button>
        </div>
        <div className="buzzer-text">
          If you were wrong, don't say what the answer is. {pickRandom(MESSAGES)}
        </div>
      </div>
    )
  }
}

export default FinalShowAnswer
