import React, { PureComponent } from 'react';
import './Buzzer.css'
import CreatableSelect from 'react-select/creatable'

class BuzzerTeam extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      teamId: null,
      teamName: process.env.REACT_APP_JDEV_TEAM || '',
    }
  }

  handleTeamSelect = (e) => {
    this.props.submitTeam(this.state.teamId, this.state.teamName)
    e.preventDefault()
  }

  handleTeamChange = (d) => {
    if (!d) {
      this.setState({teamId: null, teamName: null})
    } else if (d.__isNew__) {
      this.setState({teamId: null, teamName: d.label.substring(0, 30)})
    } else {
      this.setState({teamId: d.value, teamName: d.label.substring(0, 30)})
    }
  }

  onBlur = (e) => {
    if (e.target.value && !this.state.teamName) {
      this.setState({teamId: null, teamName: e.target.value.substring(0, 30)})
    }
  }

  onHiddenChange = (e) => {
    this.setState({teamId: null, teamName: e.target.value.substring(0, 30)})
  }

  render() {
    const options = this.props.teamList
        ? this.props.teamList.map(t => ({value: t.id, label: t.teamName}))
        : null

    return (
      <div className="buzzer-column">
        <form id="buzzer-team-form" onSubmit={this.handleTeamSelect}>
          <div>Join or create a team:</div>
          <CreatableSelect 
              id="buzzer-team-dropdown"
              isClearable
              autoFocus
              onBlur={this.onBlur}
              onChange={this.handleTeamChange}
              options={options}/>
          {this.props.devE2E &&
            <div>
              <br/>
              <input id="dev-team-name" type="text" width="30"
                  value={this.state.teamName} 
                  onChange={this.onHiddenChange}
              />
            </div>
          }
          <p/>
          <button id="buzzer-submit-team" className="buzzer-yes-button buzzer-big-font">Play!</button>
        </form>
      </div>
    )
  }
}

export default BuzzerTeam
