import { createReducer } from './reducerUtils'
import emitter from '../emitter'

// also in socket.js
export const DEFAULT_LOCKOUT = 250

const initialState = {
  sidebarVisible: false,
  useBuzzers: true,
  buzzerActivationStyle: 'jeopardy',
  
  useTimers: true,
  buzzInTimeout: 7000,
  answerTimeout: 5000,
  autoDisableBuzzers: true,
  autohost: false,
  expertMode: false,

  // for testing only, can disable latency reporting
  devSkipLatencyReport: false,

  // also in socket.js - todo remove duplication
  lockout: DEFAULT_LOCKOUT,
  rebuzz: false,
  doBuzzersJoinTeams: true,
  voice: 'google',
}

function toggleSidebar(state) {
  return {...state, sidebarVisible: !state.sidebarVisible}
}

function toggleBuzzers(state) {
  return {...state, useBuzzers: !state.useBuzzers}
}

function setBuzzerActivationStyle(state, action) {
  return {...state, buzzerActivationStyle: action.style}
}

function toggleTimers(state) {
  return {...state, useTimers: !state.useTimers}
}

function setBuzzinTimeout(state, action) {
  return {...state, buzzInTimeout: action.t}
}

function setAnswerTimeout(state, action) {
  return {...state, answerTimeout: action.t}
}

function toggleAutoDisableBuzzers(state) {
  return {...state, autoDisableBuzzers: !state.autoDisableBuzzers}
}

function toggleAutohost(state) {
  if (!state.autohost && state.buzzerActivationStyle === 'jeopardy') {
    return {...state, autohost: !state.autohost, buzzerActivationStyle: 'delayed'}
  } else if (state.autohost && state.buzzerActivationStyle === 'delayed') {
      return {...state, autohost: !state.autohost, buzzerActivationStyle: 'jeopardy'}
    } else {
    return {...state, autohost: !state.autohost}
  }
}

function setLockout(state, action) {
  return {...state, lockout: action.t}
}

function toggleRebuzz(state) {
  return {...state, rebuzz: !state.rebuzz}
}

function toggleDoBuzzersJoinTeams(state) {
  return {...state, doBuzzersJoinTeams: !state.doBuzzersJoinTeams}
}

function toggleExpertMode(state) {
  return {...state, expertMode: !state.expertMode}
}

function setVoice(state, action) {
  return {...state, voice: action.voice}
}

function danger(state, action) {
  if (action.state && action.state.options) {
    const s = {...state}
    Object.assign(s, action.state.options)

    if (action.state.options.activeClues) {
      emitter.emit('dev-active-clues')
    }

    return s
  }
  return state
}

const optionsReducer = createReducer(initialState, {
  TOGGLE_SIDEBAR: toggleSidebar,
  TOGGLE_BUZZERS: toggleBuzzers,
  BUZZER_ACTIVATION_STYLE: setBuzzerActivationStyle,
  TOGGLE_TIMERS: toggleTimers,
  SET_BUZZIN_TIMEOUT: setBuzzinTimeout,
  SET_ANSWER_TIMEOUT: setAnswerTimeout,
  TOGGLE_AUTO_DISABLE_BUZZERS: toggleAutoDisableBuzzers,
  TOGGLE_AUTOHOST: toggleAutohost,
  SET_LOCKOUT: setLockout,
  TOGGLE_REBUZZ: toggleRebuzz,
  TOGGLE_BUZZERS_JOIN_TEAMS: toggleDoBuzzersJoinTeams,
  TOGGLE_EXPERT_MODE: toggleExpertMode,
  SET_VOICE: setVoice,
  DANGER: danger,
})

export default optionsReducer
