import React, {PureComponent} from 'react';
import { connect } from "react-redux";
import './FinalJeopardy.css'
import FinalJeopardyImage from '../assets/finaljeopardy.jpg';
import FinalJeopardyDing from '../assets/ding.mp3';
import FinalJeopardySong from '../assets/jeopardy.mp3';
import Clue from './Clue';
import FinalAnswer from './FinalAnswer';
import {showFinalClue, showFinalResults, incTeamScoreById, decTeamScoreById, setLastAnswer} from "../redux/actions/index";
import emitter from '../redux/emitter'

const mapStateToProps = state => {
  return {
    game: state.gameplay.game,
    isClueDisplayed: state.gameplay.isClueDisplayed,
    playFinalSong: state.gameplay.playFinalSong,
    isFinalAnswer: state.gameplay.isFinalAnswer,
    isFinalResults: state.gameplay.isFinalResults,
    teams: state.teams,
  }
}

function mapDispatchToProps(dispatch) {
  return {
    showFinalClue: () => dispatch(showFinalClue()),
    showFinalResults: () => dispatch(showFinalResults()),
    incTeamScoreById: (teamId, clueValue) => dispatch(incTeamScoreById(teamId, clueValue)),
    decTeamScoreById: (teamId, clueValue) => dispatch(decTeamScoreById(teamId, clueValue)),
    setLastAnswer: (answer) => dispatch(setLastAnswer(answer)),
  };
}

class FinalJeopardy extends PureComponent {
  constructor(props) {
    super(props)

    this.state = {
      showCategory: false,
      nextFinalTeamIndex: 0,
      finalTeams: [],
      highscore: null,
    };
  }

  componentDidMount() {
    this.t = setTimeout(() => {
      if (!this.props.isFinalAnswer) this.setState({showCategory: true})
    }, 1000)

    const sorted = [...this.props.teams]
      .filter(team => team.score > 0)
      .sort((p1, p2) => {
        if (p1.score < p2.score) return -1
        if (p1.score > p2.score) return 1
        return 0
      })
    this.setState({finalTeams: sorted})

    emitter.on('final-next', () => {
      console.log(`[admin] final-next`)
      if (this.state.showCategory) {
        this.props.showFinalClue()
        this.setState({showCategory: false})
      } else if (this.state.nextFinalTeamIndex < this.state.finalTeams.length) {
        this.props.showFinalResults()
        this.setState({nextFinalTeamIndex: Math.min(this.state.nextFinalTeamIndex + 1, this.state.finalTeams.length)})
      } else {
        const highscore = Math.max.apply(Math, this.props.teams.map(function(p) { return p.score; }))
        this.setState({highscore: highscore})
        // game over!
      }
    })

    emitter.on('final-wagers-done', () => {
      // comes during autohost only
      console.log(`[admin] final-wagers-done`)
      if (!this.props.isFinalAnswer) {
        this.props.showFinalClue()
        this.setState({showCategory: false})
      }
    })

    emitter.on('final-answers-done', () => {
      // comes during autohost only
      console.log(`[admin] final-answers-done`)
      if (this.props.isFinalAnswer && !this.props.isFinalResults) {
        this.triggerNextTeamPreAnswer()
        this.props.showFinalResults()
        this.setState({nextFinalTeamIndex: this.getNextFinalTeamIndex(), showCategory: false})
      }
    })

    emitter.on('final-answer-revealed', (teamId) => {
      if (this.state.nextFinalTeamIndex-1 < this.state.finalTeams.length 
          && teamId === this.state.finalTeams[this.state.nextFinalTeamIndex-1].id) {
        console.log(`[admin] final-answer-revealed for team ${teamId}`)
        const teams = [...this.state.finalTeams].map(t => {
          if (t.id === teamId) t.finalAnswerRevealed = true
          return t
        })
        this.setState({finalTeams: teams})
      } else {
        console.log(`[admin] ignoring final-answer-revealed for team ${teamId}`)
      }
    })

    emitter.on('final-answer-scored', (teamId, correct, lastAnswer) => {
      if (this.state.nextFinalTeamIndex-1 < this.state.finalTeams.length 
          && teamId === this.state.finalTeams[this.state.nextFinalTeamIndex-1].id) {
        console.log(`[admin] final-answer-scored for team ${teamId}`)

        var alreadyScored = true
        const teams = this.state.finalTeams.map(t => {
          if (t.id === teamId) {
            alreadyScored = t.finalAnswerScored
            t.finalAnswerScored = true
          }
          return t
        })

        // only count it once per team
        if (!alreadyScored) {
          correct && this.props.incTeamScoreById(teamId)
          !correct && this.props.decTeamScoreById(teamId)
        }

        const nextTeamIndex = this.getNextFinalTeamIndex()
        this.setState({finalTeams: teams})

        if (this.state.nextFinalTeamIndex < this.state.finalTeams.length) {
          this.triggerNextTeamPreAnswer()
          this.setState({nextFinalTeamIndex: nextTeamIndex})
        } else {
          const highscore = Math.max.apply(Math, this.props.teams.map(function(p) { return p.score; }))
          this.setState({highscore: highscore})
          this.props.setLastAnswer(lastAnswer)
          // game over!
          emitter.emit('game-over');
        }
      } else {
        console.log(`[admin] ignoring final-answer-scored for team ${teamId}`)
      }
    })
  }

  triggerNextTeamPreAnswer() {
    const teamId = this.state.finalTeams[this.state.nextFinalTeamIndex].id
    emitter.emit('final-reveal-pre-answer', teamId)
  }

  componentWillUnmount() {
    clearTimeout(this.t)
  }

  getNextFinalTeamIndex() {
    return Math.min(this.state.nextFinalTeamIndex + 1, this.state.finalTeams.length)
  }

  render() {
    var displayedResults = []
    for (let i = 0; i < this.state.nextFinalTeamIndex; i++) {
      displayedResults.push(
        <FinalAnswer key={i} 
          index={i}
          team={this.state.finalTeams[i]} 
          highscore={this.state.highscore}
          answerRevealed={this.state.finalTeams[i].finalAnswerRevealed} 
          answerScored={this.state.finalTeams[i].finalAnswerScored} 
        />
      )
    }

    return (
      <div id="final-jeopardy" className="final-container noselect"
          style={{
            backgroundImage: `url(${FinalJeopardyImage})`,
            backgroundRepeat: 'no-repeat',
            backgroundSize: '100% 100%'
          }}
      >
        {this.state.showCategory && 
          <div className="final-category">
            <audio src={FinalJeopardyDing} autoPlay/>
            {this.props.game.final_jeopardy_round.category}
          </div>
        }

        {this.props.isFinalAnswer && this.props.isClueDisplayed &&
          <div id="final-jeopardy-clue" className="final-clue-container">
            <Clue 
              text={this.props.game.final_jeopardy_round.clue.text} 
              category={this.props.game.final_jeopardy_round.category} 
            />
          </div>
        }

        {this.props.playFinalSong && 
          <audio src={FinalJeopardySong} autoPlay/>
        }

        {this.props.isFinalResults &&
          <div id="final-jeopardy-results" className="final-results-container">
            {displayedResults}
          </div>
        }
      </div>
    )
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(FinalJeopardy)
