import React from 'react';
import './BuzzerStats.css'

export default function BuzzerStats({stats}) {
  const avgFastBuzzTime =  stats.fastBuzzCount 
      ? Math.round(stats.totalFastBuzzTime / stats.fastBuzzCount) / 1000 + 's avg'
      : ''
  const avgSlowBuzzTime =  stats.slowBuzzCount 
      ? Math.round(stats.totalSlowBuzzTime / stats.slowBuzzCount) / 1000 + 's avg'
      : ''
  const avgFirstBuzzTime = stats.firstBuzzCount 
      ? Math.round(stats.totalFirstBuzzTime / stats.firstBuzzCount) / 1000 + 's avg'
      : ''
  const avgEarlyBuzzTime = stats.earlyBuzzCount 
      ? Math.round(stats.totalEarlyBuzzTime / stats.earlyBuzzCount) / 1000 + 's avg'
      : ''
  const fastestBuzzTime = stats.fastestBuzzTime
      ? Math.round(stats.fastestBuzzTime) / 1000 + 's'
      : ''
  const avgLatency = stats.latencyAverage
      ? Math.round(stats.latencyAverage * 10) / 10 + 'ms'
      : ''
  const maxLatency = stats.latencyMax
      ? Math.round(stats.latencyMax * 10) / 10 + 'ms'
      : ''
  return (
    <div className="buzzer-stats">
      <span className="buzzer-stat-name">coryat:</span>
      <span className="buzzer-stat-value">{stats.coryat}</span>
      <span className="buzzer-stat-name">correct:</span>
      <span className="buzzer-stat-value">{stats.correctCount}</span>
      <span className="buzzer-stat-name">incorrect:</span>
      <span className="buzzer-stat-value">{stats.incorrectCount}</span>
      <span className="buzzer-stat-name">{stats.firstBuzzCount} fast winning buzz{stats.firstBuzzCount === 1 ? '' : 'es'}:</span>
      <span className="buzzer-stat-value">{avgFirstBuzzTime}</span>
      <span className="buzzer-stat-name">{stats.fastBuzzCount} fast buzz{stats.fastBuzzCount === 1 ? '' : 'es'}:</span>
      <span className="buzzer-stat-value">{avgFastBuzzTime}</span>
      <span className="buzzer-stat-name">{stats.slowBuzzCount} slow buzz{stats.slowBuzzCount === 1 ? '' : 'es'}:</span>
      <span className="buzzer-stat-value">{avgSlowBuzzTime}</span>
      <span className="buzzer-stat-name">{stats.earlyBuzzCount} early buzz{stats.earlyBuzzCount === 1 ? '' : 'es'}:</span>
      <span className="buzzer-stat-value">{avgEarlyBuzzTime}</span>
      <span className="buzzer-stat-name">fastest buzz:</span>
      <span className="buzzer-stat-value">{fastestBuzzTime}</span>
      <span className="buzzer-stat-name">daily doubles:</span>
      <span className="buzzer-stat-value">{stats.dailyDoubleCorrectCount} / {stats.dailyDoubleCount}</span>
      <span className="buzzer-stat-name">daily double earnings:</span>
      <span className="buzzer-stat-value">{stats.dailyDoubleEarned}</span>
      <span className="buzzer-stat-name">latency avg/max:</span>
      <span className="buzzer-stat-value">{avgLatency} / {maxLatency}</span>
    </div>
  )
}
