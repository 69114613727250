import React, { PureComponent } from 'react';
import { connect } from "react-redux";
import './Clue.css'
import SizeToFit from './SizeToFit'
import Light from '../assets/light.png';
import {hotkeys} from 'react-keyboard-shortcuts'
import {clueClosed, playFinalSong} from "../redux/actions/index";

const mapStateToProps = state => {
  return {
    isFinal: state.gameplay.isFinal,
    buzzersActive: state.gameplay.buzzersActive,
    currentRow: state.gameplay.currentRow,
    currentCol: state.gameplay.currentCol,
  }
}

function mapDispatchToProps(dispatch) {
  return {
    clueClosed: () => dispatch(clueClosed()),
    playFinalSong: () => dispatch(playFinalSong()),
  }
}

class Clue extends PureComponent {
  // expects text, category, isVisual, isAudio passed directly as props
  constructor(props) {
    super(props);

    this.state = {
      lightsOn: true,
    }
  }

  hot_keys = {
    'p': {
      handler: (event) => this.props.isFinal && this.props.playFinalSong() && event.preventDefault(),
    },
  }

  componentDidUpdate(prevProps) {
    if (prevProps.buzzersActive !== this.props.buzzersActive) {
      if (this.props.buzzersActive) {
        this.setState({lightsOn: true})
        this.t = setInterval(() => this.setState({lightsOn: !this.state.lightsOn}), 200)
      } else {
        clearInterval(this.t)
      }
    }
  }

  componentDidMount() {
    if (this.props.buzzersActive) {
      this.t = setInterval(() => this.setState({lightsOn: !this.state.lightsOn}), 200)
    }
  }

  componentWillUnmount() {
    clearInterval(this.t)
  }

  render() {
    // clue font looks better with two spaces between words
    const clueText = this.props.text.replace(/ /g, '  ').toUpperCase()
    // const clueId = `clue-${this.props.currentRow}-${this.props.currentCol}`
    const clueId = `clue`

    return (
      <div id={clueId} className="clue">
        {this.props.buzzersActive &&
          <div id="active-left"
            style={{
              opacity: `${this.state.lightsOn ? 1 : 0}`,
              backgroundImage: `url(${Light})`,
              backgroundRepeat: 'repeat-y',
              backgroundSize: '100%'
            }}
          ></div>
        }
        {this.props.buzzersActive &&
          <div id="active-right"
            style={{
              opacity: `${this.state.lightsOn ? 1 : 0}`,
              backgroundImage: `url(${Light})`,
              backgroundRepeat: 'repeat-y',
              backgroundSize: '100%'
            }}
          ></div>
        }

        <div className="clue-header">
          {this.props.category}
          {!this.props.isFinal && 
            <div style={{whiteSpace: 'nowrap'}}>
              <i id="minimize-clue" className="fas fa-window-minimize button min" onClick={this.props.clueClosed}></i>
              <i id="close-clue" className="fas fa-times button" onClick={this.props.clueClosed}></i>
            </div>
          }
          {this.props.isFinal && 
            <i className="fas fa-music min" onClick={this.props.playFinalSong}></i>
          }
        </div>

        <div className="clue-text">
          <SizeToFit text={clueText} smallest={10} largest={36}/>
        </div>

        {this.props.isVisual &&
          <div className="clue-footer"><i className="fas fa-photo-video min"></i> &nbsp; this is a visual clue</div>
        }
        {!this.props.isVisual && this.props.isAudio &&
          <div className="clue-footer"><i className="fas fa-music min"></i> &nbsp; this is an audio clue</div>
        }
      </div>
    )
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(hotkeys(Clue))
