import axios from 'axios';

export function logResponse(category, value, clue, correctResponse) {
  if (!!process.env.REACT_APP_ADMIN || process.env.NODE_ENV === 'production') {
    axios.post(`${process.env.REACT_APP_API}/log`,
      {
        category: category,
        value: value,
        clue: clue,
        correctResponse: correctResponse
      }
    )
  }
}
