import React, { PureComponent } from 'react';
import './Sidebar.css'
import { connect } from "react-redux";
import {startNextRound, toggleSidebar, 
  toggleBuzzers, toggleTimers, setBuzzerActivationStyle, 
  setBuzzInTimeout, setAnswerTimeout, toggleAutoDisableBuzzers,
  setLockout, toggleRebuzz,
  toggleBuzzersJoinTeams, toggleExpertMode, toggleBuzzerAutohost, setVoice,
  activateEditing, deactivateEditing,
} from "../redux/actions/index";

const mapStateToProps = state => {
  return {
    sidebarVisible: state.options.sidebarVisible,
    useBuzzers: state.options.useBuzzers,
    useTimers: state.options.useTimers,
    buzzInTimeout: state.options.buzzInTimeout,
    answerTimeout: state.options.answerTimeout,
    autoDisableBuzzers: state.options.autoDisableBuzzers,
    buzzerActivationStyle: state.options.buzzerActivationStyle,
    lockout: state.options.lockout,
    rebuzz: state.options.rebuzz,
    doBuzzersJoinTeams: state.options.doBuzzersJoinTeams,
    expertMode: state.options.expertMode,
    autohost: state.options.autohost,
    voice: state.options.voice,

    nextRoundMenu: state.gameplay.nextRoundMenu,
  }
}

function mapDispatchToProps(dispatch) {
  return {
    startNextRound: () => dispatch(startNextRound()),
    toggleSidebar: () => dispatch(toggleSidebar()),
    toggleBuzzers: () => dispatch(toggleBuzzers()),
    toggleTimers: () => dispatch(toggleTimers()),
    setBuzzerActivationStyle: (style) => dispatch(setBuzzerActivationStyle(style)),
    setBuzzInTimeout: (t) => dispatch(setBuzzInTimeout(t)),
    setAnswerTimeout: (t) => dispatch(setAnswerTimeout(t)),
    toggleAutoDisableBuzzers: () => dispatch(toggleAutoDisableBuzzers()),
    setLockout: (t) => dispatch(setLockout(t)),
    toggleRebuzz: () => dispatch(toggleRebuzz()),
    toggleBuzzersJoinTeams: () => dispatch(toggleBuzzersJoinTeams()),
    toggleExpertMode: () => dispatch(toggleExpertMode()),
    toggleBuzzerAutohost: () => dispatch(toggleBuzzerAutohost()),
    setVoice: (v) => dispatch(setVoice(v)),
    activateEditing: () => dispatch(activateEditing()),
    deactivateEditing: () => dispatch(deactivateEditing()),
  };
}

class Sidebar extends PureComponent {
  startNextRound = () => {
    this.props.toggleSidebar()
    this.props.startNextRound()
  }

  handleBuzzerActivationStyleChange = (e) => {
    this.props.setBuzzerActivationStyle(e.target.value)
  }

  handleBuzzInTimeoutChange = (e) => {
    this.props.setBuzzInTimeout(e.target.value)
  }

  handleAnswerTimeoutChange = (e) => {
    this.props.setAnswerTimeout(e.target.value)
  }

  handleLockoutChange = (e) => {
    this.props.setLockout(e.target.value)
  }

  handleVoiceChange = (e) => {
    this.props.setVoice(e.target.value)
  }

  handleFocus = (e) => {
    e.target.select()
    this.props.activateEditing()
  }

  handleBlur = (e) => {
    this.props.deactivateEditing()
  }

  render() {
    return(
      <div>
        <div id='cover' className={this.props.sidebarVisible ? 'visible' : 'hide'} onClick={this.props.toggleSidebar}>
        </div>

        <div id='sidebar' className={'noselect ' + (this.props.sidebarVisible ? 'visible' : 'hidden')}>
          <div id='sidebar-options'>
          {this.props.nextRoundMenu &&
            <ul className='menu'>
              <li className='menu' onClick={this.startNextRound}>{this.props.nextRoundMenu}</li>
            </ul>
          }

          <div className='sidebar-section'>
            <div className="buzz-style-header">Buzzers</div>
            {/* https://proto.io/freebies/onoff/ */}
            <div className="onoffswitch">
                <input type="checkbox" className="onoffswitch-checkbox" 
                  id="onoffswitch-buzzers" name="onoffswitch" 
                  checked={this.props.useBuzzers} 
                  onChange={this.props.toggleBuzzers}
                />
                <label className="onoffswitch-label" htmlFor="onoffswitch-buzzers">
                    <span className="onoffswitch-inner"></span>
                    <span className="onoffswitch-switch"></span>
                </label>
            </div>
          </div>

          {this.props.useBuzzers &&
            <div id="buzz-style-container">
              Buzzer Autohost:
              <i className="fas fa-info-circle buzz-style-info" tooltip="Buzzers can advance the game without the need for a separate person to perform host duties"></i>
              <div className="onoffswitch">
                  <input type="checkbox" className="onoffswitch-checkbox" 
                    id="onoffswitch-buzzerautohost" 
                    checked={this.props.autohost} 
                    onChange={this.props.toggleBuzzerAutohost}
                  />
                  <label className="onoffswitch-label" htmlFor="onoffswitch-buzzerautohost">
                      <span className="onoffswitch-inner"></span>
                      <span className="onoffswitch-switch"></span>
                  </label>
              </div>
              {this.props.autohost &&
                <div className="sidebar-small">
                  Note: buzzer activation style "Jeopardy" would defeat the purpose, 
                  and "Quizbowl" is also not recommended
                </div>
              }

              Buzzers join teams:
              <i className="fas fa-info-circle buzz-style-info" tooltip="Buzzers must join a team before they can play"></i>
              <div className="onoffswitch">
                  <input type="checkbox" className="onoffswitch-checkbox" 
                    id="onoffswitch-buzzerjointeam" 
                    checked={this.props.doBuzzersJoinTeams} 
                    onChange={this.props.toggleBuzzersJoinTeams}
                  />
                  <label className="onoffswitch-label" htmlFor="onoffswitch-buzzerjointeam">
                      <span className="onoffswitch-inner"></span>
                      <span className="onoffswitch-switch"></span>
                  </label>
              </div>

              Buzzer Activation Style
              <form>
                <label>
                  <input type="radio" name="buzzer-activation-style" 
                    value="jeopardy"
                    checked={this.props.buzzerActivationStyle === 'jeopardy'}
                    onChange={this.handleBuzzerActivationStyleChange}
                  />
                  Jeopardy
                  <i className="fas fa-info-circle buzz-style-info" tooltip="Buzzers must be manually activated after reading the clue"></i>
                </label><br/>
                <label>
                  <input type="radio" name="buzzer-activation-style" 
                    value="delayed"
                    checked={this.props.buzzerActivationStyle === 'delayed'}
                    onChange={this.handleBuzzerActivationStyleChange}
                  />
                  Delayed
                  <i className="fas fa-info-circle buzz-style-info" tooltip="Buzzers are automatically activated after a delay, depending on the length of the clue"></i>
                </label><br/>
                <label>
                  <input type="radio" name="buzzer-activation-style" 
                    value="quizbowl"
                    checked={this.props.buzzerActivationStyle === 'quizbowl'}
                    onChange={this.handleBuzzerActivationStyleChange}
                  />
                  Quiz Bowl
                  <i className="fas fa-info-circle buzz-style-info" tooltip="Buzzers are automatically activated as soon as the clue is visible"></i>
                </label><p/>
                {/* Multiple buzz rounds:
                <i className="fas fa-info-circle buzz-style-info" tooltip="Hold another round of buzzing upon an incorrect answer"></i>
                <div className="onoffswitch">
                    <input type="checkbox" className="onoffswitch-checkbox" 
                      id="onoffswitch-rebuzz" 
                      checked={this.props.rebuzz} 
                      onChange={this.props.toggleRebuzz}
                    />
                    <label className="onoffswitch-label" htmlFor="onoffswitch-rebuzz">
                        <span className="onoffswitch-inner"></span>
                        <span className="onoffswitch-switch"></span>
                    </label>
                </div> */}

                Buzzer lockout:
                <input type="text" size="5" value={this.props.lockout}
                  onFocus={this.handleFocus}
                  onBlur={this.handleBlur}
                  onChange={this.handleLockoutChange}
                />
                <i className="fas fa-info-circle buzz-style-info" tooltip="Number of milliseconds to disable a contestant's buzzer after an early buzz"></i>

                Expert Mode:
                <i className="fas fa-info-circle buzz-style-info" tooltip="Buzz timing is not displayed"></i>
                <div className="onoffswitch">
                    <input type="checkbox" className="onoffswitch-checkbox" 
                      id="onoffswitch-buzzerexpert" 
                      checked={this.props.expertMode} 
                      onChange={this.props.toggleExpertMode}
                    />
                    <label className="onoffswitch-label" htmlFor="onoffswitch-buzzerexpert">
                        <span className="onoffswitch-inner"></span>
                        <span className="onoffswitch-switch"></span>
                    </label>
                </div>
              </form>
            </div>
          }

          <div className='sidebar-section'>
            <div className="buzz-style-header">Timers</div>
            <div className="onoffswitch">
                <input type="checkbox" className="onoffswitch-checkbox" 
                  id="onoffswitch-timers" 
                  checked={this.props.useTimers} 
                  onChange={this.props.toggleTimers}
                />
                <label className="onoffswitch-label" htmlFor="onoffswitch-timers">
                    <span className="onoffswitch-inner"></span>
                    <span className="onoffswitch-switch"></span>
                </label>
            </div>
          </div>

          {this.props.useTimers &&
            <div id="buzz-style-container">
              <form>
                Buzz timeout:
                <input type="text" size="5" value={this.props.buzzInTimeout}
                  onFocus={this.handleFocus}
                  onBlur={this.handleBlur}
                  onChange={this.handleBuzzInTimeoutChange}
                />
                <i className="fas fa-info-circle buzz-style-info" tooltip="Number of milliseconds contestants have to buzz in after the clue is read"></i>
                <br/>
                Auto-disable buzzers:
                <i className="fas fa-info-circle buzz-style-info" tooltip="Automatically disable buzzers after the buzz timeout"></i>
                <div className="onoffswitch">
                    <input type="checkbox" className="onoffswitch-checkbox" 
                      id="onoffswitch-autodisablebuzzers" 
                      checked={this.props.autoDisableBuzzers} 
                      onChange={this.props.toggleAutoDisableBuzzers}
                    />
                    <label className="onoffswitch-label" htmlFor="onoffswitch-autodisablebuzzers">
                        <span className="onoffswitch-inner"></span>
                        <span className="onoffswitch-switch"></span>
                    </label>
                </div>
                Answer timeout:
                <input type="text" size="5" value={this.props.answerTimeout}
                  onFocus={this.handleFocus}
                  onBlur={this.handleBlur}
                  onChange={this.handleAnswerTimeoutChange}
                />
                <i className="fas fa-info-circle buzz-style-info" tooltip="Number of milliseconds contestants have to answer after buzzing in"></i>
                <br/>
              </form>
            </div>
          }

          <div className='sidebar-section'>
            <div className="buzz-style-header">Voice</div>
            <div id="buzz-style-container">
              <form>
                <label>
                  <input type="radio" name="buzzer-activation-style" 
                    value="none"
                    checked={this.props.voice === 'none'}
                    onChange={this.handleVoiceChange}
                  />
                  none
                </label><br/>
                <label>
                  <input type="radio" name="buzzer-activation-style" 
                    value="google"
                    checked={this.props.voice === 'google'}
                    onChange={this.handleVoiceChange}
                  />
                  realistic
                </label><br/>
                <label>
                  <input type="radio" name="buzzer-activation-style" 
                    value="animalese"
                    checked={this.props.voice === 'animalese'}
                    onChange={this.handleVoiceChange}
                  />
                  animalese
                </label>
              </form>
            </div>
            </div>
          </div>

          <div id='sidebar-help'>
            Hotkeys
            <ul>
              <li>arrow keys: highlight clue</li>
              <li>space: display highlighted clue</li>
              <li>x: close displayed clue</li>
              <li>1-9: add to team's score</li>
              <li>shift 1-9: subtract from team's score</li>
              <li>a: activate buzzers</li>
              {this.props.doBuzzersJoinTeams && <li>y or =: current buzzer is correct</li>}
              {this.props.doBuzzersJoinTeams && <li>n or -: current buzzer is not correct</li>}
            </ul>
          </div>
        </div>
      </div>
    )
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Sidebar)
