import { combineReducers } from 'redux-loop'
import teamsReducer from './teamsReducer'
import buzzersReducer from './buzzersReducer';
import optionsReducer from './optionsReducer';
import gameplayReducer from './gameplayReducer';

const rootReducer = combineReducers({
  teams: teamsReducer,
  buzzers: buzzersReducer,
  options: optionsReducer,
  gameplay: gameplayReducer,
})

export default rootReducer
