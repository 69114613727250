import React, {useState} from 'react';
import './SubmitCustomGame.css'
import axios from 'axios';

export default function SubmitCustomGame({close, existingTitles}) {
  const [customGameError, setCustomGameError] = useState();

  function verifyCustomGame(game) {
      if (!game.title) return "Needs a title";
      if (!game.author) return "Needs an author";
      if (existingTitles.some(t => t === game.title)) {
        return "Game with this title already exists";
      }
      if (!game.rounds || game.rounds.length !== 2) return "Game needs 2 rounds";
      for (let i = 0; i < game.rounds.length; i++) {
        const round = game.rounds[i];
        if (round.length !== 6) return "Each round should have 6 categories";
        for (let r = 0; r < round.length; r++) {
          const category = round[r];
          if (!category.category) return "Missing a category title";
          if (!category.clues || category.clues.length !== 5) return "Each category should have 5 clues";
        }
      }
      return null;
  }

  const handleUpload = (e) => {
    const file = e.target.files[0];
    e.target.value = null;
    const reader = new FileReader();
    reader.readAsText(file);
    reader.onload = () => {
      try {
        const game = JSON.parse(reader.result);
        const err = verifyCustomGame(game);
        if (!err) {
          axios.post(`${process.env.REACT_APP_API}/custom`, file, {
            headers: {
              'Content-Type': 'application/json'
            }
          })
            .then(_ => {
              setCustomGameError(null);
              close(true);
            })
            .catch(error => {
              setCustomGameError(`Failed to upload: ${error.response.data}`);
            })
        } else {
          setCustomGameError(`Failed to upload: ${err}`);
        }
      } catch(error) {
        setCustomGameError(`Failed to upload: invalid game file`);
        console.log(error);
      }
    };
  }

  return (
    <div id="submit-overlay">
      <div id="submit-modal">
        <label htmlFor="fileupload">Submit custom game: </label>
        <input type="file" id="fileupload" title=" " accept=".json" onChange={handleUpload}/>
        {customGameError &&
          <div className="custom-game-error">
            <p/>
            {customGameError}
          </div>
        }

        <p/>
        <a href="/dev/gametemplate.json" download="gametemplate.json">download game template...</a>

        <p/>
        <button onClick={close}>cancel</button>
      </div>
    </div>
  )
}
