import React, {PureComponent} from 'react';
import { connect } from "react-redux";
import './FinalAnswer.css'
import {incTeamScoreById, decTeamScoreById, finalNext} from "../redux/actions/index";

// some "handwrite-y" fonts, loaded in public/index.html
const FONTS = ['Neucha', 'Gochi Hand', 'Indie Flower', 'Long Cang', 'Shadows Into Light', 'Caveat', 'Permanent Marker', 'Kalam', 'Gloria Hallelujah', 'Marck Script', 'Architects Daughter', 'Nanum Pen Script', 'Rock Salt']

const mapStateToProps = state => {
  return {
  }
}

function mapDispatchToProps(dispatch) {
  return {
    incTeamScoreById: (i) => dispatch(incTeamScoreById(i)),
    decTeamScoreById: (i) => dispatch(decTeamScoreById(i)),
    finalNext: () => dispatch(finalNext()),
  };
}

class FinalAnswer extends PureComponent {
  constructor(props) {
    super(props)

    this.STATE_NAME = 0
    this.STATE_ANSWER = 1
    this.STATE_SCORE = 2
    this.STATE_DONE = 3

    this.state = {
      status: this.STATE_NAME,
      fontFamily: FONTS[Math.floor(Math.random()*FONTS.length)],
    };
  }

  componentDidUpdate(prevProps) {
    if (!prevProps.answerRevealed && this.props.answerRevealed) {
      this.setState({status: this.STATE_ANSWER})
    }
    if (!prevProps.answerScored && this.props.answerScored) {
      this.setState({status: this.STATE_DONE})
    }
  }

  showAnswer = () => {
    this.setState({status: this.STATE_ANSWER})
  }

  nextResult= () => {
    this.setState({status: this.STATE_DONE})
    this.props.finalNext()
  }

  _incTeamScore = () => {
    this.props.incTeamScoreById(this.props.team.id)
    this.setState({status: this.STATE_SCORE})
  }

  _decTeamScore = () => {
    this.props.decTeamScoreById(this.props.team.id)
    this.setState({status: this.STATE_SCORE})
  }

  render() {
    return (
      <div className={"final-result " + (this.props.highscore === this.props.team.score ? 'final-winner' : '')}>
        <div className="final-line">
          <div className="final-name">
            {this.props.team.teamName}
            </div>

          {this.state.status >= this.STATE_ANSWER &&
            <div id={`team-final-answer-${this.props.index}`}
                 className="final-result-item final-written" 
                 style={{fontFamily: `${this.state.fontFamily}, sans-serif`}}>
              {this.props.team.finalAnswer}
            </div>
          }
        </div>

        {/* name, answer, wager and score */}
        {this.state.status >= this.STATE_SCORE &&
          <div className="final-line">
            <div className="final-result-item final-label">
              WAGER:
            </div>
            <div id={`team-final-wager-${this.props.index}`} 
                 className="final-result-item final-written"
                 style={{fontFamily: `${this.state.fontFamily}, sans-serif`}}>
              {this.props.team.wager}
            </div>

            <div className="final-result-item final-label">
              FINAL SCORE:
            </div>
            <div id={`team-final-score-${this.props.index}`} className="final-score">
              {this.props.team.score}
            </div>
          </div>
        }

        {/* state-specific buttons */}
        {this.state.status === this.STATE_NAME &&
          <input className="final-upper-right" type="button" value="next..." onClick={this.showAnswer}/>
        }
        {this.state.status === this.STATE_ANSWER &&
          <div className="final-score-checks final-lower-right">
            <i className="fas fa-check right-answer pointer" onClick={this._incTeamScore}></i>
            <i className="fas fa-times wrong-answer pointer" onClick={this._decTeamScore}></i>
          </div>
        }
        {this.state.status === this.STATE_SCORE &&
          <div className="final-result-item">
            <input className="final-upper-right" type="button" value="next..." onClick={this.nextResult}/>
          </div>
        }
      </div>
    )
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(FinalAnswer)
