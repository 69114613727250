import React, { PureComponent } from 'react';
import './Buzzer.css'
import './BuzzerPickClue.css'
import {hotkeys} from 'react-keyboard-shortcuts'

const MESSAGES = [
  "What next?",
  "Where to?",
  "Select!",
  "You have control of the board",
  "You pick the next clue",
  "Now what?",
  "Pick a clue",
]

function pickRandom(array) {
  return array[Math.floor(Math.random()*array.length)]
}

class ClueValue extends PureComponent {
  clueClicked() {
    this.props.showClue(this.props.r, this.props.c)
  }

  render() {
    return (
      <div id={'clue-value-' + this.props.r + '-' + this.props.c} 
          className="buzzer-clue-value pointer" 
          onClick={()=>this.clueClicked()}
      >
        {this.props.v}
      </div>
    )
  }
}

function Shortcut({category, value, row, col, showClue}) {
  return (
    <div>
      <button id="buzzer-next-clue-button" className="buzzer-yes-button" onClick={() => showClue(row, col)}>
        {`${category} for ${value}`}
      </button>
    </div>
  )
}

class BuzzerPickClue extends PureComponent {
  hot_keys = {
    'enter': {
      handler: (event) => this.showNextClue() && event.preventDefault()
    },
    'n': {
      handler: (event) => this.showNextClue() && event.preventDefault()
    },
  }

  // todo ugly code duplication from render to find next clue for hotkey
  showNextClue = () => {
    const lastRow = this.props.lastRow
    const lastCol = this.props.lastCol
    var isBoardClear = true
    var nextIsNext = false
    var nextRow = -1
    var nextCol = -1
    for (var col = 0; col < this.props.board.length && nextCol === -1; col++) {
      const category = this.props.board[col]
      for (var row = 0; row < 5 && nextCol === -1; row++) {
        if (category.flags & (1 << row)) {
          if (nextIsNext && nextCol === -1) {
            nextRow = row
            nextCol = col
          }
          isBoardClear = false
        }
        nextIsNext |= (row === lastRow && col === lastCol)
      }
    }

    if (!isBoardClear && nextCol === -1) {
      // wrap around to find "next" clue
      for (col = 0; col < this.props.board.length && nextCol === -1; col++) {
        const category = this.props.board[col]
        for (row = 0; row < 5 && nextCol === -1; row++) {
          if (category.flags & (1 << row)) {
            nextRow = row
            nextCol = col
          }
        }
      }
    }

    if (nextRow !== -1 && nextCol !== -1) this.props.showClue(nextRow, nextCol)
  }

  render() {
    const baseValue = (this.props.roundNumber + 1) * 200
    var isBoardClear = true
    const lastRow = this.props.lastRow
    const lastCol = this.props.lastCol
    var nextIsNext = false
    var nextRow = -1
    var nextCol = -1
    var key = 0
    var grid = []
    for (var col = 0; col < this.props.board.length; col++) {
      const category = this.props.board[col]
      grid.push(
        <div className="buzzer-category-name" key={key++}>
          {category.category}
        </div>
      )
      for (var row = 0; row < 5; row++) {
        const value = baseValue * (row+1)
        if (category.flags & (1 << row)) {
          if (nextIsNext && nextCol === -1) {
            nextRow = row
            nextCol = col
          }
          isBoardClear = false
          grid.push(
            <ClueValue r={row} c={col} v={value} showClue={this.props.showClue} key={key++}/>
          )
        } else {
          grid.push(
            <div className="buzzer-clue-value" key={key++}>
            </div>
          )
        }
        nextIsNext |= (row === lastRow && col === lastCol)
      }
    }

    if (!isBoardClear && nextCol === -1) {
      // wrap around to find "next" clue
      for (col = 0; col < this.props.board.length && nextCol === -1; col++) {
        const category = this.props.board[col]
        for (row = 0; row < 5 && nextCol === -1; row++) {
          if (category.flags & (1 << row)) {
            nextRow = row
            nextCol = col
          }
        }
      }
    }

    const nextText = `on to ${this.props.roundNumber === 0 ? 'Double' : 'Final'} Jeopardy...`

    return (
      <div>
        {!isBoardClear && 
          <div>
            <div className="buzzer-pick-clue">
              {pickRandom(MESSAGES)}
            </div>
            <div className="buzzer-board-prompt">The next clue in order is:</div>
            <div>
              <Shortcut category={this.props.board[nextCol].category} 
                  value={baseValue * (nextRow+1)}
                  row={nextRow} col={nextCol}
                  showClue={this.props.showClue}
              />
            </div>
            <div className="buzzer-board-prompt">
              or select from the board:
            </div>
            <div className="buzzer-board">
              {grid}
            </div>
          </div>
        }

        {isBoardClear && 
          <div>
            <div>The board is clear!</div>
            <button id="buzzer-next-round-button" className="buzzer-yes-button buzzer-big-font" onClick={this.props.nextRound}>{nextText}</button>
            <div>
            </div>
          </div>
        }
      </div>
    )
  }
}

export default hotkeys(BuzzerPickClue)
