import React, { PureComponent } from 'react';
import './Buzzer.css'
import FinalStatus from './FinalStatus'

class FinalAnswer extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      finalJeopardyAnswer: '',
      finalEntryError: null,
      finalEntryMessage: null,
    }
  }

  onChange = (e) => {
    if (e.target.value.length <= 100) {
      this.setState({[e.target.name]: e.target.value})
    } else {
      e.preventDefault()
    }
  }

  handleFinalAnswer = (e) => {
    console.log(`[player] submitting final answer of ${this.state.finalJeopardyAnswer}`)
    this.setState({finalEntryError: null, finalEntryMessage: 'All set!'})
    this.props.submitFinalAnswer(this.state.finalJeopardyAnswer)
    e.preventDefault()
  }

  render() {
    return (
      <div>
        <form id="final-answer-form" onSubmit={this.handleFinalAnswer}>
          <div>Final Jeopardy answer:</div>
          <p/>
          <input id="final-answer-input" type="text" name="finalJeopardyAnswer" className="buzzer-large buzzer-input" autoFocus
            value={this.state.finalJeopardyAnswer} 
            onChange={this.onChange}
          />
          <div className="final-answer-help">Anyone on the team can submit; only the last submitted entry will count</div>
          {this.state.finalEntryMessage && 
            <div className="final-entry-message">{this.state.finalEntryMessage}</div>
          }
          <p/>
          <button id="final-answer-button" 
                className={(this.props.isFinalResults ? 'buzzer-disabled-button' : 'buzzer-yes-button') + ' buzzer-big-font'} 
                disabled={this.props.isFinalResults}>
            Submit
          </button>
        </form>

        <FinalStatus finalStatus={this.props.finalStatus} answering={this.props.answering}/>
      </div>
    )
  }
}

export default FinalAnswer
