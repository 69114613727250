import { createReducer } from './reducerUtils'
import { loop, Cmd } from 'redux-loop';
import * as actions from "../actions";
import nextInt from '../../sequence'
import emitter from '../emitter'

// state is list of {id, teamName, score, wager, lastCorrect, active, finalAnswer}

function _getLowScoreTeamIndex(teams) {
  var lowScore = 0
  var lowIndex = 0
  teams.forEach((p, i) => {
    if (i === 0 || p.score < lowScore) {
      lowIndex = i
      lowScore = p.score
    }
  })
  return lowIndex
}

function _setLastCorrectTeam(teams, index) {
  if (index >= teams.length) return teams
  return teams.map((team, n) => {
    team.lastCorrect = (n === index)
    return team
  })
}

// return new list of teams with the lowest scoring team
// having its lastCorrect attribute set to true, and all other
// teams' lastCorrect set to false
function _identifyLowestScoreTeam(teams) {
  const lowIndex = _getLowScoreTeamIndex(teams)
  return _setLastCorrectTeam(teams, lowIndex)
}

function _activateTeamAndClearWager(teams, teamId) {
  return teams.map((team) => {
    team.active = (teamId === team.id)
    team.wager = null
    return team
  })
}


function updateTeamName(state, action) {
  const teams = [...state]
  teams[action.index].teamName = action.teamName
  emitter.emit('team-name-change', teams[action.index].id, action.teamName)
  return teams
}

// todo make teams a map by id and remove this method
// Increment score for team identified by index
function incTeamScoreByIndex(state, action) {
  const i = action.index
  const teams = state.map((team, n) => {
    team.lastCorrect = (action.index === n)
    return team
  })
  teams[i].score += (teams[i].wager !== null && teams[i].wager > 0 ? teams[i].wager : action.clueValue)
  if (state.isDailyDouble) teams[i].wager = null
  return teams
}
  
// todo make teams a map by id and remove this method
// Decrement score for team identified by index
function decTeamScoreByIndex(state, action) {
  const i = action.index
  const teams = [...state]
  teams[i].score -= (teams[i].wager !== null && teams[i].wager > 0 ? teams[i].wager : action.clueValue)
  if (state.isDailyDouble) teams[i].wager = null
  return teams
}

// Increment score for team identified by id
function incTeamScoreById(state, action) {
  const teams = state.map((team) => {
    if (team.id === action.teamId) {
      team.score += (team.wager !== null && team.wager > 0 ? team.wager : action.clueValue)
      team.lastCorrect = true
      if (state.isDailyDouble) team.wager = null
    } else {
      team.lastCorrect = false
    }
    return team
  })
  return teams
}

// Decrement score for team identified by id
function decTeamScoreById(state, action) {
  const teams = state.map((team) => {
    if (team.id === action.teamId) {
      team.score -= (team.wager !== null && team.wager > 0 ? team.wager : action.clueValue)
      if (state.isDailyDouble) team.wager = null
    }
    return team
  })
  return teams
}

// todo make teams a map by id and remove this method
function updateTeamScoreByIndex(state, action) {
  const i = action.index
  const score = action.score
  const p = [...state]
  p[i].score = score
  p[i].wager = null
  return p
}

// todo make teams a map by id and remove this method
function updateTeamWagerByIndex(state, action) {
  if (action.isFinalAnswer) {
    // no more bets
    return state
  }

  const i = action.index
  const teams = [...state]
  teams[i].wager = parseInt(action.wager) || null
  const allWagersEntered = action.isFinal && !!!state.find((team) => team.wager === null && team.score > 0)
  return !allWagersEntered ? teams : loop(teams, Cmd.action(actions.setFinalContinueButton("Show Final Answer...")))
}

function updateTeamWagerById(state, action) {
  if (action.isFinalAnswer) {
    // no more bets
    return state
  }
  
  var allWagersEntered = action.isFinal
  const teams = state.map((team) => {
    if (team.id === action.teamId) {
      team.wager = parseInt(action.wager) || null
    }
    allWagersEntered = allWagersEntered && (team.wager !== null || team.score <= 0)
    return team
  })
  return !allWagersEntered ? teams : loop(teams, Cmd.action(actions.setFinalContinueButton("Show Final Answer...")))
}

function updateTeamAnswerById(state, action) {
  if (action.isFinalResults) {
    // no more answers
    return state
  }

  var allAnswersEntered = true
  const teams = state.map((team) => {
    if (team.id === action.teamId) {
      team.finalAnswer = action.answer
    }
    allAnswersEntered &= (team.finalAnswer !== null || team.score <= 0)
    return team
  })
  return !allAnswersEntered ? teams : loop(teams, Cmd.action(actions.setFinalContinueButton("Show Final Results...")))
}

function addTeam(state, action) {
  const teamId = action && action.teamId ? action.teamId : nextInt()
  const teamName = action && action.teamName ? action.teamName : ''
  const teams = [...state, 
    {id: teamId, teamName: teamName, score: 0, wager: null, lastCorrect: (state.length === 0), finalAnswer: null}
  ]
  return teams
}

function removeTeam(state, action) {
  const wasActive = state[action.index].lastCorrect
  const p = state.filter((_,n) => n !== action.index)
  return wasActive ? _identifyLowestScoreTeam(p) : p
}

function activateTeamAndClearWager(state, action) {
  return _activateTeamAndClearWager(state, action.teamId)
}

function clueClosed(state) {
  return _activateTeamAndClearWager(state, -1)
}

function startNextRound(state, action) {
  const teams = activateTeamAndClearWager(state, -1)

  if (action.currentRoundNumber === 0) {
    // clear all buzzers (pass low score team for autohost game, 
    // so a buzzer from that team can pick the next clue)
    const lowScoreTeamIndex = _getLowScoreTeamIndex(teams)
    const lowScoreTeamId = lowScoreTeamIndex >= teams.length ? -1 : teams[lowScoreTeamIndex].id
    emitter.emit('clear-buzzers', null, lowScoreTeamId)

    // set the low score team as visually "last correct"
    return _setLastCorrectTeam(teams, lowScoreTeamIndex)

  } else if (action.currentRoundNumber === 1) {
    // show final answer button right away if there are no teams
    if (teams.length === 0) {
      return loop(teams, Cmd.action(actions.setFinalContinueButton("Show Final Answer...")))
    }
  }
  return teams
}

function danger(state, action) {
  return (action.state && action.state.teams) ? action.state.teams : state
}

// initialState.push({id: nextInt(), teamName: 'Jack', score: 1000, wager: null, lastCorrect: true, active: false, finalAnswer: null})
// initialState.push({id: nextInt(), teamName: 'Jill', score: 2000, wager: null, lastCorrect: false, active: false, finalAnswer: null})
// initialState.push({id: nextInt(), teamName: 'Jerry', score: 1500, wager: null, lastCorrect: false, active: false, finalAnswer: null})

// Slice reducer
const teamsReducer = createReducer([], {
  // operations directed to a specific team
  UPDATE_TEAM_NAME: updateTeamName,
  INC_TEAM_SCORE_ID: incTeamScoreById,
  DEC_TEAM_SCORE_ID: decTeamScoreById,
  INC_TEAM_SCORE_INDEX: incTeamScoreByIndex,
  DEC_TEAM_SCORE_INDEX: decTeamScoreByIndex,
  UPDATE_TEAM_SCORE: updateTeamScoreByIndex,
  UPDATE_TEAM_WAGER: updateTeamWagerByIndex,
  UPDATE_TEAM_WAGER_ID: updateTeamWagerById,
  UPDATE_TEAM_ANSWER_ID: updateTeamAnswerById,
  // SET_LAST_CORRECT: setLastCorrectTeam,
  ADD_TEAM: addTeam,
  REMOVE_TEAM: removeTeam,
  ACTIVATE_TEAM: activateTeamAndClearWager,

  // operations that affect team(s)
  CLUE_CLOSED: clueClosed,
  START_NEXT_ROUND: startNextRound,

  DANGER: danger,
})

export default teamsReducer
