import React, { PureComponent } from 'react';
import './Buzzer.css'
import './DailyDoubleWager.css'
import DailyDoubleImage from '../assets/dailydouble.jpg';

const MESSAGES_NAN = [
  "That's not going to work",
  "Try entering a number next time",
  "And how much do you suppose that's worth? Try again..."
]

const MESSAGES_NEGATIVE = [
  "Let's keep it positive, big shooter",
  "Please. Seriously. At least bet 0.",
  "Hey think positive!"
]

const MESSAGES_NEGATIVE_ZERO = [
  "Why on earth would you even try this?!",
  "-0 = +0 = 0. If you really want 0, enter 0.",
  "Does -0 even exist?"
]

const MESSAGES_TOOMUCH = [
  "Yeah, you don't quite have that much",
  "Nice try. But no.",
  "No can do - too much!"
]

const MESSAGES_NOENTRY = [
  "Ya gotta wager something!",
  "Looks like you forgot your wager...",
  "If you really want to wager 0, go ahead and enter a 0"
]

const MESSAGES_INTEGER = [
  "Integers only ... MATT!!!"
]

function pickRandom(array) {
  return array[Math.floor(Math.random()*array.length)]
}

class DailyDoubleWager extends PureComponent {
  constructor(props) {
    super(props);

    this.wagerElement = React.createRef()

    this.state = {
      wagerInput: '',
      wagerError: null,
      wagerMessage: null,
    }
  }

  onChange = (e) => {
    if (e.target.value.length <= 15) {
      this.setState({[e.target.name]: e.target.value})
    } else {
      e.preventDefault()
    }
  }

  handleWagerSubmit = (e) => {
    console.log(`[player] submitting daily double wager of ${this.state.wagerInput}`)
    const n = Number(this.state.wagerInput);
    const maxWager = Math.max(this.props.teamScore, (this.props.roundNumber+1)*1000)
    if (isNaN(n)) {
      this.setState({wagerError: pickRandom(MESSAGES_NAN)})
      this.wagerElement.current.select();
    } else if (this.state.wagerInput === '-0') {
      this.setState({wagerError: pickRandom(MESSAGES_NEGATIVE_ZERO)})
      this.wagerElement.current.select();
    } else if (this.state.wagerInput.trim() === '') {
      this.setState({wagerError: pickRandom(MESSAGES_NOENTRY)})
      this.wagerElement.current.select();
    } else if (n < 0) {
      this.setState({wagerError: pickRandom(MESSAGES_NEGATIVE)})
      this.wagerElement.current.select();
    } else if (n > maxWager) {
      this.setState({wagerError: pickRandom(MESSAGES_TOOMUCH)})
      this.wagerElement.current.select();
    } else if (!Number.isSafeInteger(n)) {
      this.setState({wagerError: pickRandom(MESSAGES_INTEGER)})
      this.wagerElement.current.select();
    } else {
      this.setState({wagerError: null})
      this.props.submitWager(this.state.wagerInput)
    }
    e.preventDefault()
  }

  render() {
    return (
      <form id="daily-double-wager-form" onSubmit={this.handleWagerSubmit}>
        <div className="buzzer-daily-double-header"
          style={{
            backgroundImage: `url(${DailyDoubleImage})`,
            backgroundRepeat: 'no-repeat',
            backgroundSize: '100% 100%'
          }}
        >
          {this.props.category}
          <br/>
          for ${this.props.clueValue}
        </div>
        <div>You have ${this.props.teamScore}</div>

        <p/>
        <div>Enter your wager:</div>
        <input id="daily-double-wager-input" type="text" name="wagerInput" className="buzzer-large buzzer-input" 
          inputMode="numeric"
          ref={this.wagerElement}
          autoFocus
          value={this.state.wagerInput} 
          onChange={this.onChange}
        />
        {this.state.wagerError && 
          <div className="final-entry-error">{this.state.wagerError}</div>
        }
        <p/>
        <button id="daily-double-wager-button" className="buzzer-yes-button buzzer-big-font">Submit</button>
      </form>
    )
  }
}

export default DailyDoubleWager
