import React, { PureComponent } from 'react';
import './Buzzer.css'

class GameOver extends PureComponent {
  render() {
    return (
      <div id="game-over">
        Game Over! 
        <p/>
        Check the big screen for results...
      </div>
    )
  }
}

export default GameOver
