import { createReducer } from './reducerUtils'
import emitter from '../emitter'

// state is list of {
//  buzzerId
//  buzzerName
//  teamId
//  picking - flag indicating buzzer that is picking the next clue
//  revealed - flag indicating buzzer that is viewing current answer
//  stats {
//    correctCount (includes DD)
//    incorrectCount (includes DD)
//    coryat ($ right - $ wrong + value of right DDs (ignore wrong DDs and FJ))
//    firstBuzzCount (only counts the times the buzzer was first, whether ultimately correct or not)
//    totalFirstBuzzTime
//    dailyDoubleCount
//    dailyDoubleCorrectCount
//    dailyDoubleEarned
//    latencyAverage
//    latencyMax
//    // FYI these are calculated on the buzzer and not actually stored here...
//    fastBuzzCount (does not include DD)
//    totalFastBuzzTime (used with fastBuzzCount for average calc)
//    slowBuzzCount (does not include DD)
//    totalSlowBuzzTime (used with slowBuzzCount for average calc)
//    earlyBuzzCount
//    totalEarlyBuzzTime
//    fastestBuzzTime
//  }
// }
// it's a list to keep it in order of who came on board
// but todo make it a map, all these list lookups are a little painful to see

function updateBuzzerStats(state, action) {
  // action.buzzerId
  // action.data: wasRight, clueValue, wasDailyDouble, wager, buzzTime, wasFirst
  const data = action.data
  return state.map(b => {
    if (b.buzzerId === action.buzzerId) {
      if (!data.wasDailyDouble) {
        // fast, slow, early, and fastest buzz timings are calculated on the buzzer, not here
        if (data.wasRight) {
          b.stats.correctCount += 1
          b.stats.coryat += data.clueValue
        } else {
          b.stats.incorrectCount += 1
          b.stats.coryat -= data.clueValue
        }
        // only "fast" buzzes are counted in the "first buzz" stat
        if (data.wasFirst && data.buzzTime <= action.lockout) {
          b.stats.firstBuzzCount += 1
          b.stats.totalFirstBuzzTime += data.buzzTime
        }

      } else {
        b.stats.dailyDoubleCount += 1
        if (data.wasRight) {
          b.stats.correctCount += 1
          b.stats.dailyDoubleCorrectCount += 1
          b.stats.coryat += data.clueValue
          b.stats.dailyDoubleEarned += data.wager
        } else {
          b.stats.incorrectCount += 1
        }
      }
    }
    emitter.emit('send-buzzer-stats', b.buzzerId, b.stats)
    return b;
  })
}

function addBuzzer(state, action) {
  const buzzers = state.filter((b) => b.buzzerId !== action.buzzerId)
  buzzers.push({
    buzzerId: action.buzzerId, 
    buzzerName: action.buzzerName, 
    teamId: action.teamId, 
    picking: action.autohost && (state.length === 0),
    stats: {
      correctCount: 0,
      incorrectCount: 0,
      coryat: 0,
      firstBuzzCount: 0,
      totalFirstBuzzTime: 0,
      dailyDoubleCount: 0,
      dailyDoubleCorrectCount: 0,
      dailyDoubleEarned: 0,
      latencyAverage: null,
      latencyMax: null,
    },
  })
  return buzzers
}

function removeBuzzer(state, action) {
  return state.filter((b) => b.buzzerId !== action.buzzerId)
}

function buzzerIsPickingClue(state, action) {
  // autohost: turn on "picking next clue" indicator
  // (or turn them all off if no action.buzzerId)
  return state.map((b) => {
    b.picking = (b.buzzerId === action.buzzerId)
    return b
  })
}

function buzzerIsViewingAnswer(state, action) {
  // autohost: turn on "viewing clue answer" indicator
  // (or turn them all off if no action.buzzerId)
  return state.map((b) => {
    b.revealed = (b.buzzerId === action.buzzerId)
    return b
  })
}

function setupClue(state, action) {
  // autohost: turn off "picking next clue" indicator
  return state.map(b => {b.picking = false; return b;})
}

function startNextRound(state, action) {
  // autohost: turn off "picking next clue" indicator
  return state.map(b => {b.picking = false; return b;})
}

function setLatencyAverage(state, action) {
  return state.map(b => {
    if (b.buzzerId === action.buzzerId || b.buzzerName === action.buzzerId) {
      b.stats.latencyAverage = action.latencyAverage;
      b.stats.latencyMax = action.latencyMax;
    }
    return b;
  })
}

// initialState.push({buzzerName: 'aaa', teamId: 1})
// initialState.push({buzzerName: 'bbb', teamId: 1})
// initialState.push({buzzerName: 'x', teamId: 1})
// initialState.push({buzzerName: 'y', teamId: 1})
// initialState.push({buzzerName: 'I am a buzzer name', teamId: 1})
// initialState.push({buzzerName: 'ccc', teamId: 2})

const buzzersReducer = createReducer([], {
  // operations directed to a specific team
  ADD_BUZZER: addBuzzer,
  REMOVE_BUZZER: removeBuzzer,
  BUZZER_IS_PICKING_CLUE: buzzerIsPickingClue,
  BUZZER_IS_VIEWING_ANSWER: buzzerIsViewingAnswer,

  SET_BUZZER_LATENCY: setLatencyAverage,
  UPDATE_BUZZER_STATS: updateBuzzerStats,

  // operations that affect buzzer(s)
  SETUP_CLUE: setupClue,
  START_NEXT_ROUND: startNextRound,
})

export default buzzersReducer
