import React, { PureComponent } from 'react';
import SizeToFit from './SizeToFit'
import './CategoryName.css'

class CategoryName extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      showComments: false,
    }
  }

  toggleComments = () => {
    this.setState({showComments: !this.state.showComments})
  }

  render() {
    return (
      <div className='category-name'>
        {!!this.props.comments && 
          <button className="comment-button pointer" onClick={this.toggleComments}>
            <i className="fas fa-info"/>
          </button>
        }

        {this.state.showComments &&
          <div className='comments' onClick={this.toggleComments}>
            {this.props.comments}
          </div>
        }

        <SizeToFit text={this.props.text} smallest={7} largest={18}/>
      </div>
    )
  }
}

export default CategoryName
