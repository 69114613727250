import React, { PureComponent } from 'react';
import {hotkeys} from 'react-keyboard-shortcuts'

const reasons = {
  'timeout': 'No one got it, you get to pick again...',
  'dj': 'You get to pick the first clue for Double Jeopardy',
  'dd': "Oh that's too bad. Pick again.",
}

class BuzzerPrePickClue extends PureComponent {
  hot_keys = {
    'enter': {
      handler: (event) => this.props.ok() && event.preventDefault()
    },
  }

  render() {
    const message = reasons[this.props.reason]
    return (
      <div>
        <div>{message}</div>
        <button id="buzzer-pre-pick-button" className="buzzer-yes-button buzzer-big-font" onClick={this.props.ok}>OK</button>
      </div>
    )
  }
}

export default hotkeys(BuzzerPrePickClue)
