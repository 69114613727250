const re = /\?game_id=(\d*)/

// Scrapes a season page looking for all games that aired
// in a given year.
// Returns a list of objects.

export function scrape_season2(content, year) {
  const parser = new DOMParser();
  const doc = parser.parseFromString(content, 'text/html');
  
  const r = []

  const anchors = doc.querySelectorAll('a')
  for (var i = 0; i < anchors.length; i++) {
    const anchorText = anchors[i].textContent
    if (anchorText.includes('aired')) {
      try {
        // todo brittle - relying on anchor text ending exactly in yyyy-MM-dd
        const airDateString = anchorText.slice(-10)
        const airDateYear = airDateString.slice(0, 4)
        if (airDateYear === year) {
          const href = anchors[i].getAttribute('href')
          const gameId = href.match(re)[1]

          r.push({"date": airDateString, "gameId": gameId})
        }
      } catch (error) {
        console.log(`ignoring ${anchorText}`)
      }
    }
  }

  // r = [
  //   {"date": "yyyy-mm-dd", "gameId": "<gameid>"},
  //   ...
  // ]
  
  return r
}
