import React, { Component } from 'react';
import { connect } from "react-redux";
import './MainScreen.css';
import SelectGame from'./SelectGame'
import GameBoard from'./GameBoard'
import TeamList from'./TeamList'
import Header from './Header';
import FinalJeopardy from './FinalJeopardy';
import Sidebar from './Sidebar';
import BuzzerHost from './BuzzerHost';
import Timer, {STYLE_BUZZIN, STYLE_ANSWER} from './Timer';
import DelayedActivation from './DelayedActivation';
import {scrape} from '../scraper/jarchive_scraper'
// import ChooseGame from '../components/ChooseGame';
import ThisIsJeopardySound from '../assets/This_is_Jeopardy.mp3';
import {startGame, finalNext} from "../redux/actions/index";
import emitter from '../redux/emitter'

const mapStateToProps = state => {
  return {
    game: state.gameplay.game,
    roundNumber: state.gameplay.roundNumber,
    nextRoundMenu: state.gameplay.nextRoundMenu,
    isFinal: state.gameplay.isFinal,
    hideTimer: state.gameplay.hideTimer,
    buzzersActive: state.gameplay.buzzersActive,
    validBuzzActive: state.gameplay.validBuzzActive,
    delayedActivation: state.gameplay.delayedActivation,
    lastAnswer: state.gameplay.lastAnswer,
    showFinalContinueButton: state.gameplay.showFinalContinueButton,

    useBuzzers: state.options.useBuzzers,
    useTimers: state.options.useTimers,
    autohost: state.options.autohost,

    activeClues: state.options.activeClues,
  }
}

function mapDispatchToProps(dispatch) {
  return {
    startGame: payload => dispatch(startGame(payload)),
    finalNext:() => dispatch(finalNext()),
    danger: (state) => dispatch({type: 'DANGER', state: state}),
  };
}

class MainScreen extends Component {
  audio = new Audio(ThisIsJeopardySound)

  gameLoaded(g) {
    // this.audio.play()
    this.setupActiveClues(g)
    this.props.startGame(g)
  }

  isSpecial(gameComments) {
    if (gameComments.toLowerCase().indexOf('tournament') > 0) return true;
    if (gameComments.toLowerCase().indexOf('all-star') > 0) return true;
    if (gameComments.toLowerCase().indexOf('championship') > 0) return true;
    if (gameComments.toLowerCase().indexOf('battle') > 0) return true;
    if (gameComments.toLowerCase().indexOf('week') > 0) return true;
    if (gameComments.toLowerCase().indexOf('celebrity') > 0) return true;
    if (gameComments.toLowerCase().indexOf('greatest of all time') > 0) return true;
    if (gameComments.toLowerCase().indexOf('ibm challenge') > 0) return true;
    return false;
  }

  isNormalRound() {
    return [0,1].includes(this.props.roundNumber)
  }

  // devonly
  setupActiveClues(game) {
    if (!this.props.activeClues) return
    for (var roundNum = 0; roundNum < this.props.activeClues.length; roundNum++) {
      const activeClues = this.props.activeClues[roundNum]
      if (Object.keys(activeClues).length > 0) {
        for (var col = 0; col < game.rounds[roundNum].length; col++) {
          for (var row = 0; row < game.rounds[roundNum][col].clues.length; row++) {
            const key = `c${col}r${row}`
            const active = !!activeClues[key]
            game.rounds[roundNum][col].clues[row].done = !active
          }
        }
      }
    }
  }

  // devonly
  processOptions() {
    if (process.env.REACT_APP_JDEV_OPTIONS) {
      console.log(`[admin] DEV loading ${process.env.REACT_APP_JDEV_OPTIONS}`)
      fetch('./dev/' + process.env.REACT_APP_JDEV_OPTIONS)
        .then(r => r.text())
        .then(text => require('toml').parse(text))
        .then(options => {
          if (options.state) {
            this.props.danger(options.state)
          }

          if (options.gameFile) {
            this.loadGameFile(options.gameFile)
          }
        })
    }

    if (process.env.REACT_APP_JDEV_GAMEFILE) {
      this.loadGameFile(process.env.REACT_APP_JDEV_GAMEFILE)
    }
  }

  // devonly
  loadGameFile(gameFile) {
    console.log(`[admin] DEV loading ${gameFile}`)
    if (typeof fetch !== 'undefined') {
      // running in react
      fetch('./dev/' + gameFile)
        .then(r => r.text())
        .then(text => this.gameLoaded(scrape(text)))
    } else {
      // running in node
      const fs = require('fs')
      const text = fs.readFileSync(__dirname + '/../../public/dev/' + gameFile)
      this.gameLoaded(scrape(text))
    }
  }

  componentDidMount() {
    this.processOptions()

    emitter.on('dev-active-clues', () => {
      const game = {...this.props.game}
      this.setupActiveClues(game)
      this.props.startGame(game)
    })
  }

  LastAnswer({answer}) {
    return (
      <div className="last-answer">
        The previous answer was: {answer}
      </div>
    )
  }

  render() {
    return (
      <div className="app">
        <Sidebar/>
        <Header/>

        {/* <ChooseGame/> */}

        {!this.props.game && <SelectGame gameLoaded={(g) => this.gameLoaded(g)}/>}

        {this.props.game && 
          <div className="game">
            <TeamList/>

            <div className="game-content">
              <div className="game-title-bar">
                {this.props.game.title &&
                  <div className="game-info">
                    {this.props.game.title}
                    {this.isSpecial(this.props.game.comments) && 
                      <span>&nbsp;&nbsp;|&nbsp;&nbsp;{this.props.game.comments}</span>
                    }
                  </div>
                }

                {this.props.isFinal && this.props.showFinalContinueButton !== null &&
                  <div className="final-continue">
                    <input type="button" className="pointer" 
                        id="final-continue-button" value={this.props.showFinalContinueButton} onClick={this.props.finalNext}/>
                  </div>
                }
              </div>

              {this.isNormalRound() &&
                <GameBoard/>
              }

              {this.isNormalRound() &&
                this.props.useTimers &&
                this.props.useBuzzers &&
                this.props.buzzersActive &&
                !this.props.validBuzzActive &&
                !this.props.hideTimer &&

                <Timer timerStyle={STYLE_BUZZIN}/>
              }
              {this.isNormalRound() &&
                this.props.useTimers &&
                this.props.useBuzzers &&
                this.props.validBuzzActive &&
                !this.props.hideTimer &&

                <Timer timerStyle={STYLE_ANSWER}/>
              }

              {this.isNormalRound() &&
                (this.props.delayedActivation > 0) &&

                <DelayedActivation/>
              }

              {this.props.isFinal &&
                <FinalJeopardy 
                  finalCategory={this.props.game.final_jeopardy_round.category}
                  clueText={this.props.game.final_jeopardy_round.clue.text}
                  />
              }

              {this.props.autohost && this.props.lastAnswer &&
                <this.LastAnswer answer={this.props.lastAnswer}/>
              }

            </div>

            {this.props.useBuzzers &&
              <BuzzerHost/>
            }
          </div>
        }
      </div>
    )
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(MainScreen)
