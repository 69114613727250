import React, { PureComponent } from 'react';
import './Buzzer.css'
import './BuzzerButton.css'
import GreenButton from '../assets/green-button.png';
import RedButton from '../assets/red-button.png';
import Light from '../assets/big-light.png';
import {hotkeys} from 'react-keyboard-shortcuts'
import SizeToFit from '../components/SizeToFit'

const BuzzFeedback = ({earlyBuzzTime, lockedoutTime, buzzTime, buzzerActivatedTime}) => {
  var early, lockedout, good = ''
  if (earlyBuzzTime) {
    if (buzzerActivatedTime) {
      early = <div className="buzzer-feedback-early">too early by {(buzzerActivatedTime - earlyBuzzTime) / 1000}</div>
    } else {
      early = <div className="buzzer-feedback-early">too early</div>
    }
  }
  if (lockedoutTime) lockedout = <div className="buzzer-feedback-lockedout">locked out for {lockedoutTime / 1000}</div>
  if (buzzTime) good = <div className="buzzer-feedback-good">good buzz {buzzTime / 1000}</div>

  return (
    <div>
      {(earlyBuzzTime || lockedoutTime || buzzTime) && 
        <div className="buzzer-feedback">
          {early}
          {lockedout}
          {good}
        </div>
      }
    </div>
  )
}

class BuzzerButton extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      lightsOn: true,
      earlyBuzzTime: null,
      lockedoutTime: null,
      buzzTime: null,
    }
  }

  hot_keys = {
    'space': {
      handler: (event) => this.buzz() && event.preventDefault()
    },
  }

  componentDidMount() {
    if (this.props.buzzersActive) {
      this.t = setInterval(() => this.setState({lightsOn: !this.state.lightsOn}), 200)
    }
  }

  componentWillUnmount() {
    clearInterval(this.t)
  }

  componentDidUpdate(prevProps) {
    if (prevProps.buzzersActive !== this.props.buzzersActive) {
      if (this.props.buzzersActive) {
        this.setState({lightsOn: true})
        this.t = setInterval(() => this.setState({lightsOn: !this.state.lightsOn}), 200)
      } else {
        clearInterval(this.t)
      }
    }
    if (prevProps.clueText && !this.props.clueText) {
      this.setState({earlyBuzzTime: null, lockedoutTime: null, buzzTime: null})
    }
  }

  buzz = () => {
    this.props.buzz().then(([status, resultTime]) => {
      if (status && resultTime) {
        console.log(`buzz got status ${status} and result ${resultTime}`)
        switch (status) {
          case 'invalid':
            this.setState({earlyBuzzTime: resultTime})
            break;
          case 'locked out':
            this.setState({lockedoutTime: resultTime})
            break;
          case 'good':
            this.setState({buzzTime: resultTime})
            break;
          default:
            // pass
        }
      }
    })
  }

  render() {
    return (
      <div>
        {/* buzzer active flashing lights */}
        {this.props.buzzersActive &&
          <div id="buzzer-lights-left">
            <div className="buzzer-lights"
              style={{
                opacity: `${this.state.lightsOn ? 1 : 0}`,
                backgroundImage: `url(${Light})`,
              }}
            ></div>
          </div>
        }
        {this.props.buzzersActive &&
          <div id="buzzer-lights-right">
            <div className="buzzer-lights"
              style={{
                opacity: `${this.state.lightsOn ? 1 : 0}`,
                backgroundImage: `url(${Light})`,
              }}
            ></div>
          </div>
        }

        <div className="buzzer-header">
          {this.props.clueText &&
            <div className="buzzer-clue-text">
              <SizeToFit text={this.props.clueText} smallest={6} largest={24}/>
            </div>
          }
        </div>

        <div className="buzzer-button-container">
          {this.props.buzzerEnabled 
            ? <img id="buzzer-button" className="buzzer-button" src={GreenButton} alt="button" onClick={this.buzz}/>
            : <img id="buzzer-button-disabled" className="buzzer-button" src={RedButton} alt="button"/>
          }

          {this.props.showBuzzTimes &&
            <BuzzFeedback earlyBuzzTime={this.state.earlyBuzzTime}
              lockedoutTime={this.state.lockedoutTime}
              buzzTime={this.state.buzzTime}
              buzzerActivatedTime={this.props.buzzerActivatedTime}
            />
          }
        </div>
      </div>
    )
  }
}

export default hotkeys(BuzzerButton)
