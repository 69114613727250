import React, { PureComponent } from 'react';
import './SelectGame.css'
import Trebek from '../assets/trebek.png'
import axios from 'axios';
import {scrape} from '../scraper/jarchive_scraper'
import {scrape_season2} from '../scraper/jarchive_season_scraper2'
import SubmitCustomGame from './SubmitCustomGame';
import { connect } from "react-redux";
import {toggleExpertMode, toggleBuzzerAutohost} from "../redux/actions/index";

const mapStateToProps = state => {
  return {
    expertMode: state.options.expertMode,
    autohost: state.options.autohost,
  }
}

function mapDispatchToProps(dispatch) {
  return {
    toggleExpertMode: () => dispatch(toggleExpertMode()),
    toggleBuzzerAutohost: () => dispatch(toggleBuzzerAutohost()),
  };
}

class SelectGame extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      selectedYear: null,
      selectedMonth: null,
      selectedMonthName: null,
      selectedDay: null,
      isLoadingYear: false,
      isLoadingGame: false,
      gameList: null,
      gameLists: [],
      monthList: null,
      days: null,
      firstDayOfWeek: null,
      customGames: [],
      shouldShowSubmitModal: false,
    };
  }

  selectedDate() {
    let s = ''
    if (this.state.isLoadingGame) {
      s += 'Loading '
    }
    if (this.state.selectedMonthName) {
      s += this.state.selectedMonthName
      s += ' '
      if (this.state.selectedDay) {
        s += this.state.selectedDay
        s += ', '
      }
    }
    if (this.state.selectedYear) {
      s += this.state.selectedYear
    }
    return s
  }

  loadGame(gameId) {
    console.log(`[admin] loading gameId ${gameId}`)
    this.setState({isLoadingGame: true, isError: false})
    // axios.get(`${'http://0.0.0.0:8080/'}${this.state.gameurl}`)
    axios.get(`${process.env.REACT_APP_API}/game?game_id=${gameId}`)
      .then(res => {
        try {
          this.props.gameLoaded(scrape(res.data))
        } catch (error) {
          console.log(error)
          this.setState({isError: true, isLoadingGame: false})
        }
      })
      .catch(error => {
        console.log(error)
        this.setState({isError: true, isLoadingGame: false})
      });
  }

  // loadCustomJson = (filename) => {
  //   fetch(`./dev/${filename}`)
  //     .then(r => r.json())
  //     .then(json => this.props.gameLoaded(json))
  // }

  extractDays(month) {
    let days = []
    this.state.gameList.forEach(g => {
      const d = new Date(g.date)
      const gameMonth = d.getUTCMonth()
      if (month === gameMonth) {
        const date = d.getUTCDate()
        days.push({date: date, gameId: g.gameId})
      }
    })
    this.setState({days: days.sort((a,b) => a.date - b.date)})
}

  extractMonths() {
    this.setState({isLoadingYear: false})
    const yearList = this.state.gameLists[0].concat(this.state.gameLists[1])
    this.setState({gameList: yearList, gameLists: []})

    const l = {}
    yearList.forEach(g => {
      const d = new Date(g.date)
      const month = d.getUTCMonth()
      if (l[month]) {
        l[month].count += 1
      } else {
        l[month] = {month: month, monthname: d.toLocaleString('default', {month: 'long'}), count: 1}
      }
    });

    const monthList = Object.values(l).sort((a,b) => a.month - b.month)
    this.setState({monthList: monthList})
  }

  getSeasonList(season, year) {
    axios.get(`${process.env.REACT_APP_API}/season?season=${season}`)
    .then(res => {
      try {
        const gameList = scrape_season2(res.data, year)
        const gameLists = [...this.state.gameLists]
        gameLists.push(gameList)
        this.setState({gameLists: gameLists})
        console.log(`got ${gameLists.length} lists`)

        // got them both
        if (gameLists.length === 2) {
          this.extractMonths()
        }
      } catch (error) {
        console.log(error)
        this.setState({isError: true, isLoadingYear: false})
      }
    })
    .catch(error => {
      console.log(error)
      this.setState({isError: true, isLoadingYear: false})
    });
  }

  handleSelectYear = (e) => {
    if (e.target.value !== this.state.selectedYear) {
      const year = e.target.value
      this.setState({selectedYear: year, monthList: null, selectedMonth: null, selectedMonthName: null, days: null, selectedDay: null})
      this.setState({isLoadingYear: true, gameLists: [], isError: false})

      // j-archive is by season, need to look up two pages
      // year-1983 and year-1984
      this.getSeasonList(year - 1983, year)
      this.getSeasonList(year - 1984, year)
    }
  }

  handleSelectMonth = (e) => {
    if (e.target.value !== this.state.selectedMonth) {
      const month = e.target.value
      const d = new Date(this.state.selectedYear, month)
      this.setState({selectedMonth: month, selectedMonthName: d.toLocaleString('default', {month: 'long'}), selectedDay: null})
      this.extractDays(parseInt(month))
    }
  }

  handleSelectDay = (d) => {
    this.setState({selectedDay: d.date})
    this.loadGame(d.gameId)
  }

  years() {
    const now = new Date()
    let years = []
    for (let year = now.getFullYear(); year >= 1984; year--) {
      years.push(<option key={year} value={year}>{year}</option>)
    }
    return years
  }

  months() {
    let months = []
    this.state.monthList.forEach(m => {
      months.push(<option key={m.month} value={m.month}>{m.monthname} ({m.count} game{m.count === 1 ? '' : 's'})</option>)
    })
    return months
  }

  days() {
    let days = [];
    let key = 0;

    ['S','M','T','W','T','F','S'].forEach(d => {
      days.push(<div className="select-cell select-cell-header" key={key++}>{d}</div>)
    })

    let day = new Date(this.state.selectedYear, this.state.selectedMonth, this.state.days[0].date)
    // any blank days up to first represented day of week
    if (day.getUTCDay() > 0) {
      for (let d = 0; d < day.getUTCDay(); d++) {
        days.push(<div className="select-cell" key={key++}></div>)
      }
    }

    // all represented days (with blanks inserted for days with no game)
    for (let d = 0; d < this.state.days.length; d++) {
      while (this.state.days[d].date > day.getUTCDate()) {
        days.push(<div className="select-cell" key={key++}></div>)
        day.setUTCDate(day.getUTCDate() + 1)
      }
      days.push(
        <div className="select-cell pointer" key={key++} 
        onClick={() => this.handleSelectDay(this.state.days[d])}>
          {this.state.days[d].date}
        </div>
      )
      day.setUTCDate(day.getUTCDate() + 1)
    }

    // finish off the last row
    while (key % 7 !== 0) {
      days.push(<div className="select-cell" key={key++}></div>)
    }

    return days
  }

  loadCustomGame = (id) => {
    axios.get(`${process.env.REACT_APP_API}/custom?id=${id}`)
      .then(res => {
        const gameJson = res.data;
        this.props.gameLoaded(gameJson);
      })
      .catch(error => {
        console.log(error)
      });
  }

  handleSelectCustomGame = (e) => {
    this.loadCustomGame(e.target.value);
  }

  fetchCustomGames() {
    axios.get(`${process.env.REACT_APP_API}/custom`)
      .then(res => {
        const rows = res.data;
        if (rows) this.setState({customGames: rows});
      })
      .catch(error => {
        console.log(error)
      });
  }

  confirmDeleteCustomGame = (id, title, author) => {
  if (window.confirm("WARNING!!! You are about to remove the custom game:\n\n"+
                     title + " by " + author + "\n\n" +
                     "This is immediate, permanent, and irreversible!\n"+
                     "Are you absolutely sure you want to proceed?") === true) {
      this.deleteCustomGame(id);
    }
  }

  deleteCustomGame = (id) => {
    axios.delete(`${process.env.REACT_APP_API}/custom?id=${id}`)
      .then(_ => {
        this.fetchCustomGames();
      })
      .catch(error => {
        console.log(error)
      });
  }

  showSubmitModal = () => {
    this.setState({shouldShowSubmitModal: true});
  }

  closeSubmitModal = (shouldRefresh) => {
    if (shouldRefresh) this.fetchCustomGames();
    this.setState({shouldShowSubmitModal: false});
  }

  componentDidMount() {
    this.fetchCustomGames();
  }

  render() {
    return (
      <div className="select-game-page">
        <div className="select-game-header">Let's find a game...</div>

        <div className="select-game-line">
          <div className="select-game-line-item">
            First pick a year:
            <br/>
            <select className="select-game-select" size="12" onChange={this.handleSelectYear}>
              {this.years()}
            </select>
          </div>

          {this.state.isLoadingYear && 
            <div className="select-game-line-item">
              Next pick a month:
              <br/>
              <div className="select-spinner-container select-game-select">
                <img className="select-spinner" src={Trebek} alt="Trebek"/>
                <span className="select-spinner-text">a moment please...</span>
              </div>
            </div>
          }

          {this.state.monthList &&
            <div className="select-game-line-item">
              Next pick a month:
              <br/>
              <select className="select-game-select" size="12" onChange={this.handleSelectMonth}>
                {this.months()}
              </select>
            </div>
          }

          {this.state.days &&
            <div className="select-game-line-item">
              Now pick a day:
              <br/>
              <div className="select-day">
                {this.days()}
              </div>
            </div>
          }
        </div>

        <div className="select-game-date">
          {this.selectedDate()}
        </div>

        {this.state.isError &&
          <div>
            Could not parse page, try a different game
          </div>
        }

        <div className="select-game-options-container">
          Quick options
          <div className="select-game-options">
            <div>
              Autohost:
            </div>
            <div className="onoffswitch">
                <input type="checkbox" className="onoffswitch-checkbox" 
                  id="onoffswitch-buzzerautohost" 
                  checked={this.props.autohost} 
                  onChange={this.props.toggleBuzzerAutohost}
                />
                <label className="onoffswitch-label" htmlFor="onoffswitch-buzzerautohost">
                    <span className="onoffswitch-inner"></span>
                    <span className="onoffswitch-switch"></span>
                </label>
            </div>

            <div>
              Expert Mode:
            </div>
            <div className="onoffswitch">
                <input type="checkbox" className="onoffswitch-checkbox" 
                  id="onoffswitch-buzzerexpert" 
                  checked={this.props.expertMode} 
                  onChange={this.props.toggleExpertMode}
                />
                <label className="onoffswitch-label" htmlFor="onoffswitch-buzzerexpert">
                    <span className="onoffswitch-inner"></span>
                    <span className="onoffswitch-switch"></span>
                </label>
            </div>
          </div>
        </div>

        <div className="select-custom-game">
          <div className="select-game-header">Or play a custom game...</div>
          <div className="select-custom-container">
            <div className="select-custom-list-header">
              <div className="select-list-item-title">Title</div>
              <div className="select-list-item-author">Author</div>
              <div className="select-list-item-delete"></div>
            </div>
            <div className="select-custom-game-select">
              {this.state.customGames.map((g, i) => 
                <div className="select-custom-list-option" key={`custom_${i}`}>
                  <div className="select-list-item-title" onClick={() => this.loadCustomGame(g.id)}>{g.title}</div>
                  <div className="select-list-item-author" onClick={() => this.loadCustomGame(g.id)}>{g.author}</div>
                  <button className="select-list-item-delete" onClick={() => this.confirmDeleteCustomGame(g.id, g.title, g.author)}>delete</button>
                </div>
              )}
            </div>
          </div>
        </div>

        <button id="submit-button" onClick={this.showSubmitModal}>submit custom game</button>

        {this.state.shouldShowSubmitModal && 
          <SubmitCustomGame close={this.closeSubmitModal} existingTitles={this.state.customGames.map(g => g.title)}/>
        }
      </div>
    )
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(SelectGame)
