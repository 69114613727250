import React, { PureComponent } from 'react';
import './Buzzer.css'
import './FinalStatus.css'

function Check({condition}) {
  return condition ? <i className="fas fa-check"></i> : ''
}

function Wagering({num, autohost}) {
  return (
    <div>
      <div>
        {num === 0 && 
          <span>All wagers are in!</span>
        }
        {num > 0 &&
          <span>{num} team{num === 1 ? ' has' : 's have'} yet to wager</span>
        }
      </div>
      {autohost &&
        <div className="final-status-message">The final clue will be revealed as soon as the last team submits their wager</div>
      }
    </div>
  )
}

function Answering({num, autohost}) {
  return (
    <div>
      <div>
        {num === 0 && 
          <span>All answers are in!</span>
        }
        {num > 0 &&
          <span>{num} team{num === 1 ? ' has' : 's have'} yet to answer</span>
        }
      </div>
      {autohost &&
        <div className="final-status-message">The game will end as soon as the last team submits their answer</div>
      }
    </div>
  )
}

class FinalStatus extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {
    }
  }

  render() {
    var grid = []
    grid.push(<div className="status-header" key='header-teamname'></div>)
    grid.push(<div className="status-header" key='header-wager'>Wagered</div>)
    grid.push(<div className="status-header" key='header-answer'>Answered</div>)
    
    var wagersLeft = this.props.finalStatus.length
    var answersLeft = this.props.finalStatus.length

    for (var row = 0; row < this.props.finalStatus.length; row++) {
      grid.push(
        <div className="status-entry" key={'team'+row}>
          {this.props.finalStatus[row].teamName}
        </div>
      )
      grid.push(
        <div className="status-entry" key={'wager'+row}>
          <Check condition={this.props.finalStatus[row].flags & 1}/>
        </div>
      )
      grid.push(
        <div className="status-entry" key={'answer'+row}>
          <Check condition={this.props.finalStatus[row].flags & 2}/>
        </div>
      )

      if (this.props.finalStatus[row].flags & 1) wagersLeft--
      if (this.props.finalStatus[row].flags & 2) answersLeft--
    }

    return (
      <div>
        <hr/>
        {!this.props.answering 
          ? <Wagering num={wagersLeft} autohost={this.props.autohost}/> 
          : <Answering num={answersLeft} autohost={this.props.autohost}/>}

        <div className="status-table">
          {grid}
        </div>
      </div>
    )
  }
}

export default FinalStatus
