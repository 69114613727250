import React, { PureComponent } from 'react';
import { connect } from "react-redux";
import './GameBoard.css'
import CategoryName from './CategoryName';
import ClueValue from './ClueValue';
import ClueContainer from './ClueContainer';
import {hotkeys} from 'react-keyboard-shortcuts'
import {clueClosed, moveHighlight, incTeamScoreByIndex, decTeamScoreByIndex, setupClue, displayClue} from "../redux/actions/index";

const mapStateToProps = state => {
  return {
    game: state.gameplay.game,
    roundNumber: state.gameplay.roundNumber,
    isClueDisplayed: state.gameplay.isClueDisplayed,
    activeClueValue: state.gameplay.activeClueValue,
    isDailyDoubleDisplayed: state.gameplay.isDailyDoubleDisplayed,
    clueDisplayState: state.gameplay.clueDisplayState,
    currentRow: state.gameplay.currentRow,
    currentCol: state.gameplay.currentCol,
    editing: state.gameplay.editing,
    buzzersActive: state.gameplay.buzzersActive,

    useBuzzers: state.options.useBuzzers,
    buzzerActivationStyle: state.options.buzzerActivationStyle,

    teams: state.teams,
  }
}

function mapDispatchToProps(dispatch) {
  return {
    clueClosed: () => dispatch(clueClosed()),
    moveHighlight: (dir) => dispatch(moveHighlight(dir)),
    setupClue: (r,c) => dispatch(setupClue(r,c)),
    displayClue: (r,c) => dispatch(displayClue(r,c)),
    incTeamScoreByIndex: (i, clueValue) => dispatch(incTeamScoreByIndex(i, clueValue)),
    decTeamScoreByIndex: (i, clueValue) => dispatch(decTeamScoreByIndex(i, clueValue)),
  };
}

class GameBoard extends PureComponent {
  constructor(props) {
    super(props);

    this.board = React.createRef()
  }

  hot_keys = {
    'up': {
      handler: (event) => this.props.editing || (this.props.moveHighlight('up') && event.preventDefault()),
    },
    'down': {
      handler: (event) => this.props.editing || (this.props.moveHighlight('down') && event.preventDefault()),
    },
    'left': {
      handler: (event) => this.props.editing || (this.props.moveHighlight('left') && event.preventDefault()),
    },
    'right': {
      handler: (event) => this.props.editing || (this.props.moveHighlight('right') && event.preventDefault()),
    },
    'mod+up': {
      handler: (event) => this.props.editing || (this.props.moveHighlight('top') && event.preventDefault()),
    },
    'mod+down': {
      handler: (event) => this.props.editing || (this.props.moveHighlight('bottom') && event.preventDefault()),
    },
    'mod+left': {
      handler: (event) => this.props.editing || (this.props.moveHighlight('farleft') && event.preventDefault()),
    },
    'mod+right': {
      handler: (event) => this.props.editing || (this.props.moveHighlight('farright') && event.preventDefault()),
    },
    'space': {
      handler: (event) => {
        if (!this.props.editing && !this.props.isClueDisplayed) {
          const round = this.props.game.rounds[this.props.roundNumber]
          if (!round[this.props.currentCol].clues[this.props.currentRow].done
              && round[this.props.currentCol].clues[this.props.currentRow].value) {
            this._clueClicked()
          }
          event.preventDefault()
        }
      }
    },
    'x': {
      handler: (event) => {
        if (!this.props.editing && this.props.isClueDisplayed) {
          this.props.clueClosed()
          event.preventDefault()
        }
      }
    },
    '-': {
      handler: (event) => {
        if (!this.props.editing && this.props.isClueDisplayed) {
          this.props.clueClosed()
          event.preventDefault()
        }
      }
    },
    '1': this._teamScoreHandler(0),
    '2': this._teamScoreHandler(1),
    '3': this._teamScoreHandler(2),
    '4': this._teamScoreHandler(3),
    '5': this._teamScoreHandler(4),
    '6': this._teamScoreHandler(5),
    '7': this._teamScoreHandler(6),
    '8': this._teamScoreHandler(7),
    '9': this._teamScoreHandler(8),
    'shift+1': this._teamScoreHandler(0, false),
    'shift+2': this._teamScoreHandler(1, false),
    'shift+3': this._teamScoreHandler(2, false),
    'shift+4': this._teamScoreHandler(3, false),
    'shift+5': this._teamScoreHandler(4, false),
    'shift+6': this._teamScoreHandler(5, false),
    'shift+7': this._teamScoreHandler(6, false),
    'shift+8': this._teamScoreHandler(7, false),
    'shift+9': this._teamScoreHandler(8, false),
  }

  _clueClicked = () => {
    this.props.setupClue(this.props.currentRow, this.props.currentCol)
    setTimeout(() => this.props.displayClue(this.props.currentRow, this.props.currentCol), 0)
  }

  _teamScoreHandler(index, inc = true) {
    return {
      handler: (event) => {
        if (index < this.props.teams.length) {
          const team = this.props.teams[index]
          if (!this.props.editing && 
                this.props.isClueDisplayed && 
                (team.wager >= 0 || this.props.activeClueValue > 0)) 
          {
            if (inc) {
              this.props.incTeamScoreByIndex(index, this.props.activeClueValue)
            } else {
              this.props.decTeamScoreByIndex(index, this.props.activeClueValue)
            }
            event.preventDefault()
          }
        }
      }
    }
  }

  render() {
    const round = this.props.game.rounds[this.props.roundNumber]
    var grid = []
    var key = 0
    for (const category of round) {
      grid.push(<CategoryName text={category.category} comments={category.comments} key={key++}/>)
    }
    for (var row = 0; row < 5; row++) {
      for (var col = 0; col < round.length; col++) {
        const clueValue = round[col].clues[row].value
        grid.push(
          <ClueValue key={key++} 
            clueValue={clueValue} 
            display={!round[col].clues[row].done}
            row={row} col={col}
          />
        )
      }
    }
    return (
      <div className={'board-container noselect' + (this.props.buzzersActive && ' board-active')}>
        <div id="game-board" className="board" ref={this.board}>
          {grid}
        </div>

        {!!this.props.clueDisplayState && 
          <ClueContainer category={round[this.props.currentCol].category} 
            text={round[this.props.currentCol].clues[this.props.currentRow].text} 
            isVisual={round[this.props.currentCol].clues[this.props.currentRow].isVisual} 
            isAudio={round[this.props.currentCol].clues[this.props.currentRow].isAudio} 
          />
        }
      </div>
    )
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(hotkeys(GameBoard))
