import React, { PureComponent } from 'react';
import './Buzzer.css'

class BuzzerName extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      inputGameId: process.env.REACT_APP_JDEV_GAMEID || this.props.inputGameId || '',
      inputName: process.env.REACT_APP_JDEV_NAME || '',
    }
  }

  onChange = (e) => {
    if (e.target.value.length <= 15) {
      this.setState({[e.target.name]: e.target.value})
    } else {
      e.preventDefault()
    }
  }

  handleSubmit = (e) => {
    this.props.submitBuzzerName(this.state.inputGameId, this.state.inputName)
    e.preventDefault()
  }

  render() {
    return (
      <div className="buzzer-column">
        <form id="buzzer-name-form" onSubmit={this.handleSubmit}>
          <div>Game id:</div>
          <input id="buzzer-input-gameid" type="text" name="inputGameId" className="buzzer-large buzzer-input" autoFocus 
            inputMode="numeric"
            placeholder='game id'
            value={this.state.inputGameId} 
            onChange={this.onChange}
          />
          <p/>
          <div>Your Name:</div>
          <input id="buzzer-input-name" type="text" name="inputName" className="buzzer-large buzzer-input" 
            placeholder='your name'
            value={this.state.inputName} 
            onChange={this.onChange}
          />
          <p/>
          <button id="buzzer-submit-name" className="buzzer-yes-button buzzer-big-font">Join</button>
        </form>
      </div>
    )
  }
}

export default BuzzerName
