import React, { PureComponent } from 'react';
import { connect } from "react-redux";
import './Header.css'
import Background from '../assets/header.png';
import Trebek from '../assets/trebek.png'
import {startNextRound, toggleSidebar} from "../redux/actions/index";

const mapStateToProps = state => {
  return {
    nextRoundMenu: state.gameplay.nextRoundMenu,
  }
}

function mapDispatchToProps(dispatch) {
  return {
    startNextRound: () => dispatch(startNextRound()),
    toggleSidebar: () => dispatch(toggleSidebar())
  };
}

class Header extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      showMenu: false
    }
  }

  toggleMenu = () => {
    this.setState({showMenu: !this.state.showMenu})
  }

  startNextRound = () => {
    this.toggleMenu()
    this.props.startNextRound()
  }

  render() {
    return(
      <div className="header"
          style={{
            backgroundImage: `url(${Background})`,
            backgroundRepeat: 'repeat-x'
          }}
      >
        <img src={Trebek} className="menuButton" alt="Trebek" onClick={this.props.toggleSidebar}/>

        {this.state.showMenu && this.props.nextRoundMenu &&
          <div className="menu">
            <ul>
              <li onClick={this.startNextRound}>{this.props.nextRoundMenu}</li>
            </ul>
          </div>
        }
      </div>
    )
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Header)
