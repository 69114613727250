import React, { PureComponent } from 'react';
import './Buzzer.css'
import io from "socket.io-client";
import BuzzerName from './BuzzerName'
import BuzzerTeam from './BuzzerTeam'
import BuzzerButton from './BuzzerButton'
import BuzzerAnswer from './BuzzerAnswer'
import BuzzerShowAnswer from './BuzzerShowAnswer'
import BuzzerPrePickClue from './BuzzerPrePickClue'
import BuzzerPickClue from './BuzzerPickClue'
import DailyDoubleWager from './DailyDoubleWager'
import FinalSorry from './FinalSorry'
import FinalWager from './FinalWager'
import FinalAnswer from './FinalAnswer'
import FinalPreReveal from './FinalPreReveal'
import FinalShowAnswer from './FinalShowAnswer'
import GameOver from './GameOver'
import emitter from '../redux/emitter'
import BuzzerStats from '../components/BuzzerStats';
import { DEFAULT_LOCKOUT } from '../redux/reducers/optionsReducer'
import toSpeech, {POST_CLUE_DELAY} from '../voice/animalese'
import FinalJeopardyDing from '../assets/ding.mp3';

class Buzzer extends PureComponent {
  constructor(props) {
    super(props);

    this.audioElement = new Audio();

    this.devBuzzerDelay = 0;
    this.animalese = null;

    this.state = {
      gameId: null,
      teamId: null,
      teamName: null,
      teamList: null,
      buzzerId: null,
      buzzerName: null,
      buzzerEnabled: false,
      buzzersActive: false,
      buzzerActivatedTime: 0,
      buzzerLockoutPenalty: 0,
      lastBuzzTime: 0,
      teamScore: null,
      latencyHistory: [],
      latencyAverage: 0,
      correctResponse: null,
      board: null,
      roundNumber: null,
      prePickReason: null,
      clueText: null,
      isDailyDouble: false,
      stats: {
        // these are computed here only
        fastBuzzCount: 0,
        slowBuzzCount: 0,
        earlyBuzzCount: 0,
        totalFastBuzzTime: 0,
        totalSlowBuzzTime: 0,
        totalEarlyBuzzTime: 0,
        fastestBuzzTime: null,
        // these come from the server
        correctCount: 0,
        incorrectCount: 0,
        coryat: 0,
        firstBuzzCount: 0,
        totalFirstBuzzTime: 0,
        dailyDoubleCount: 0,
        dailyDoubleCorrectCount: 0,
        dailyDoubleEarned: 0,
        latencyAverage: null,
        latencyMax: null,
      },
      showBuzzTimes: true,
      showStats: false,
      showVolume: false,
      volume: 100,
      lockout: DEFAULT_LOCKOUT,
      inputGameId: getQueryGameId(this.props.location.search),
      devE2E: null, // for sad special cases that don't work under enzyme/jsdom (i.e. 3rd party components)
      // name, team, play, 
      // final-wager, final-answer, final-sorry, game-over, 
      // answer, show-answer, pre-pick-clue, pick-clue, daily-double-wager
      status: 'name',
    }
  }

  componentDidMount() {
    console.log(`connecting to io... ${process.env.REACT_APP_API}`)
    this.socket = io(process.env.REACT_APP_API);

    this.socket.on('clear-buzzer', (data) => {
      console.log(`[player] clear-buzzer ${this.state.status} originating from ${data.buzzerId}`)
      this.setState({
        buzzerEnabled: true, 
        clueText: null, 
        isDailyDouble: false, 
        buzzersActive: false, 
        buzzerActivatedTime: 0, 
        buzzerLockoutPenalty: false,
        lastBuzzTime: null,
      })
      // in the case where this buzzer originated the clear request
      // (because this buzzer closed the clue after claiming the right answer),
      // don't update status (so this buzzer can move to 'pick-clue' status)
      if (data.buzzerId !== this.state.buzzerId) {
        // data.buzzerId should only come during an autohost game, otherwise just
        // move to the play status like normal
        console.log('[player] setting status to play')
        this.setState({status: 'play'})
      }
    })

    this.socket.on("disable-buzzer", () => {
      console.log(`[player] disable-buzzer ${this.state.status}`)
      this.setState({status: 'play', buzzerEnabled: false})
    })

    this.socket.on("activate-buzzers", (active) => {
      console.log(`[player] activate-buzzers ${this.state.status}`)
      const now = Date.now()
      if (!this.state.buzzersActive) {
        this.setState({buzzerActivatedTime: now})
        if (active && this.state.buzzerLockoutPenalty && this.state.lastBuzzTime) {
          this.setState(prev => mergeStats(prev, stats => {
            stats.totalEarlyBuzzTime += (now - this.state.lastBuzzTime)
          }))
        }
      }
      this.setState({buzzersActive: active})
    })

    this.socket.on("game-over", () => {
      console.log('[player] game-over')
      this.setState({status: 'name', gameId: null, buzzerEnabled: true})
    })

    this.socket.on("disconnect", () => {
      console.log('[player] server disconnect')
    })

    this.socket.on('team-list', (data) => {
      if (this.state.status === 'team') {
        // if I'm picking a team, listen for any team list changes
        console.log(`[player] team-list has ${data.teamList.length} teams`)
        this.setState({teamList: data.teamList})
      }
    })

    this.socket.on('team-joined', (data) => {
      if (data.buzzerName === this.state.buzzerName) {
        // if this was a new team, we'll finally get a team id
        console.log(`[player] team-joined ${data.teamId}`)
        this.setState({teamId: data.teamId, teamList: null, buzzerEnabled: true})
        this.socket.emit('team-joined', {gameId: this.state.gameId, teamId: data.teamId})
        if (data.board) {
          // we'll only get board data if this is the first buzzer in an autohost game
          this.socket.emit('buzzer-picking-clue', {gameId: this.state.gameId, buzzerId: this.state.buzzerId})
          this.setState({status: 'pick-clue', board: data.board, roundNumber: data.roundNumber, lastRow: data.previousRow, lastCol: data.previousCol})
        } else {
          this.setState({status: 'play'})
        }
      } else {
        console.log(`[player] ignoring team-joined for ${data.buzzerName}`)
        if (this.state.status === 'team') {
          // but refresh team list
          this.socket.emit('team-list', {gameId: this.state.gameId, buzzerName: this.state.buzzerId, buzzerId: this.state.buzzerName})
        }
      }
    })

    this.socket.on('team-name-change', (data) => {
      console.log(`[player] team-name-change`)
      if (data.teamId === this.state.teamId) {
        this.setState({teamName: data.teamName})
      } else {
        console.log(`[player] ignoring team-name-change for ${data.teamId}`)
        if (this.state.status === 'team') {
          // but refresh team list
          this.socket.emit('team-list', {gameId: this.state.gameId, buzzerName: this.state.buzzerId, buzzerId: this.state.buzzerName})
        }
      }
    })

    // only comes during autohost
    this.socket.on('daily-double', (data) => {
      console.log(`[player] daily-double`)
      if (data.teamScore !== null) {
        this.setState({
          status: 'daily-double-wager', 
          isDailyDouble: true,
          teamScore: data.teamScore, 
          category: data.category, 
          clueValue: data.clueValue
        })
      }
    })

    this.socket.on('final-jeopardy', (teams, status, autohost) => {
      console.log(`[player] final-jeopardy`)
      const existingTeam = teams.find((p) => p.id === this.state.teamId)
      if (existingTeam) {
        if (existingTeam.score > 0) {
          this.setState({status: 'final-wager', teams: teams, teamScore: existingTeam.score, finalStatus: status, autohost: autohost})
        } else {
          this.setState({status: 'final-sorry', teams: teams, teamScore: existingTeam.score})
        }
      } else {
        // not on a team
        this.setState({status: 'final-sorry', teams: teams, teamScore: null})
      }
    })

    this.socket.on('final-status', (status, autohost) => {
      this.setState({finalStatus: status, autohost: autohost, isFinalResults: this.isFinalResults(status, autohost)})
    })

    this.socket.on('final-answers', (teams) => {
      console.log(`[player] final-answers`)
      if (this.state.status !== 'final-sorry') {
        this.setState({status: 'final-answer'})
      }
    })

    this.socket.on('final-results', () => {
      console.log(`[player] final-results`)
      this.setState({status: 'game-over'})
    })

    this.socket.on('final-reveal-pre-answer', (teamId) => {
      if (teamId === this.state.teamId && this.state.status === 'final-answer') {
        console.log(`[player] final-reveal-pre-answer`)
        this.setState({status: 'final-pre-reveal'})
      } else {
        console.log(`[player] ignoring final-reveal-pre-answer for ${teamId} ${this.state.status}`)
      }
    })

    this.socket.on('pong', (latency) => {
      console.log(`[player] pong: ${latency}`)
      const history = [...this.state.latencyHistory]
      history.push(latency)
      while (history.length > 10) history.shift()
      const avg = history.reduce((a,b) => (a+b)) / history.length;
      const max = history.reduce((a,b) => Math.max(a, b), -Infinity);
      console.log(`[player] latency avg: ${avg} max: ${max}`)
      this.setState({latencyHistory: history, latencyAverage: avg, latencyMax: max})

      this.setState({stats: {...this.state.stats, latencyAverage: avg, latencyMax: max}})

      // report latency avg back to the host
      // this could be piggybacked on other events, but still need to know about
      // the buzzer that doesn't do anything
      this.socket.emit('buzzer-latency', avg, max)
    })

    this.socket.on('connect', () => {
      console.log(`[player] connect [connected] ${this.socket.connected} [gameId] ${this.state.gameId}`)
      if (this.state.gameId) {
        // if we already had a gameId, then we're reconnecting...
        this.setState({status: 'play', teamId: this.state.teamId, teamList: null, buzzerEnabled: true})
        this.socket.emit('team-rejoined', {
          gameId: this.state.gameId, 
          buzzerId: this.state.buzzerId, 
          buzzerName: this.state.buzzerName, 
          teamId: this.state.teamId
        }, (success) => {
          if (!success) {
            console.log(`rejoin game ${this.state.gameId} failed`)
            this.setState({status: 'name', gameId: null, buzzerEnabled: true})
            this.socket.close()
          }
        })
      }
    })

    this.socket.on('reconnect', (attemptNumber) => {
      console.log(`[player] reconnect: ${attemptNumber}`)
    })

    // only comes during autohost
    this.socket.on('buzzer-answering', (data) => {
      console.log(`[player] buzzer-answering: ${data.buzzerId}`)
      this.setState({status: 'answer'})
    })

    // only comes during autohost
    this.socket.on('pick-clue', (data) => {
      console.log(`[player] pick-clue`)
      // pre-pick clue splash page if a message for one is provided
      const status = data.splash ? 'pre-pick-clue' : 'pick-clue'
      this.socket.emit('buzzer-picking-clue', {gameId: this.state.gameId, buzzerId: this.state.buzzerId})
      this.setState({status: status, prePickReason: data.splash, board: data.board, roundNumber: data.roundNumber, lastRow: data.previousRow, lastCol: data.previousCol})
    })

    this.socket.on('clue-shown', (data) => {
      console.log(`[player] clue-shown, delay is ${data.delay}`)
      this.setState({status: 'play', clueText: data.clue, lockout: data.lockout, showBuzzTimes: !data.expertMode})
      if (data.delay === 0) {
        this.activateBuzzers()
      } else if (data.delay > 0) {
        this.playClue(data.clue, data.delay, data.autohost, data.voice, data.audioDataURI)
      }
    })

    this.socket.on('send-buzzer-stats', (buzzerId, stats, numBuzzers) => {
      if (buzzerId === this.state.buzzerId) {
        console.log('[player] send-buzzer-stats')
        // override latencyAverage with local value - it's more up to date
        stats.latencyAverage = this.state.latencyAverage
        this.setState(prev => {
          const s = {...prev.stats, ...stats}
          return {stats: s}
        })

        // auto-show stats if this is the only buzzer at the time of initial stats report
        if (this.state.showStats === null && numBuzzers === 1) {
          this.setState({showStats: true})
        }
      }
    })

    fetch('voice.wav')
      .then(r => r.arrayBuffer())
      .then(buffer => this.animalese = new Uint8Array(buffer))

    // TEST CODE
    emitter.on('dev-e2e', () => {
      this.setState({devE2E: true})
    })

    // TEST CODE
    this.socket.on('dev-buzzer-delay', (buzzerName, delay) => {
      if (buzzerName === this.state.buzzerName) {
        this.devBuzzerDelay = delay
      }
    })
  }

  componentWillUnmount() {
    this.socket.close()
  }

  activateBuzzers = () => {
    clearInterval(this.t)
    console.log(`[player] buzzer self-activated`)
    const now = Date.now()
    this.setState({buzzersActive: true, buzzerActivatedTime: now})
    if (this.state.buzzerLockoutPenalty && this.state.lastBuzzTime) {
      this.setState(prev => mergeStats(prev, stats => {
        stats.totalEarlyBuzzTime += (now - this.state.lastBuzzTime)
      }))
    }
  }

  isFinalResults(status, autohost) {
    if (!autohost) return false
    // we're in final results display phase if the everyone has submitted their answers
    const numAnswered = status.reduce((a,c) => a + ((c.flags & 2) ? 1 : 0), 0)
    return numAnswered === status.length
  }

  // ----------------------------------------------------------------
  // called from child component

  submitBuzzerName = (gameId, buzzerName) => {
    console.log(`[player] emit new-buzzer ${buzzerName} in game ${gameId}`)

    // brutal hack to "turn on" audio playback which in some browsers is only
    // allowed after a user interaction (which this function is)
    this.audioElement.src = FinalJeopardyDing;
    this.audioElement.volume = 0;
    this.audioElement.play().then(() => {this.audioElement.pause()});

    this.socket.emit('new-buzzer', {gameId: gameId, buzzerName: buzzerName}
        , (data) => { 
          console.log(`[player] new buzzer ack:`, data)
          if (data.gameId === gameId) {
            this.setState({gameId: gameId, buzzerId: data.buzzerId, buzzerName: buzzerName})
            if (data.doBuzzersJoinTeams) {
              this.setState({status: 'team'})
            } else {
              this.setState({status: 'play', teamId: null, teamList: null, buzzerEnabled: true})
            }
          } else {
            alert(data.err)
          }
        })
  }

  submitTeam = (teamId, teamName) => {
    console.log(`[player] emit join-team ${teamId} in game ${this.state.gameId}`)
    this.setState({teamId: teamId, teamName: teamName})
    this.socket.emit('join-team', 
        {
          gameId: this.state.gameId, 
          buzzerId: this.state.buzzerId,
          buzzerName: this.state.buzzerName,
          teamId: teamId,
          teamName: teamName
        }
    )
  }

  changeTeam = () => {
    // request a current team list - when a response comes back it will trigger
    // this buzzer to select a new team
    this.socket.emit('team-list', {gameId: this.state.gameId, buzzerId: this.state.buzzerId, buzzerName: this.state.buzzerName})
    this.setState({status: 'team'})
  }

  toggleBuzzTimes = () => {
    this.setState({showBuzzTimes: !this.state.showBuzzTimes})
  }

  toggleStats = () => {
    this.setState({showStats: !this.state.showStats})
  }

  toggleVolume = () => {
    this.setState({showVolume: !this.state.showVolume})
  }

  volumeChange = (e) => {
    this.setState({volume: Number(e.target.value)})
  }

  buzz = async () => {
    if (this.state.status === 'play' && this.state.buzzerEnabled) {
      const now = Date.now()
      let buzzTime = -1
      let status
      let resultTime
      this.setState({lastBuzzTime: now})
      if (!this.state.buzzersActive) {
        if (this.state.clueText) {
          // clue is shown but buzzers are not yet active - penalty!

          if (!this.state.buzzerLockoutPenalty) {
            this.setState(prev => mergeStats(prev, stats => {
              stats.earlyBuzzCount += 1
            }))
          }

          // https://www.reddit.com/r/Jeopardy/comments/jazxkd/how_exactly_does_the_250_ms_lockout_work/
          // Everything I thought I knew about the lockout was wrong!
          // Basically if you buzz early, you get locked out for some time STARTING ONCE THE BUZZERS ARE ACTIVATED!
          // This is much different than a rolling lockout (meaning locked out for 250ms then active again,
          // then re-locked out if still too early, then active again, etc), which would be conducive to 
          // button spamming, because then you'd get an average response of 125ms, which is better than
          // the human average. With the penalty, you're at 250ms minimum, which is much more easily beaten
          // by someone timing their buzz to the clue cadence.
          this.setState({buzzerLockoutPenalty: true})
          resultTime = now
          console.log(`[player] invalid buzz - too early`)
        } else {
          // clue is not yet shown - no penalty, just a useless buzz

          // I'm trying to be nice and not impose a penalty if someone buzzes in just goofing around
          // (i.e. the clue isn't even up yet)
          console.log(`[player] invalid buzz - no clue`)
        }
        status = 'invalid'
      } else if (this.state.buzzerLockoutPenalty && ((now - this.state.buzzerActivatedTime) < this.state.lockout)) {
        resultTime = this.state.lockout - now + this.state.buzzerActivatedTime
        console.log(`[player] locked out for another ${resultTime}`)
        status = 'locked out'
      } else {
        buzzTime = this.state.buzzerActivatedTime ? now - this.state.buzzerActivatedTime : -1
        console.log(`[player] buzz time ${buzzTime}`)
        status = 'good'
        resultTime = buzzTime

        this.setState(prev => mergeStats(prev, stats => {
          // TODO get "fast time" threshold from server lockout
          if (buzzTime <= 250) {
            stats.fastBuzzCount += 1
            stats.totalFastBuzzTime += buzzTime
          } else {
            stats.slowBuzzCount += 1
            stats.totalSlowBuzzTime += buzzTime
          }
          if (buzzTime < stats.fastestBuzzTime || !stats.fastestBuzzTime) stats.fastestBuzzTime = buzzTime
        }))
        this.setState({buzzerEnabled: false})

        // TEST CODE: if specified from a test, fake some network delay
        if (this.devBuzzerDelay) {
          await new Promise(res => setTimeout(() => {res()}, this.devBuzzerDelay));
        }
      }
      this.socket.emit('buzz', buzzTime, status)
      return [status, resultTime]
    }
  }

  revealAnswer = () => {
    this.socket.emit('get-answer',
      (correctResponse) => {
        console.log(`[player] got correct response ${correctResponse}`)
        this.setState({status: 'show-answer', correctResponse: correctResponse})
      })
  }

  buzzerAnsweredRight = () => {
    this.socket.emit('buzzer-answered-right', this.state.buzzerId, this.state.teamId, (data) => {
      console.log(`[player] buzzer-answered-right ack`)
      this.socket.emit('buzzer-picking-clue', {gameId: this.state.gameId, buzzerId: this.state.buzzerId})
      this.setState({status: 'pick-clue', board: data.board, roundNumber: data.roundNumber, lastRow: data.previousRow, lastCol: data.previousCol})
    })
  }

  buzzerAnsweredWrong = () => {
    this.socket.emit('buzzer-answered-wrong', this.state.buzzerId, this.state.teamId)
    this.setState({status: 'play'})
  }

  buzzerAnsweredSkip = () => {
    this.socket.emit('buzzer-answered-wrong', this.state.buzzerId, this.state.teamId, true)
    this.setState({status: 'play'})
  }

  finalAnsweredRight = () => {
    this.socket.emit('buzzer-answered-right', this.state.buzzerId, this.state.teamId)
    this.setState({status: 'game-over'})
  }

  finalAnsweredWrong = () => {
    this.socket.emit('buzzer-answered-wrong', this.state.buzzerId, this.state.teamId)
    this.setState({status: 'game-over'})
  }

  submitWager = (wager) => {
    this.socket.emit('daily-double-wager', {gameId: this.state.gameId, teamId: this.state.teamId, buzzerId: this.state.buzzerId, wager: wager})
    this.setState({status: 'answer'})
  }

  submitFinalWager = (wager) => {
    this.socket.emit('final-wager', {gameId: this.state.gameId, buzzerName: this.state.buzzerName, finalWager: wager})
  }

  submitFinalAnswer = (answer) => {
    this.socket.emit('final-answer', {gameId: this.state.gameId, buzzerName: this.state.buzzerName, finalAnswer: answer})
  }

  actuallyPickClue = () => {
    this.socket.emit('close-clue', {gameId: this.state.gameId, buzzerId: this.state.buzzerId})
    this.setState({status: 'pick-clue'})
  }

  showClue = (row, col) => {
    this.socket.emit('show-clue', {gameId: this.state.gameId, buzzerId: this.state.buzzerId, row: row, col: col})
    this.setState({status: 'play'})
  }

  nextRound = () => {
    this.socket.emit('next-round', {gameId: this.state.gameId})
  }

  revealFinalAnswer = () => {
    this.socket.emit('get-answer',
      (correctResponse) => {
        console.log(`[player] got final response ${correctResponse}`)
        this.setState({status: 'final-show-answer', correctResponse: correctResponse})
      })
  }

  playClue = async (text, delay, autohost, voice, audioDataURI) => {
    if (autohost && voice !== 'none' && (audioDataURI || this.animalese)) {
      // if we're given audio directly, use it, otherwise generate animalese
      const dataURI = audioDataURI ? audioDataURI : toSpeech(this.animalese, text, 1).dataURI;
  
      const audio = this.audioElement;
      audio.volume = this.state.volume / 100
      audio.src = dataURI;
      audio.onended = () => {
        this.t = setTimeout(this.activateBuzzers, POST_CLUE_DELAY)
      }
      audio.play().catch (e => {
        console.log('[player] failed to play audio');
        console.log(e)
        setTimeout(this.activateBuzzers, delay)
      });

    } else {
      setTimeout(this.activateBuzzers, delay)
    }
  }

  // ----------------------------------------------------------------

  subcomponents = (status) => {
    return {
      'name': <BuzzerName submitBuzzerName={this.submitBuzzerName} inputGameId={this.state.inputGameId}/>,
      'team': <BuzzerTeam teamList={this.state.teamList} submitTeam={this.submitTeam} devE2E={this.state.devE2E}/>,
      'play': <BuzzerButton 
                buzzerEnabled={this.state.buzzerEnabled}
                buzzersActive={this.state.buzzersActive}
                buzzerActivatedTime={this.state.buzzerActivatedTime}
                clueText={this.state.clueText}
                showBuzzTimes={this.state.showBuzzTimes}
                buzz={this.buzz}
              />,
      'answer': <BuzzerAnswer revealAnswer={this.revealAnswer}/>,
      'show-answer': <BuzzerShowAnswer 
                       correctResponse={this.state.correctResponse} 
                       isDailyDouble={this.state.isDailyDouble}
                       buzzerAnsweredRight={this.buzzerAnsweredRight}
                       buzzerAnsweredWrong={this.buzzerAnsweredWrong}
                       buzzerAnsweredSkip={this.buzzerAnsweredSkip}
                     />,
      'pre-pick-clue': <BuzzerPrePickClue 
                         reason={this.state.prePickReason} 
                         ok={this.actuallyPickClue}
                       />,
      'pick-clue': <BuzzerPickClue 
                     board={this.state.board} roundNumber={this.state.roundNumber}
                     lastRow={this.state.lastRow} lastCol={this.state.lastCol}
                     showClue={this.showClue} nextRound={this.nextRound}
                   />,
      'daily-double-wager': <DailyDoubleWager teamScore={this.state.teamScore}
                              roundNumber={this.state.roundNumber}
                              category={this.state.category}
                              clueValue={this.state.clueValue}
                              submitWager={this.submitWager}
                            />,
      'final-sorry': <FinalSorry finalTeamScore={this.state.teamScore}/>,
      'final-wager': <FinalWager 
                       submitFinalWager={this.submitFinalWager}
                       finalTeamScore={this.state.teamScore}
                       teams={this.state.teams}
                       teamName={this.state.teamName}
                       autohost={this.state.autohost} 
                       finalStatus={this.state.finalStatus} answering={false}
                     />,
      'final-answer': <FinalAnswer 
                        submitFinalAnswer={this.submitFinalAnswer}
                        autohost={this.state.autohost} 
                        finalStatus={this.state.finalStatus} answering={true} isFinalResults={this.state.isFinalResults}
                      />,
      'final-pre-reveal': <FinalPreReveal ok={this.revealFinalAnswer}/>,
      'final-show-answer': <FinalShowAnswer correctResponse={this.state.correctResponse}
                             buzzerAnsweredRight={this.finalAnsweredRight}
                             buzzerAnsweredWrong={this.finalAnsweredWrong}
                           />,
      'game-over': <GameOver/>,
    }[status]
  }

  render() {
    return (
      <div className="buzzer">
        <div className="buzzer-container buzzer-column buzzer-large">
          {this.subcomponents(this.state.status)}

          <div className="buzzer-footer">
            {this.state.teamName &&
              <div className='pointer'>
                <span onClick={this.changeTeam}>Team: {this.state.teamName}</span>
                <i className="fas fa-edit buzzer-team-edit"></i>
              </div>
            }

            &nbsp;
            <div className="buzzer-footer-buttons">
              <i className={`fas fa-user-clock ${this.state.showBuzzTimes?'footer-button-on':'footer-button-off'}`} onClick={this.toggleBuzzTimes}></i>
              {/* {this.state.stats &&  */}
                <i className={`fas fa-chart-bar ${this.state.showStats?'footer-button-on':'footer-button-off'}`} onClick={this.toggleStats}></i>
              {/* } */}
              <i className={`fas ${this.state.volume === 0 ? "fa-volume-mute" : "fa-volume-up"}`} onClick={this.toggleVolume}></i>
            </div>

            {this.state.stats && this.state.showStats && 
              <div className='player-buzzer-stats-container'>
                <BuzzerStats stats={this.state.stats}/>
              </div>
            }

            {this.state.showVolume && 
              <input id="volume-slider" type="range" min="0" max="100" value={this.state.volume} onChange={this.volumeChange}></input>
            }

          </div>
        </div>
      </div>
    )
  }
}

function getQueryGameId(qs) {
  // query param string to map of name->value
  let args = Object.assign({}, ...qs.substring(1).split('&').map(e => {
    let pair = e.split('=');
    return {[pair[0]]: pair[1]};
  }));
  return args['g'];
}

function mergeStats(prev, mergeFunc) {
  const stats = {...prev.stats}
  // mergeFunc should mutate given stats
  mergeFunc(stats)
  return {stats: stats}
}

export default Buzzer
