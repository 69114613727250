import { createReducer } from './reducerUtils'
import * as constants from "../constants"
import { logResponse } from '../../response_logger'
import emitter from '../emitter'
import {preprocessInput, OUTPUT_LETTER_DURATION, POST_CLUE_DELAY} from '../../voice/animalese'

const initialState = {
  // game object loaded from j-archive
  game: null,
  // -1: none, 0: single jeopardy, 1: double jeopardy, 2: final jeopardy
  roundNumber: 0,
  // text to display on the menu
  nextRoundMenu: '',

  // row/col of currently active clue
  currentRow: -1,
  currentCol: -1,
  previousRow: -1,
  previousCol: -1,
  
  // the clue text is displayed on the screen
  isClueDisplayed: false,
  // current clue is a daily double
  isDailyDouble: false,
  // dollar value of the current clue (0 for daily doubles)
  activeClueValue: null,
  // the daily double splash is displayed on the screen
  isDailyDoubleDisplayed: false,

  lastAnswer: null,

  clueDisplayState: 0,
  boardElements: {},
  
  buzzersActive: false,
  validBuzzActive: false,

  // shortcut for/duplicate of roundNumber === 2
  isFinal: false,
  // set to true when the final clue is displayed
  isFinalAnswer: false,
  // set to true when the final results list is displayed
  isFinalResults: false,
  
  // this sucks - a flag that hotkey functions can check to turn themselves off
  editing: false,

  resetTimer: false,
  hideTimer: false,
  buzzinTimerExpired: false,
  delayedActivation: null,
  showFinalContinueButton: null,
}

function calculateBuzzerDelay(buzzerActivationStyle, clueText) {
  if (buzzerActivationStyle === 'jeopardy') return -1
  if (buzzerActivationStyle === 'quizbowl') return 0
  
  return preprocessInput(clueText).length * OUTPUT_LETTER_DURATION * 1000 + POST_CLUE_DELAY
}

function startGame(state, action) {
  const o = (state.roundNumber >= 0 && state.roundNumber < 3) 
    ? [
        { nextRoundMenu: 'on to Double Jeopardy...' },
        { nextRoundMenu: 'on to Final Jeopardy...' },
        { nextRoundMenu: null, isFinal: true, isFinalResults: true, showFinalContinueButton: 'go' }    
      ][state.roundNumber] 
    : {}
  return {...state,
    game: action.game,
    ...o
  }
}

function startNextRound(state) {
  const s = (state.clueDisplayState === constants.CLUE_STATE_SHOW_DAILY_DOUBLE) ?
        constants.CLUE_STATE_HIDE_DAILY_DOUBLE :
        constants.CLUE_STATE_HIDE
  if (state.roundNumber === 0) {
    return {...state,
      roundNumber: 1,
      nextRoundMenu: 'on to Final Jeopardy...',
      activeClueValue: 0,
      isClueDisplayed: false,
      isDailyDouble: false,
      buzzersActive: false,
      validBuzzActive: false,
      resetTimer: false,
      hideTimer: false,
      clueDisplayState: s,
      lastAnswer: null,
      currentRow: 0,
      currentCol: 0,
      previousRow: -1,
      previousCol: -1,
    }
  } else if (state.roundNumber === 1) {
    return {...state,
      roundNumber: 2,
      nextRoundMenu: null,
      isFinal: true,
      activeClueValue: 0,
      isClueDisplayed: false,
      isDailyDouble: false,
      buzzersActive: false,
      validBuzzActive: false,
      resetTimer: false,
      hideTimer: false,
      clueDisplayState: s,
      lastAnswer: null,
    }
  }
  return state;
}

function setBoardElementRect(state, action) {
  const boardElements = {...state.boardElements}
  if (!boardElements[action.row]) boardElements[action.row] = {}
  boardElements[action.row][action.col] = action.rect
  return {...state, boardElements: boardElements}
}

function setupClue(state, action) {
  const row = action.row
  const col = action.col
  const category = state.game.rounds[state.roundNumber][col].category
  const clue = state.game.rounds[state.roundNumber][col].clues[row]
  
  logResponse(category, clue.value, clue.text, clue.correctResponse)
  
  return {...state, 
    currentRow: row, currentCol: col,
    previousRow: row, previousCol: col,
    lastAnswer: null,
    clueDisplayState: constants.CLUE_STATE_PRE,
  }
}

function displayClue(state, action) {
  const row = action.row
  const col = action.col
  const clue = state.game.rounds[state.roundNumber][col].clues[row]
  const d = calculateBuzzerDelay(action.buzzerActivationStyle, clue.text)
  if (clue.dailyDouble) {
    emitter.emit('daily-double')
    return {...state, 
      activeClueValue: clue.value,
      isDailyDoubleDisplayed: true, 
      isDailyDouble: true,
      clueDisplayState: constants.CLUE_STATE_DAILY_DOUBLE_SPLASH,
      hideTimer: true,
    }
  } else {
    emitter.emit('clue-shown', row, col, d)
    return {...state, 
      activeClueValue: clue.value,
      isClueDisplayed: true, 
      clueDisplayState: constants.CLUE_STATE_SHOW,
      delayedActivation: d,
      buzzersActive: (action.buzzerActivationStyle === 'quizbowl'),
    }
  }
}

function clueClosed(state, action) {
  if (state.isClueDisplayed) {
    const game = {...state.game}
    game.rounds[state.roundNumber][state.currentCol].clues[state.currentRow].done = true

    const r = (state.currentRow < 4) ? state.currentRow + 1 : 0
    
    const s = (state.clueDisplayState === constants.CLUE_STATE_SHOW_DAILY_DOUBLE) ?
        constants.CLUE_STATE_HIDE_DAILY_DOUBLE :
        constants.CLUE_STATE_HIDE

    emitter.emit('clear-buzzers', action.buzzerId)

    return {...state, 
      game: game,
      activeClueValue: 0,
      isClueDisplayed: false,
      isDailyDouble: false,
      clueDisplayState: s,
      currentRow: r,
      buzzersActive: false,
      validBuzzActive: false,
      resetTimer: false,
      hideTimer: false,
      delayedActivation: null,
    }
  } else {
    return state
  }
}

function dailyDoubleClicked(state) {
  // the daily double splash graphic is clicked, now show the clue
  return {...state, 
    isDailyDoubleDisplayed:false, 
    isClueDisplayed: true,
    isDailyDouble: true,
    clueDisplayState: constants.CLUE_STATE_SHOW_DAILY_DOUBLE,
  }
}

function setLastAnswer(state, action) {
  return {...state, lastAnswer: action.lastAnswer}
}

function showFinalClue(state) {
  logResponse(
    state.game.final_jeopardy_round.category,
    'final', 
    state.game.final_jeopardy_round.clue.text,
    state.game.final_jeopardy_round.clue.correctResponse)
  return {...state, isClueDisplayed: true, isFinalAnswer: true, showFinalContinueButton: null}
}

function showFinalResults(state) {
  return {...state, isFinalResults: true, showFinalContinueButton: null, isClueDisplayed: false}
}

function playFinalSong(state) {
  return {...state, playFinalSong: true}
}

function setFinalContinuebutton(state, action) {
  return {...state, showFinalContinueButton: action.text}
}

function finalNext(state) {
  // trigger to move to the next step of final results display
  emitter.emit('final-next')
  return state
}

function moveHighlight(state, action) {
  if (state.isClueDisplayed || action.sidebarVisible) return state
  let r = state.currentRow
  let c = state.currentCol
  switch (action.dir) {
    case 'up':
      if (r > 0) r -= 1
      break
    case 'down':
      if (r < 4) r += 1
      break
    case 'left':
      if (c > 0) c -= 1
      break
    case 'right':
      if (c < 5) c += 1
      break
    case 'top':
      r = 0
      break
    case 'bottom':
      r = 4
      break
    case 'farleft':
      c = 0
      break
    case 'farright':
      c = 5
      break
    default:
  }
  return {...state, currentRow: r, currentCol: c}
}

function activateBuzzers(state) {
  return {...state, buzzersActive: true, resetTimer: false, buzzinTimerExpired: false, delayedActivation: null}
}

function deactivateBuzzers(state) {
  return {...state, buzzersActive: false, validBuzzActive: false, resetTimer: false}
}

function buzzinTimerExpired(state) {
  return {...state, buzzersActive: false, validBuzzActive: false, resetTimer: false, buzzinTimerExpired: true}
}

function setEditing(state, action) {
  return {...state, editing: action.editing}
}

function gotValidBuzz(state, action) {
  return {...state, validBuzzActive: action.isValid}
}

function resetTimer(state) {
  return {...state, resetTimer: true, hideTimer: false}
}

function hideTimer(state) {
  return {...state, hideTimer: true}
}

function danger(state, action) {
  if (action.state && action.state.gameplay) {
    const s = {...state}
    Object.assign(s, action.state.gameplay)
    return s
  }
  return state
}

const gameplayReducer = createReducer(initialState, {
  START_GAME: startGame,
  START_NEXT_ROUND: startNextRound,
  SET_BOARD_ELEMENT_RECT: setBoardElementRect,
  SETUP_CLUE: setupClue,
  DISPLAY_CLUE: displayClue,
  CLUE_CLOSED: clueClosed,
  DAILY_DOUBLE_CLICKED: dailyDoubleClicked,
  SET_LAST_ANSWER: setLastAnswer,
  SHOW_FINAL_CLUE: showFinalClue,
  SHOW_FINAL_RESULTS: showFinalResults,
  PLAY_FINAL_SONG: playFinalSong,
  SET_FINAL_CONTINUE_BUTTON: setFinalContinuebutton,
  FINAL_NEXT: finalNext,
  MOVE_HIGHLIGHT: moveHighlight,
  ACTIVATE_BUZZERS: activateBuzzers,
  DEACTIVATE_BUZZERS: deactivateBuzzers,
  BUZZIN_TIMER_EXPIRED: buzzinTimerExpired,
  GOT_VALID_BUZZ: gotValidBuzz,
  EDITING: setEditing,
  RESET_TIMER: resetTimer,
  HIDE_TIMER: hideTimer,
  DANGER: danger,
})

export default gameplayReducer
