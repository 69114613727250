import { createStore, compose, applyMiddleware } from "redux"
import { install } from 'redux-loop';
import thunk from 'redux-thunk'
import rootReducer from '../reducers'

const allMiddleware = compose(
  applyMiddleware(thunk),
  install(),  // redux-loop
)

const store = createStore(rootReducer, allMiddleware)

export default store
