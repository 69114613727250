import React, { PureComponent } from 'react';
import './Admin.css'
import io from "socket.io-client";

class Admin extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      logs: [],
    }
  }

  componentDidMount() {
    this.socket = io(process.env.REACT_APP_API);
    this.socket.on('log-correct-response', log => {
      this.setState({logs: [log, ...this.state.logs]})
    });
  }

  componentWillUnmount() {
    this.socket.close()
  }

  render() {
    return (
      <div className="admin">
        <div className="admin-container">
          <span className="admin-header">J-Trivia Response Log</span>
          <div>
            <table border="1">
              <tbody>
                <tr id="header">
                  <th>Time</th>
                  <th>IP</th>
                  <th>Category</th>
                  <th>Value</th>
                  <th>Clue</th>
                  <th>Correct Response</th>
                </tr>
                {this.state.logs.map((log, i) =>
                <tr key={i}>
                  <td className="timestamp">{log.timestamp}</td>
                  <td>{log.ip}</td>
                  <td>{log.category}</td>
                  <td>{log.value}</td>
                  <td>{log.clue}</td>
                  <td>{log.correctResponse}</td>
                </tr>
                )}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    )
  }
}

export default Admin
