import * as constants from "../constants"

// Use a thunk to add some state to the action.
// Useful for actions handled in reducers that don't
// have access to a segment of state that they need.
// Lame because now an action needs to know and include
// all state that is required for every reducer that
// handles the action.
// todo find a better way for slice reducers to access
// state from a different slice, or reorg/refactor so 
// that is no longer an issue
function mixedStateAction(action, fromState) {
  return (dispatch, getState) => {
    dispatch({...action, ...fromState(getState())})
  }
}

export function startGame(game) {
  return { type: constants.START_GAME, game: game }
}

export function startNextRound() {
  return mixedStateAction(
    {type: constants.START_NEXT_ROUND},
    (state) => ({currentRoundNumber: state.gameplay.roundNumber})
  )
}

export function setBoardElementRect(row, col, rect) {
  return { type: constants.SET_BOARD_ELEMENT_RECT, row: row, col: col, rect: rect }
}

export function setupClue(row, col) {
  return { type: constants.SETUP_CLUE, row: row, col: col }
}

export function displayClue(row, col) {
  return mixedStateAction(
    {type: constants.DISPLAY_CLUE, row: row, col: col},
    (state) => ({
      buzzerActivationStyle: state.options.buzzerActivationStyle, 
    })
  )
}

export function clueClosed(buzzerId) {
  return { type: constants.CLUE_CLOSED, buzzerId: buzzerId }
}

export function dailyDoubleClicked() {
  return { type: constants.DAILY_DOUBLE_CLICKED }
}

export function setLastAnswer(lastAnswer) {
  return { type: constants.SET_LAST_ANSWER, lastAnswer: lastAnswer }
}

export function buzzerIsPickingClue(buzzerId) {
  return { type: constants.BUZZER_IS_PICKING_CLUE, buzzerId: buzzerId }
}

export function buzzerIsViewingAnswer(buzzerId) {
  return { type: constants.BUZZER_IS_VIEWING_ANSWER, buzzerId: buzzerId }
}

export function showFinalClue() {
  return { type: constants.SHOW_FINAL_CLUE }
}

export function showFinalResults() {
  return { type: constants.SHOW_FINAL_RESULTS }
}

export function playFinalSong() {
  return { type: constants.PLAY_FINAL_SONG }
}

export function updateTeamName(index, teamName) {
  return { type: constants.UPDATE_TEAM_NAME, index: index, teamName: teamName }
}

export function updateTeamScore(index, score) {
  return { type: constants.UPDATE_TEAM_SCORE, index: index, score: score }
}

export function incTeamScoreByIndex(index, clueValue) {
  return { type: constants.INC_TEAM_SCORE_INDEX, index: index, clueValue: clueValue }
}

export function decTeamScoreByIndex(index, clueValue) {
  return { type: constants.DEC_TEAM_SCORE_INDEX, index: index, clueValue: clueValue }
}

export function incTeamScoreById(teamId, clueValue) {
  return { type: constants.INC_TEAM_SCORE_ID, teamId: teamId, clueValue: clueValue }
}

export function decTeamScoreById(teamId, clueValue) {
  return { type: constants.DEC_TEAM_SCORE_ID, teamId: teamId, clueValue: clueValue }
}

export function updateTeamWager(index, wager) {
  return mixedStateAction(
    {type: constants.UPDATE_TEAM_WAGER, index: index, wager: wager},
    (state) => ({isFinal: state.gameplay.isFinal, isFinalAnswer: state.gameplay.isFinalAnswer})
  )
}

export function updateTeamWagerById(teamId, wager) {
  return mixedStateAction(
    {type: constants.UPDATE_TEAM_WAGER_ID, teamId: teamId, wager: wager},
    (state) => ({isFinal: state.gameplay.isFinal, isFinalAnswer: state.gameplay.isFinalAnswer})
  )
}

export function updateTeamAnswerById(teamId, answer) {
  return mixedStateAction(
    {type: constants.UPDATE_TEAM_ANSWER_ID, teamId: teamId, answer: answer},
    (state) => ({isFinalResults: state.gameplay.isFinalResults})
  )
}

export function setFinalContinueButton(text) {
  return { type: constants.SET_FINAL_CONTINUE_BUTTON, text: text }
}

export function finalNext() {
  return { type: constants.FINAL_NEXT }
}

export function setLastCorrect(index) {
  return { type: constants.SET_LAST_CORRECT, index: index }
}

export function addTeam(teamId, teamName) {
  return { type: constants.ADD_TEAM, teamId: teamId, teamName: teamName }
}

export function removeTeam(index) {
  return { type: constants.REMOVE_TEAM, index: index }
}

export function addBuzzer(buzzerId, buzzerName, teamId) {
  return mixedStateAction(
    {type: constants.ADD_BUZZER, buzzerId: buzzerId, buzzerName: buzzerName, teamId: teamId},
    (state) => ({autohost: state.options.autohost})
  )
}

export function removeBuzzer(buzzerId) {
  return { type: constants.REMOVE_BUZZER, buzzerId: buzzerId }
}

export function setBuzzerLatency(buzzerId, latencyAverage, latencyMax) {
  return { type: constants.SET_BUZZER_LATENCY, buzzerId: buzzerId, latencyAverage: latencyAverage, latencyMax: latencyMax }
}

export function updateBuzzerStats(buzzerId, data) {
  return mixedStateAction(
    { type: constants.UPDATE_BUZZER_STATS, buzzerId: buzzerId, data: data },
    (state) => ({lockout: state.options.lockout})
  )
}

export function moveHighlight(dir) {
  return mixedStateAction(
    {type: constants.MOVE_HIGHLIGHT, dir: dir},
    (state) => ({sidebarVisible: state.options.sidebarVisible})
  )
}

export function toggleSidebar() {
  return { type: constants.TOGGLE_SIDEBAR }
}

export function toggleBuzzers() {
  return { type: constants.TOGGLE_BUZZERS }
}

export function clearBuzzers() {
  return { type: constants.CLEAR_BUZZERS }
}

export function activateBuzzers() {
  return { type: constants.ACTIVATE_BUZZERS }
}

export function deactivateBuzzers() {
  return { type: constants.DEACTIVATE_BUZZERS }
}

export function buzzinTimerExpired() {
  return { type: constants.BUZZIN_TIMER_EXPIRED }
}

export function activateEditing() {
  return { type: constants.EDITING, editing: true }
}

export function deactivateEditing() {
  return { type: constants.EDITING, editing: false }
}

export function setBuzzerActivationStyle(style) {
  return { type: constants.BUZZER_ACTIVATION_STYLE, style: style }
}

export function toggleTimers() {
  return { type: constants.TOGGLE_TIMERS }
}

export function setBuzzInTimeout(t) {
  return { type: constants.SET_BUZZIN_TIMEOUT, t: t }
}

export function setAnswerTimeout(t) {
  return { type: constants.SET_ANSWER_TIMEOUT, t: t }
}

export function toggleAutoDisableBuzzers() {
  return { type: constants.TOGGLE_AUTO_DISABLE_BUZZERS }
}

export function gotValidBuzz(isValid) {
  return { type: constants.GOT_VALID_BUZZ, isValid: isValid }
}

export function resetTimer() {
  return { type: constants.RESET_TIMER }
}

export function hideTimer() {
  return { type: constants.HIDE_TIMER }
}

export function setLockout(t) {
  return { type: constants.SET_LOCKOUT, t: t }
}

export function toggleRebuzz() {
  return { type: constants.TOGGLE_REBUZZ }
}

export function toggleBuzzersJoinTeams() {
  return { type: constants.TOGGLE_BUZZERS_JOIN_TEAMS }
}

export function toggleExpertMode() {
  return { type: constants.TOGGLE_EXPERT_MODE }
}

export function toggleBuzzerAutohost() {
  return { type: constants.TOGGLE_AUTOHOST }
}

export function setVoice(voice) {
  return { type: constants.SET_VOICE, voice: voice }
}

export function activateTeam(teamId) {
  return { type: constants.ACTIVATE_TEAM, teamId: teamId }
}
